import { Loading } from '@layout/loading';
import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash-es';

import {
  setActiveMenuItem,
  setCurrentLocation,
  setLoading,
  setRouteLoading,
  showTopBar,
} from './layout.actions';
import { LayoutState } from './layout.state';

export const LAYOUT_REDUCER_STATE_NAME = 'layout';

const INITIAL_STATE: LayoutState = {
  loading: Loading.App,
  location: undefined,
  routeLoading: undefined,
  showTopBar: true,
};

export const layoutReducer = createReducer<LayoutState>(
  INITIAL_STATE,
  on(showTopBar, (state, action) => {
    return {
      ...state,
      showTopBar: action.showTopBar,
    };
  }),
  on(setLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
  on(setRouteLoading, (state, { routeLoading }) => {
    return {
      ...state,
      routeLoading,
    };
  }),
  on(setCurrentLocation, (state, { location }) => {
    return { ...state, location };
  }),
  on(setActiveMenuItem, (state, { activeMenuItemId }) => {
    return {
      ...state,
      location: isNil(state.location)
        ? undefined
        : {
            ...state.location,
            activeMenuItemId,
          },
    };
  }),
);
