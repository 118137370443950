import { actionNameFactory } from '@core/ngrx';
import { CurrentLocation } from '@model/bems-cloud/bems//layout';
import { createAction, props } from '@ngrx/store';

import { LayoutState } from './layout.state';

const createActionName = actionNameFactory('Layout');

export const setCurrentLocation = createAction(
  createActionName('set current location'),
  props<Pick<LayoutState, 'location'>>(),
);

export const setActiveMenuItem = createAction(
  createActionName('set active menu item'),
  props<Pick<CurrentLocation, 'activeMenuItemId'>>(),
);

export const setLoading = createAction(
  createActionName('set loading'),
  props<Pick<LayoutState, 'loading'>>(),
);

export const setRouteLoading = createAction(
  createActionName('set route loading'),
  props<Pick<LayoutState, 'routeLoading'>>(),
);

export const showTopBar = createAction(
  createActionName('show top bar'),
  props<Pick<LayoutState, 'showTopBar'>>(),
);
