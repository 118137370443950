import { ApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { actionNameFactory } from '@core/ngrx';
import { Device } from '@model/bems-cloud/bems/devices';
import { createAction, props } from '@ngrx/store';

import { CurrentUserState } from './current-user.state';

const createActionName = actionNameFactory('User');

export const fetchCurrentUser = createAction(createActionName('fetch user'));

export const acceptEula = createAction(
  createActionName('accept eula'),
  props<{ version: string }>(),
);

export const fetchCurrentUserError = createAction(
  createActionName('fetch user error'),
  props<ApiErrorResponse>(),
);

export const fetchCurrentUserSuccess = createAction(
  createActionName('fetch user success'),
  props<Pick<CurrentUserState, 'user'>>(),
);

export const fetchCurrentUserRole = createAction(
  createActionName('fetch user role'),
  props<Device>(),
);

export const fetchCurrentUserRoleError = createAction(
  createActionName('fetch user role error'),
  props<ApiErrorResponse>(),
);

export const fetchCurrentUserRoleSuccess = createAction(
  createActionName('fetch user role success'),
  props<Pick<CurrentUserState, 'role'>>(),
);
