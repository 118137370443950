import { Loading, RouteLoading } from '@layout/loading';
import { CurrentLocation } from '@model/bems-cloud/bems//layout';
import { Nil } from '@model/nil';

export interface LayoutState {
  location: CurrentLocation | Nil;
  loading: Loading | Nil;
  routeLoading: RouteLoading | Nil;
  showTopBar: boolean;
}
