import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { camelCase } from 'lodash-es';

import { TrackingEvent } from './event';

@Injectable()
export class TrackingService {
  public constructor(private gtmService: GoogleTagManagerService) {}

  private initialized = false;

  public initialize(): void {
    this.initialized = true;
  }

  /**
   * Send a new event
   * @param event the event to send
   */
  public track(event: TrackingEvent): void {
    if (this.initialized) {
      this.gtmService.pushTag({
        [camelCase(event.name)]: true,
        ...event.properties,
      });
    }
  }

  /*
   * Generate and send an "view" event.
   *
   * @param name the name of element that has been viewed (ex: a page)
   */
  public trackView(
    view: string,
    properties: Record<string, string | number> = {},
  ): void {
    this.track({
      name: `${view}`,
      properties: { ...properties, view },
    });
  }

  public trackAction(
    view: string,
    action: string,
    properties: Record<string, string | number> = {},
  ): void {
    this.track({
      name: `${view} - ${action}`,
      properties: { ...properties, view, action },
    });
  }
}
