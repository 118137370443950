import {
  EnvironmentProviders,
  Provider,
  makeEnvironmentProviders,
} from '@angular/core';
import { provideLottieOptions } from 'ngx-lottie';

export function playerFactory() {
  return import('lottie-web');
}
export function provideLottieAnimation(): EnvironmentProviders {
  const providers: Provider[] = [
    provideLottieOptions({ player: playerFactory }),
  ];
  return makeEnvironmentProviders(providers);
}
