import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Notification,
  NotificationCount,
  ReadNotifications,
} from '@model/bems-cloud/bems/notifications';
import { Observable } from 'rxjs';

import { BemsApiService } from '../bems-api.service';
import { ApiErrorResponse, PaginatedResults } from '../bems-api.types';
import {
  NotificationCountJson,
  NotificationCountJsonSchema,
} from './io/notification-count.io';
import { NotificationJson, NotificationJsonSchema } from './io/notification.io';
import { ReadNotificationsJson } from './io/read-notifications.io';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends BemsApiService {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getNotificationCount(): Observable<
    NotificationCount | ApiErrorResponse
  > {
    return this.get<NotificationCountJson, NotificationCount>({
      endpoint: 'notifications/count-per-level?isRead=false',
      schema: NotificationCountJsonSchema,
      deserializer: (response) => {
        return response;
      },
    });
  }

  public getNotifications(
    offset: number,
    pageSize: number,
    sortBy: string,
    sortOrder: string,
  ): Observable<PaginatedResults<Notification> | ApiErrorResponse> {
    return this.list<NotificationJson, Notification>({
      endpoint: `notifications/?limit=${pageSize}&offset=${
        offset * pageSize
      }&sortBy=${sortBy}:${sortOrder}`,
      schema: NotificationJsonSchema,
      deserializer: (response) => {
        return {
          ...response,
          createdAt: new Date(response.createdAt),
        };
      },
    });
  }

  public markAllNotificationsAsRead(): Observable<
    ApiErrorResponse | undefined
  > {
    return this.postWithoutResponse<ReadNotifications, ReadNotificationsJson>({
      endpoint: 'notifications/',
      payload: { notificationIDs: [] },
      payloadSerializer: (payload) => {
        return payload;
      },
    });
  }
}
