import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { SAVE_TRAP_MESSAGE } from './save-trap.provider';
import { SaveTrapService } from './save-trap.service';

export const saveTrapGuard: CanActivateFn = () => {
  const message = inject(SAVE_TRAP_MESSAGE);
  const service = inject(SaveTrapService);

  if (service.isPristine()) {
    return true;
  }

  const canDeactivate = confirm(message);

  if (canDeactivate) {
    service.markAsPristine();
  }

  return canDeactivate;
};
