import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { environment } from '@env/bems-cloud/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { ZodError, z } from 'zod';

import { Nil } from '@model';
import {
  ApiError,
  ApiErrorResponse,
  PaginatedResults,
  PaginatedResultsJsonSchemaFactory,
  RequestOptions,
} from './bems-api.types';
import { ErrorCode } from './bems-api.utils';

/**
 * Abstract API service for HTTP requests
 * Almost all exceptions from the API are rethrown and handled GlobalErrorHandler. So if you need to handle exceptions on the component level (not globally)
 * you need to add @see catchError
 */
export abstract class BemsApiService {
  public constructor(private httpClient: HttpClient) {}

  protected post<Payload, PayloadJson, Response, ResponseJson>({
    endpoint,
    payload,
    responseSchema,
    payloadSerializer: serializer,
    responseDeserializer: deserializer,
  }: {
    endpoint: string;
    payload: Payload;
    responseSchema: z.Schema<ResponseJson>;
    payloadSerializer: (payload: Payload) => PayloadJson;
    responseDeserializer: (response: ResponseJson) => Response;
  }): Observable<Response | ApiErrorResponse> {
    return this.httpClient
      .post<Response>(
        `${environment.urls.api}/${endpoint}`,
        serializer(payload),
      )
      .pipe(
        map((response) => {
          return deserializer(responseSchema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected patch<Payload, PayloadJson, Response, ResponseJson>({
    endpoint,
    payload,
    responseSchema,
    payloadSerializer: serializer,
    responseDeserializer: deserializer,
  }: {
    endpoint: string;
    payload: Payload;
    responseSchema: z.Schema<ResponseJson>;
    payloadSerializer: (payload: Payload) => PayloadJson;
    responseDeserializer: (response: ResponseJson) => Response;
  }): Observable<Response | ApiErrorResponse> {
    return this.httpClient
      .patch<Response>(
        `${environment.urls.api}/${endpoint}`,
        serializer(payload),
      )
      .pipe(
        map((response) => {
          return deserializer(responseSchema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected put<Payload, PayloadJson, Response, ResponseJson>({
    endpoint,
    payload,
    responseSchema,
    payloadSerializer: serializer,
    responseDeserializer: deserializer,
  }: {
    endpoint: string;
    payload: Payload;
    responseSchema: z.Schema<ResponseJson>;
    payloadSerializer: (payload: Payload) => PayloadJson;
    responseDeserializer: (response: ResponseJson) => Response;
  }): Observable<Response | ApiErrorResponse> {
    return this.httpClient
      .put<Response>(`${environment.urls.api}/${endpoint}`, serializer(payload))
      .pipe(
        map((response) => {
          return deserializer(responseSchema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected postWithoutPayload<Response, ResponseJson>({
    endpoint,
    responseSchema,
    responseDeserializer: deserializer,
  }: {
    endpoint: string;
    responseSchema: z.Schema<ResponseJson>;
    responseDeserializer: (response: ResponseJson) => Response;
  }): Observable<Response | ApiErrorResponse> {
    return this.httpClient
      .post<Response>(`${environment.urls.api}/${endpoint}`, null)
      .pipe(
        map((response) => {
          return deserializer(responseSchema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected postWithoutResponse<Payload, PayloadJson>({
    endpoint,
    payload,
    payloadSerializer: serializer,
  }: {
    endpoint: string;
    payload: Payload;
    payloadSerializer: (payload: Payload) => PayloadJson;
  }): Observable<undefined | ApiErrorResponse> {
    return this.httpClient
      .post<undefined>(
        `${environment.urls.api}/${endpoint}`,
        serializer(payload),
      )
      .pipe(
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected list<Response, Model>({
    endpoint,
    schema,
    deserializer,
  }: {
    endpoint: string;
    schema: z.Schema<Response>;
    deserializer: (response: Response) => Model;
  }): Observable<PaginatedResults<Model> | ApiErrorResponse> {
    return this.httpClient
      .get<unknown>(`${environment.urls.api}/${endpoint}`)
      .pipe(
        map((response) => {
          const results =
            PaginatedResultsJsonSchemaFactory(schema).parse(response);
          return {
            ...results,
            items: (results.items || []).map(deserializer),
          };
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected get<Response, Model>({
    endpoint,
    schema,
    deserializer,
    options,
  }: {
    endpoint: string;
    schema: z.Schema<Response>;
    deserializer: (response: Response) => Model;
    options?: RequestOptions;
  }): Observable<Model | ApiErrorResponse> {
    return this.httpClient
      .get<Response>(`${environment.urls.api}/${endpoint}`, options)
      .pipe(
        map((response) => {
          return deserializer(schema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected deleteWithoutResponse({
    endpoint,
  }: {
    endpoint: string;
  }): Observable<ApiErrorResponse | null> {
    return this.httpClient.delete(`${environment.urls.api}/${endpoint}`).pipe(
      map(() => {
        return null;
      }),
      catchError((response: HttpErrorResponse | ZodError) => {
        return of(this.getApiErrorResponse(response));
      }),
    );
  }

  protected getText({
    endpoint,
  }: {
    endpoint: string;
  }): Observable<string | ApiErrorResponse> {
    return this.httpClient
      .get(`${environment.urls.api}/${endpoint}`, { responseType: 'text' })
      .pipe(
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  private getApiErrorResponse(
    response: HttpErrorResponse | ZodError,
  ): ApiErrorResponse {
    if (response instanceof ZodError) {
      console.error(response);
      throw new Error();
    }

    // If the response is a 401, 403, 404, 500 or 503 we want to throw it so
    // that the global error handler can handle it.
    if (
      response.status === HttpStatusCode.Unauthorized ||
      response.status === HttpStatusCode.Forbidden ||
      response.status === HttpStatusCode.InternalServerError ||
      response.status === HttpStatusCode.ServiceUnavailable
    ) {
      throw response;
    }
    if (response.status === HttpStatusCode.NotFound) {
      const apiErrorResponse: ApiErrorResponse = response.error;
      const apiErrors: ApiError[] | Nil = apiErrorResponse.errors;
      apiErrors?.forEach((apiError) => {
        apiError.messages?.forEach((message) => {
          // 404 Not Found with code 9015 is an edge case as it indicates
          // an error when trying to revoke the access of the unit's sole administrator.
          // In this situation we don't throw the response so that the app's flow
          // can continue, i.e. transfer ownership.
          if (message.code !== ErrorCode.CurrentUserIsSoleAdmin) {
            throw response;
          }
        });
      });
    }

    return {
      code: response.status,
      errors: response.error?.errors,
      message: response.error?.messages,
    };
  }
}
