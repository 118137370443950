import { createReducer, on } from '@ngrx/store';

import { fetchNotificationCountSuccess } from './notifications.actions';
import { NotificationsState } from './notifications.state';

export const NOTIFICATIONS_STATE_NAME = 'notifications';

const INITIAL_STATE: NotificationsState = {
  critical: 0,
  info: 0,
  warning: 0,
};

export const notificationsReducer = createReducer<NotificationsState>(
  INITIAL_STATE,
  on(fetchNotificationCountSuccess, (_state, { critical, info, warning }) => {
    return {
      critical,
      info,
      warning,
    };
  }),
);
