/* eslint-disable max-len */
import * as formatMessage from 'format-message';

// AUTOMATICALLY GENERATED ! DO NOT EDIT MANUALLY !

export const MESSAGES = {
  accountPage: {
    description: {
      accountInformation: $localize`:@@account-page.description.account-information:Energy consumption and production history for all the devices and buildings associated with this account.`,
      auditLogs: $localize`:@@account-page.description.audit-logs:Records of all events and changes your account did on the software.`,
    },
    label: {
      accountInformation: $localize`:@@account-page.label.account-information:Account information`,
      auditLogs: $localize`:@@account-page.label.audit-logs:Audit logs`,
      support: $localize`:@@account-page.label.support:Support`,
    },
    menu: {
      changePassword: $localize`:@@account-page.menu.change-password:Change password`,
      deleteAccount: $localize`:@@account-page.menu.delete-account:Delete account`,
      downloadAccountInformation: $localize`:@@account-page.menu.download-account-information:Download account information`,
      personalInformation: $localize`:@@account-page.menu.personal-information:Personal information`,
      preferences: $localize`:@@account-page.menu.preferences:Preferences`,
    },
    title: {
      myAccount: $localize`:@@account-page.title.my-account:My Account`,
    },
  },
  appMenu: {
    label: {
      assets: $localize`:@@app-menu.label.assets:Assets`,
      charts: $localize`:@@app-menu.label.charts:Charts`,
      dashboard: $localize`:@@app-menu.label.dashboard:Dashboard`,
      scheduler: $localize`:@@app-menu.label.scheduler:Scheduler`,
      settings: $localize`:@@app-menu.label.settings:Settings`,
      users: $localize`:@@app-menu.label.users:Users`,
    },
    title: $localize`:@@app-menu.title:BEMS`,
  },
  app: {
    cookieConsent: {
      accept: $localize`:@@app.cookie-consent.accept:I accept cookies`,
      analytics: $localize`:@@app.cookie-consent.analytics:Analytics`,
      consent: $localize`:@@app.cookie-consent.consent:I allow this website to use the following cookies`,
      explanation: $localize`:@@app.cookie-consent.explanation:We use cookies to be able to provide social media features, analyze our traffic and behavior of the visitors on our website and for marketing purposes.`,
      link: $localize`:@@app.cookie-consent.link:More information`,
      linkUrl: $localize`:@@app.cookie-consent.link-url:https://www.cookiesandyou.com`,
      required: $localize`:@@app.cookie-consent.required:Required`,
      settings: $localize`:@@app.cookie-consent.settings:Change settings`,
      title: $localize`:@@app.cookie-consent.title:This website uses cookies`,
    },
    saveTrap: {
      confirm: $localize`:@@app.save-trap.confirm:If you proceed, your unsaved changes will be lost. Are you sure you want to continue?`,
    },
  },
  assetDetailsPage: {
    gatheringState: {
      description: $localize`:@@asset-details-page.gathering-state.description:This process can take up to an hour.`,
      title: $localize`:@@asset-details-page.gathering-state.title:Gathering data`,
    },
    offlineState: {
      description: $localize`:@@asset-details-page.offline-state.description:Please review your asset details to solve this issue.`,
      title: $localize`:@@asset-details-page.offline-state.title:Offline`,
    },
  },
  assetsDetailsPage: {
    button: {
      backToAllAssets: $localize`:@@assets-details-page.button.back-to-all-assets:Back to all assets`,
    },
    label: {
      starting: $localize`:@@assets-details-page.label.starting:Starting`,
    },
  },
  assetsPage: {
    column: {
      name: $localize`:@@assets-page.column.name:Name`,
      power: $localize`:@@assets-page.column.power:Power`,
      status: $localize`:@@assets-page.column.status:Status`,
    },
    emptyState: {
      title: $localize`:@@assets-page.empty-state.title:No asset available yet.`,
    },
  },
  authorizeApplicationErrorPage: {
    paragraph: {
      closeTab: $localize`:@@authorize-application-error-page.paragraph.close-tab:You can now close this tab.`,
      restart: (name: string | number) => {
        return format(
          $localize`:@@authorize-application-error-page.paragraph.restart:The user code expired due to inactivity.\\nPlease start again the process from {{name}}.`,
          { name },
        );
      },
    },
    title: {
      timeout: $localize`:@@authorize-application-error-page.title.timeout:Authorize application timeout`,
    },
  },
  authorizeApplicationPage: {
    button: {
      allow: $localize`:@@authorize-application-page.button.allow:Allow`,
    },
    paragraph: {
      intro: (clientName: string | number) => {
        return format(
          $localize`:@@authorize-application-page.paragraph.intro:{{clientName}} is requesting access to BEMS data for the following installations:`,
          { clientName },
        );
      },
      share: $localize`:@@authorize-application-page.paragraph.share:You will share all readable data to this application.`,
      userCode: $localize`:@@authorize-application-page.paragraph.user-code:Please enter the user code and click on allow to finalize the authorization.`,
    },
    placeholder: {
      userCode: $localize`:@@authorize-application-page.placeholder.user-code:User Code`,
    },
    title: {
      authorizeApplication: $localize`:@@authorize-application-page.title.authorize-application:Authorize application`,
    },
    validationError: {
      userCode: {
        invalid: $localize`:@@authorize-application-page.validation-error.user-code.invalid:Invalid User Code. Please insert 10 alphanumeric chars`,
      },
    },
  },
  authorizeApplicationSuccessPage: {
    paragraph: {
      closeTab: (clientName: string | number) => {
        return format(
          $localize`:@@authorize-application-success-page.paragraph.close-tab:You can now close this tab and return to {{clientName}}.`,
          { clientName },
        );
      },
    },
    title: {
      success: $localize`:@@authorize-application-success-page.title.success:Application authorized successfully`,
    },
  },
  changePasswordPage: {
    hint: {
      passwordsMustMatch: $localize`:@@change-password-page.hint.passwords-must-match:Both passwords must match`,
    },
    notification: {
      saveError: $localize`:@@change-password-page.notification.save-error:An error has occurred while saving your password.`,
      saveSuccess: $localize`:@@change-password-page.notification.save-success:Your password has been saved successfully.`,
    },
    placeholder: {
      confirmPassword: $localize`:@@change-password-page.placeholder.confirm-password:Confirm password`,
      newPassword: $localize`:@@change-password-page.placeholder.new-password:New password`,
      oldPassword: $localize`:@@change-password-page.placeholder.old-password:Old password`,
    },
  },
  chartsPage: {
    emptyState: {
      title: $localize`:@@charts-page.empty-state.title:Data currently unavailable`,
    },
    legend: {
      batteryCharging: $localize`:@@charts-page.legend.battery-charging:Battery Charging`,
      batteryDischarging: $localize`:@@charts-page.legend.battery-discharging:Battery Discharging`,
      batteryStateOfCharge: $localize`:@@charts-page.legend.battery-state-of-charge:Battery state of charge`,
      connectionCapacity: $localize`:@@charts-page.legend.connection-capacity:Connection capacity`,
      currentTime: $localize`:@@charts-page.legend.current-time:Current Time`,
      day: $localize`:@@charts-page.legend.day:Day`,
      evChargers: $localize`:@@charts-page.legend.ev-chargers:EV Chargers`,
      evChargersCharging: $localize`:@@charts-page.legend.ev-chargers-charging:EV Chargers Charging`,
      evChargersDischarging: $localize`:@@charts-page.legend.ev-chargers-discharging:EV Chargers Discharging`,
      forecastData: $localize`:@@charts-page.legend.forecast-data:Forecast data`,
      grid: $localize`:@@charts-page.legend.grid:Grid`,
      gridConsumption: $localize`:@@charts-page.legend.grid-consumption:Grid Consumption`,
      gridInjection: $localize`:@@charts-page.legend.grid-injection:Grid Injection`,
      gridInjectionLimit: $localize`:@@charts-page.legend.grid-injection-limit:Grid injection limit`,
      night: $localize`:@@charts-page.legend.night:Night`,
      otherLoads: $localize`:@@charts-page.legend.other-loads:Other Loads`,
      peakShaving: $localize`:@@charts-page.legend.peak-shaving:Peak Shaving`,
      realTimeData: $localize`:@@charts-page.legend.real-time-data:Real data`,
      selfGeneration: $localize`:@@charts-page.legend.self-generation:Self generation`,
      selfSufficiency: $localize`:@@charts-page.legend.self-sufficiency:Self sufficiency`,
      solarPanels: $localize`:@@charts-page.legend.solar_panels:Solar Panels`,
      storageSystems: $localize`:@@charts-page.legend.storage_systems:Storage Systems`,
    },
    title: {
      buildingSummary: $localize`:@@charts-page.title.building-summary:Building Summary`,
      performanceMetrics: $localize`:@@charts-page.title.performance-metrics:Performance metrics`,
    },
  },
  createAccountErrorPage: {
    button: {
      back: $localize`:@@create-account-error-page.button.back:Go Back`,
    },
    paragraph: {
      description: (support: string | number) => {
        return format(
          $localize`:@@create-account-error-page.paragraph.description:An issue occurred during the account creation, please try to go back and submit the form again. If the issue is happening again, please contact the {{support}}.`,
          { support },
        );
      },
      support: $localize`:@@create-account-error-page.paragraph.support:support`,
      title: (firstName: string | number) => {
        return format(
          $localize`:@@create-account-error-page.paragraph.title:Sorry {{firstName}}, an error occurred`,
          { firstName },
        );
      },
    },
  },
  createAccountPage: {
    button: {
      sendNewCode: $localize`:@@create-account-page.button.send-new-code:Send New Code`,
      signUp: $localize`:@@create-account-page.button.sign-up:Sign up`,
    },
    hint: {
      newCodeSent: $localize`:@@create-account-page.hint.new-code-sent:Verification code sent successfully.`,
      passwordMustMatch: $localize`:@@create-account-page.hint.password-must-match:Both passwords must match`,
    },
    paragraph: {
      createAnAccount: $localize`:@@create-account-page.paragraph.create-an-account:Create an account`,
    },
    placeholder: {
      confirmPassword: $localize`:@@create-account-page.placeholder.confirm-password:Confirm password`,
      email: $localize`:@@create-account-page.placeholder.email:Email address`,
      firstName: $localize`:@@create-account-page.placeholder.first-name:First Name`,
      lastName: $localize`:@@create-account-page.placeholder.last-name:Last Name`,
      password: $localize`:@@create-account-page.placeholder.password:New password`,
      verificationCode: $localize`:@@create-account-page.placeholder.verification-code:Verification Code`,
    },
    validationError: {
      newCodeNotSent: $localize`:@@create-account-page.validation-error.new-code-not-sent:Verification code couldn't be sent.`,
    },
  },
  createAccountSuccessPage: {
    button: {
      finish: $localize`:@@create-account-success-page.button.finish:Finish`,
    },
    paragraph: {
      description: (email: string | number, button: string | number) => {
        return format(
          $localize`:@@create-account-success-page.paragraph.description:Your account has been successfully created with the email {{email}}. Press {{button}} below to continue.`,
          { email, button },
        );
      },
      title: (firstName: string | number) => {
        return format(
          $localize`:@@create-account-success-page.paragraph.title:Welcome, {{firstName}}!`,
          { firstName },
        );
      },
    },
  },
  dashboardPage: {
    energyDiagram: {
      energyPoint: {
        electricGrid: $localize`:@@dashboard-page.energy-diagram.energy-point.electric-grid:Electric Grid`,
        essentialLoads: $localize`:@@dashboard-page.energy-diagram.energy-point.essential-loads:Essential\\nloads`,
        evChargers: $localize`:@@dashboard-page.energy-diagram.energy-point.ev-chargers:EV Chargers`,
        learnMore: $localize`:@@dashboard-page.energy-diagram.energy-point.learn-more:Learn more`,
        noneConnected: $localize`:@@dashboard-page.energy-diagram.energy-point.none-connected:None connected`,
        offline: $localize`:@@dashboard-page.energy-diagram.energy-point.offline:Offline`,
        otherLoads: $localize`:@@dashboard-page.energy-diagram.energy-point.other-loads:Other Loads`,
        percentageValue: (value: string | number, unit: string | number) => {
          return format(
            $localize`:@@dashboard-page.energy-diagram.energy-point.percentage-value:{{value}} {{unit}}`,
            { value, unit },
          );
        },
        solarPanels: $localize`:@@dashboard-page.energy-diagram.energy-point.solar-panels:Solar Panels`,
        storageSystems: $localize`:@@dashboard-page.energy-diagram.energy-point.storage-systems:Storage Systems`,
        subTitle: {
          electricGrid: $localize`:@@dashboard-page.energy-diagram.energy-point.sub-title.electric-grid:EDF Electricity`,
        },
        timesValue: (times: string | number) => {
          return format(
            $localize`:@@dashboard-page.energy-diagram.energy-point.times-value:x{{times}}`,
            { times },
          );
        },
      },
      title: {
        fetching: $localize`:@@dashboard-page.energy-diagram.title.fetching:FETCHING\\nDATA...`,
        offline: $localize`:@@dashboard-page.energy-diagram.title.offline:SYSTEM OFFLINE`,
        online: $localize`:@@dashboard-page.energy-diagram.title.online:TOTAL \\nPOWER USAGE`,
      },
      totalConsumption: {
        off: $localize`:@@dashboard-page.energy-diagram.total-consumption.off:OFF`,
        on: $localize`:@@dashboard-page.energy-diagram.total-consumption.on:ON`,
        title: $localize`:@@dashboard-page.energy-diagram.total-consumption.title:TOTAL CONSUMPTION`,
      },
    },
    title: {
      lastDays: $localize`:@@dashboard-page.title.last-days:Last 30 days`,
      today: $localize`:@@dashboard-page.title.today:Today`,
    },
    trendCard: {
      title: {
        energyCostsNode: $localize`:@@dashboard-page.trend-card.title.energy-costs-node:Energy costs`,
        evChargerNode: $localize`:@@dashboard-page.trend-card.title.ev-charger-node:EV Charger Consumption`,
        gridNode: $localize`:@@dashboard-page.trend-card.title.grid-node:Grid Energy Consumption`,
        pvNode: $localize`:@@dashboard-page.trend-card.title.pv-node:Renewables Production`,
        selfSufficiencyNode: $localize`:@@dashboard-page.trend-card.title.self-sufficiency-node:Self-Sufficiency`,
      },
      tooltip: {
        energyCostsNode: $localize`:@@dashboard-page.trend-card.tooltip.energy-costs-node:These are estimated values calculated by the system. Please refer to your energy bill for precise costs.`,
        evChargerNode: $localize`:@@dashboard-page.trend-card.tooltip.ev-charger-node:The total power that has been consumed by all electric vehicle chargers combined.`,
        gridNode: $localize`:@@dashboard-page.trend-card.tooltip.gridNode:The power your building has obtained from the grid.`,
        pvNode: $localize`:@@dashboard-page.trend-card.tooltip.pv-node:The combined power your building has produced from renewable sources, like photovoltaic panels and wind turbines.`,
        selfSufficiencyNode: $localize`:@@dashboard-page.trend-card.tooltip.self-sufficiency-node:How much of the renewable electricity production is consumed locally.`,
      },
    },
  },
  deleteAccountPage: {
    button: {
      deleteAccount: $localize`:@@delete-account-page.button.delete-account:Delete account`,
    },
    keyword: {
      delete: $localize`:@@delete-account-page.keyword.delete:DELETE`,
    },
    link: {
      download: $localize`:@@delete-account-page.link.download:Download the account information`,
    },
    notification: {
      deleteError: $localize`:@@delete-account-page.notification.delete-error:An error occurred while deleting your account.`,
      deleteSuccess: $localize`:@@delete-account-page.notification.delete-success:Your account has been successfully deleted.`,
    },
    paragraph: {
      attention: $localize`:@@delete-account-page.paragraph.attention:ATTENTION: `,
      confirm: $localize`:@@delete-account-page.paragraph.confirm:Are you sure you want to delete this account? This process cannot be undone.`,
      deleteAccount: (
        attention: string | number,
        download: string | number,
      ) => {
        return format(
          $localize`:@@delete-account-page.paragraph.delete-account:{{attention}} deleting your account will remove all of your data and device information from the system. Make sure you {{download}} before you delete.`,
          { attention, download },
        );
      },
    },
    placeholder: {
      confirm: (keyword: string | number) => {
        return format(
          $localize`:@@delete-account-page.placeholder.confirm:Type {{keyword}} to confirm`,
          { keyword },
        );
      },
    },
    title: {
      deleteAccount: $localize`:@@delete-account-page.title.delete-account:Delete account`,
    },
  },
  deviceSettingsGeneralPage: {
    notification: {
      lobbyUrlCopied: $localize`:@@device-settings-general-page.notification.lobby-url-copied:URL copied to clipboard`,
      settingsLoadError: $localize`:@@device-settings-general-page.notification.settings-load-error:An error has occurred while loading your settings.`,
      settingsSaveError: $localize`:@@device-settings-general-page.notification.settings-save-error:An error has occured while saving your settings.`,
      settingsSaveSuccess: $localize`:@@device-settings-general-page.notification.settings-save-success:Your settings have been saved successfully.`,
    },
    placeholder: {
      country: $localize`:@@device-settings-general-page.placeholder.country:Country`,
      currency: $localize`:@@device-settings-general-page.placeholder.currency:Currency`,
      lobbyView: $localize`:@@device-settings-general-page.placeholder.lobby-view:Lobby view`,
      postalCode: $localize`:@@device-settings-general-page.placeholder.postal-code:Postal code / locality`,
      serial: $localize`:@@device-settings-general-page.placeholder.serial:Unit serial number`,
      technicianAccess: $localize`:@@device-settings-general-page.placeholder.technician-access:Local technician access`,
      timeZone: $localize`:@@device-settings-general-page.placeholder.time-zone:Timezone`,
      unitCustomName: $localize`:@@device-settings-general-page.placeholder.unit-custom-name:Unit custom name`,
    },
    tooltip: {
      lobbyUrlCopied: $localize`:@@device-settings-general-page.tooltip.lobby-url-copied:Lobby view URL has been copied to clipboard.`,
      lobbyUrlInfo: $localize`:@@device-settings-general-page.tooltip.lobby-url-info:Use this URL to view the BEMS dashboard on a dedicated monitor or television screen.`,
    },
  },
  eulaPage: {
    button: {
      acceptAndProceed: $localize`:@@eula-page.button.accept-and-proceed:Accept and proceed`,
    },
    title: { eula: $localize`:@@eula-page.title.eula:EULA` },
  },
  evChargersPage: {
    gatheringState: {
      description: $localize`:@@ev-chargers-page.gathering-state.description:This process can take up to an hour.`,
      subtitle: $localize`:@@ev-chargers-page.gathering-state.subtitle:The system is now gathering your EV charger details.`,
      title: $localize`:@@ev-chargers-page.gathering-state.title:Gathering data`,
    },
    label: {
      errorCode: $localize`:@@ev-chargers-page.label.error-code:Error code`,
      externalConstraint: $localize`:@@ev-chargers-page.label.external-constraint:BEMS constraint`,
      instantPower: $localize`:@@ev-chargers-page.label.instant-power:Instant power`,
      maximumPower: $localize`:@@ev-chargers-page.label.maximum-power:Maximum power`,
      plugName: (
        index: string | number,
        maxPowerValue: string | number,
        maxPowerUnit: string | number,
      ) => {
        return format(
          $localize`:@@ev-chargers-page.label.plug-name:Type {{index}} - {{maxPowerValue}} {{maxPowerUnit}}`,
          { index, maxPowerValue, maxPowerUnit },
        );
      },
      sessionDuration: $localize`:@@ev-chargers-page.label.session-duration:Session duration`,
    },
    offlineState: {
      description: $localize`:@@ev-chargers-page.offline-state.description:Please review your asset details to solve this issue.`,
      subtitle: $localize`:@@ev-chargers-page.offline-state.subtitle:The system is unable to gather your EV charger details.`,
      title: $localize`:@@ev-chargers-page.offline-state.title:Offline`,
    },
    paragraph: {
      connectors: $localize`:@@ev-chargers-page.paragraph.connectors:EVSEs / Connectors`,
    },
  },
  forbiddenPage: {
    button: { goBack: $localize`:@@forbidden-page.button.go-back:Go Back` },
    paragraph: {
      description: $localize`:@@forbidden-page.paragraph.description:You need additional permissions to access this page.`,
      supportMessage: $localize`:@@forbidden-page.paragraph.support-message:Please contact your system administrator.`,
    },
    title: {
      forbidden: $localize`:@@forbidden-page.title.forbidden:Permission required.`,
    },
  },
  general: {
    assetCategory: {
      binaryLoad: $localize`:@@general.asset-category.binary-load:Binary Load`,
      evCharger: $localize`:@@general.asset-category.ev-charger:EV Charger`,
      powerMeter: $localize`:@@general.asset-category.power-meter:Power Meter`,
      solarPanel: $localize`:@@general.asset-category.solar-panel:Solar Panel`,
      storageUnit: $localize`:@@general.asset-category.storage-unit:Storage Unit`,
    },
    assetEmptyState: {
      powerMeterOfflineDescription: $localize`:@@general.asset-empty-state.power-meter-offline-description:Please review your asset details to solve the issue.`,
      powerMeterOfflineSubtitle: $localize`:@@general.asset-empty-state.power-meter-offline-subtitle:The system is unable to gather your power meter details.`,
      powerMeterGatheringSubtitle: $localize`:@@general.assetEmptyState.power-meter-gathering-subtitle:The system is now gathering your Power meter Unit details.`,
    },
    assetStatus: {
      available: $localize`:@@general.asset-status.available:Available`,
      charging: $localize`:@@general.asset-status.charging:Charging`,
      consuming: $localize`:@@general.asset-status.consuming:Consuming`,
      disabled: $localize`:@@general.asset-status.disabled:Disabled`,
      discharging: $localize`:@@general.asset-status.discharging:Discharging`,
      enabled: $localize`:@@general.asset-status.enabled:Enabled`,
      error: $localize`:@@general.asset-status.error:Error`,
      faulted: $localize`:@@general.asset-status.faulted:Faulted`,
      finishing: $localize`:@@general.asset-status.finishing:Finishing`,
      idle: $localize`:@@general.asset-status.idle:Idle`,
      initialising: $localize`:@@general.asset-status.initialising:Initialising`,
      injecting: $localize`:@@general.asset-status.injecting:Injecting`,
      offline: $localize`:@@general.asset-status.offline:Offline`,
      online: $localize`:@@general.asset-status.online:Online`,
      preparing: $localize`:@@general.asset-status.preparing:Preparing`,
      reserved: $localize`:@@general.asset-status.reserved:Reserved`,
      suspendedevse: $localize`:@@general.asset-status.suspendedevse:Suspended EVSE`,
      unavailable: $localize`:@@general.asset-status.unavailable:Unavailable`,
      unknown: $localize`:@@general.asset-status.unknown:Unknown`,
    },
    button: {
      cancel: $localize`:@@general.button.cancel:Cancel`,
      delete: $localize`:@@general.button.delete:Delete`,
      edit: $localize`:@@general.button.edit:Edit`,
      ok: $localize`:@@general.button.ok:Ok`,
      remove: $localize`:@@general.button.remove:Remove`,
      save: $localize`:@@general.button.save:Save`,
      view: $localize`:@@general.button.view:View`,
    },
    dateFormat: {
      europe: $localize`:@@general.date-format.europe:Europe`,
      us: $localize`:@@general.date-format.us:MM/DD/YY (USA)`,
    },
    dateRange: {
      day: $localize`:@@general.date-range.day:Day`,
      month: $localize`:@@general.date-range.month:Month`,
      week: $localize`:@@general.date-range.week:Week`,
      year: $localize`:@@general.date-range.year:Year`,
    },
    dayOfWeek: {
      friday: $localize`:@@general.day-of-week.friday:Friday`,
      monday: $localize`:@@general.day-of-week.monday:Monday`,
      saturday: $localize`:@@general.day-of-week.saturday:Saturday`,
      sunday: $localize`:@@general.day-of-week.sunday:Sunday`,
      thursday: $localize`:@@general.day-of-week.thursday:Thursday`,
      tuesday: $localize`:@@general.day-of-week.tuesday:Tuesday`,
      wednesday: $localize`:@@general.day-of-week.wednesday:Wednesday`,
    },
    device: {
      unit: (deviceSerial: string | number) => {
        return format($localize`:@@general.device.unit:Unit {{deviceSerial}}`, {
          deviceSerial,
        });
      },
      version: (version: string | number) => {
        return format($localize`:@@general.device.version:v{{version}}`, {
          version,
        });
      },
    },
    labels: {
      notAvailable: $localize`:@@general.labels.not-available:Not available`,
    },
    language: {
      de: $localize`:@@general.language.de:Deutsch`,
      en: $localize`:@@general.language.en:English`,
      fr: $localize`:@@general.language.fr:Français`,
      it: $localize`:@@general.language.it:Italiano`,
      nl: $localize`:@@general.language.nl:Nederlands`,
      no: $localize`:@@general.language.no:Norsk`,
    },
    months: {
      april: $localize`:@@general.months.april:April`,
      august: $localize`:@@general.months.august:August`,
      december: $localize`:@@general.months.december:December`,
      february: $localize`:@@general.months.february:February`,
      january: $localize`:@@general.months.january:January`,
      july: $localize`:@@general.months.july:July`,
      june: $localize`:@@general.months.june:June`,
      march: $localize`:@@general.months.march:March`,
      may: $localize`:@@general.months.may:May`,
      november: $localize`:@@general.months.november:November`,
      october: $localize`:@@general.months.october:October`,
      september: $localize`:@@general.months.september:September`,
    },
    scheduler: {
      actions: {
        delete: $localize`:@@general.scheduler.actions.delete:Delete Event`,
        linkToScheduler: $localize`:@@general.scheduler.actions.link-to-scheduler:Edit in Scheduler`,
      },
      eventActionFieldType: {
        limit: $localize`:@@general.scheduler.event-action-field-type.limit:Max Power Rate`,
        limitAmount: (amount: string | number) => {
          return format(
            $localize`:@@general.scheduler.event-action-field-type.limit-amount:Max Power Rate {{amount}} kW`,
            { amount },
          );
        },
        soc: $localize`:@@general.scheduler.event-action-field-type.soc:State of Charge`,
        socAmount: (amount: string | number) => {
          return format(
            $localize`:@@general.scheduler.event-action-field-type.soc-amount:State of Charge {{amount}} %`,
            { amount },
          );
        },
      },
      eventActionType: {
        evPowerLimit: $localize`:@@general.scheduler.event-action-type.ev-power-limit:Power Limit`,
        evPowerLimitAsset: (assetName: string | number) => {
          return format(
            $localize`:@@general.scheduler.event-action-type.ev-power-limit-asset:{{assetName}} - Power limit`,
            { assetName },
          );
        },
        targetSoc: $localize`:@@general.scheduler.event-action-type.target-soc:Target State of Charge`,
        targetSocAsset: (assetName: string | number) => {
          return format(
            $localize`:@@general.scheduler.event-action-type.target-soc-asset:{{assetName}} - Target State of Charge`,
            { assetName },
          );
        },
      },
      placeholder: {
        title: $localize`:@@general.scheduler.placeholder.title:Scheduling`,
        tooltip: (startTime: string | number, endTime: string | number) => {
          return format(
            $localize`:@@general.scheduler.placeholder.tooltip:From {{startTime}} until {{endTime}}`,
            { startTime, endTime },
          );
        },
      },
    },
    table: {
      filter: {
        empty: $localize`:@@general.table.filter.empty:No result`,
        emptyDescription: $localize`:@@general.table.filter.empty-description:Please try to change your search to get more results.`,
      },
      filtersDialog: {
        button: {
          apply: $localize`:@@general.table.filters-dialog.button.apply:Apply`,
          filters: (count: string | number) => {
            return format(
              $localize`:@@general.table.filters-dialog.button.filters:{count, plural, one {<x id=\count\ equiv-text=\{{count}}\/> filter applied} other {<x id=\count\ equiv-text=\{{count}}\/> filters applied}}`,
              { count },
            );
          },
          noFilters: $localize`:@@general.table.filters-dialog.button.no-filters:Filters`,
          reset: $localize`:@@general.table.filters-dialog.button.reset:Reset`,
        },
        placeholder: {
          dateRange: $localize`:@@general.table.filters-dialog.placeholder.date-range:Enter a date range`,
        },
        timePeriods: {
          custom: $localize`:@@general.table.filters-dialog.time-periods.custom:Custom`,
          lastMonth: $localize`:@@general.table.filters-dialog.time-periods.last-month:Last Month`,
          lastQuarter: $localize`:@@general.table.filters-dialog.time-periods.last-quarter:Last Quarter`,
          thisMonth: $localize`:@@general.table.filters-dialog.time-periods.this-month:This Month`,
          thisQuarter: $localize`:@@general.table.filters-dialog.time-periods.this-quarter:This Quarter`,
        },
        title: {
          filterColumn: $localize`:@@general.table.filters-dialog.title.filter-column:Filter by`,
          filters: $localize`:@@general.table.filters-dialog.title.filters:Filters`,
          timePeriod: $localize`:@@general.table.filters-dialog.title.time-period:Time Period`,
        },
      },
      placeholder: {
        search: (attribute: string | number) => {
          return format(
            $localize`:@@general.table.placeholder.search:Search by {{attribute}}`,
            { attribute },
          );
        },
      },
    },
    timePeriods: {
      monthly: $localize`:@@general.time-periods.monthly:Monthly`,
      yearly: $localize`:@@general.time-periods.yearly:Yearly`,
    },
    unit: {
      energy: {
        gWatt: $localize`:@@general.unit.energy.gWatt:GW`,
        gWh: $localize`:@@general.unit.energy.gWh:GWh`,
        kWatt: $localize`:@@general.unit.energy.kWatt:kW`,
        kWh: $localize`:@@general.unit.energy.kWh:kWh`,
        mWatt: $localize`:@@general.unit.energy.mWatt:MW`,
        mWh: $localize`:@@general.unit.energy.mWh:MWh`,
        perKWh: $localize`:@@general.unit.energy.per-kWh:/kWh`,
        watt: $localize`:@@general.unit.energy.watt:W`,
        wh: $localize`:@@general.unit.energy.wh:Wh`,
      },
      power: { kW: $localize`:@@general.unit.power.kW:kW` },
      shortDay: $localize`:@@general.unit.short-day:d`,
      shortHour: $localize`:@@general.unit.short-hour:h`,
      shortMilli: $localize`:@@general.unit.short-milli:ms`,
      shortMinute: $localize`:@@general.unit.short-minute:m`,
      shortMonth: $localize`:@@general.unit.short-month:m`,
      shortSecond: $localize`:@@general.unit.short-second:s`,
      shortWeek: $localize`:@@general.unit.short-week:w`,
      shortYear: $localize`:@@general.unit.short-year:y`,
      temperature: {
        celsius: $localize`:@@general.unit.temperature.celsius:Celsius`,
        fahrenheit: $localize`:@@general.unit.temperature.fahrenheit:Fahrenheit`,
      },
    },
    userRole: {
      administrator: $localize`:@@general.user-role.administrator:Administrator`,
      reader: $localize`:@@general.user-role.reader:Reader`,
    },
    validationError: {
      invalidEmail: $localize`:@@general.validation-error.invalid-email:The given email address is invalid`,
      invalidPassword: $localize`:@@general.validation-error.invalid-password:The given password does not contain all required types of characters.`,
      passwordsMustMatch: $localize`:@@general.validation-error.passwords-must-match:Both passwords must match`,
      required: $localize`:@@general.validation-error.required:Mandatory field`,
      tooLong: $localize`:@@general.validation-error.too-long:The value is too long`,
      tooSmall: $localize`:@@general.validation-error.too-small:The value is too small`,
    },
    weather: {
      clear: $localize`:@@general.weather.clear:Clear`,
      cloudy: $localize`:@@general.weather.cloudy:Cloudy`,
      drizzle: $localize`:@@general.weather.drizzle:Drizzle`,
      flurries: $localize`:@@general.weather.flurries:Flurries`,
      fog: $localize`:@@general.weather.fog:Fog`,
      freezingDrizzle: $localize`:@@general.weather.freezing-drizzle:Freezing Drizzle`,
      freezingRain: $localize`:@@general.weather.freezing-rain:Freezing Rain`,
      heavyFreezingRain: $localize`:@@general.weather.heavy-freezing-rain:Heavy Freezing Rain`,
      heavyIcePellets: $localize`:@@general.weather.heavy-ice-pellets:Heavy Ice Pellets`,
      heavyRain: $localize`:@@general.weather.heavy-rain:Heavy Rain`,
      heavySnow: $localize`:@@general.weather.heavy-snow:Heavy Snow`,
      icePellets: $localize`:@@general.weather.ice-pellets:Ice Pellets`,
      lightFog: $localize`:@@general.weather.light-fog:Light Fog`,
      lightFreezingRain: $localize`:@@general.weather.light-freezing-rain:Light Freezing Rain`,
      lightIcePellets: $localize`:@@general.weather.light-ice-pellets:Light Ice Pellets`,
      lightRain: $localize`:@@general.weather.light-rain:Light Rain`,
      lightSnow: $localize`:@@general.weather.light-snow:Light Snow`,
      lightWind: $localize`:@@general.weather.light-wind:Light Wind`,
      mostlyClear: $localize`:@@general.weather.mostly-clear:Mostly Clear`,
      mostlyCloudy: $localize`:@@general.weather.mostly-cloudy:Mostly Cloudy`,
      partlyCloudy: $localize`:@@general.weather.partly-cloudy:Partly Cloudy`,
      rain: $localize`:@@general.weather.rain:Rain`,
      snow: $localize`:@@general.weather.snow:Snow`,
      strongWind: $localize`:@@general.weather.strong-wind:Strong Wind`,
      thunderstorm: $localize`:@@general.weather.thunderstorm:Thunderstorm`,
      wind: $localize`:@@general.weather.wind:Wind`,
    },
  },
  gridSettingsPage: {
    labels: {
      end: $localize`:@@grid-settings-page.labels.end:End`,
      read: $localize`:@@grid-settings-page.labels.read:Read`,
      setpoint: $localize`:@@grid-settings-page.labels.setpoint:Setpoint`,
      start: $localize`:@@grid-settings-page.labels.start:Start`,
      tariff: $localize`:@@grid-settings-page.labels.tariff:Tariff`,
    },
    numberOfRates: {
      options: {
        oneRate: $localize`:@@grid-settings-page.number-of-rates.options.one-rate:1 Rate`,
        twoRates: $localize`:@@grid-settings-page.number-of-rates.options.two-rates:2 Rates`,
      },
    },
    placeholders: {
      electricityRate: $localize`:@@grid-settings-page.placeholders.electricity-rate:Electricity rate`,
      numberOfRates: $localize`:@@grid-settings-page.placeholders.number-of-rates:Number of separate rates`,
      offPeakHours: $localize`:@@grid-settings-page.placeholders.off-peak-hours:Off-peak hours`,
      peakHours: $localize`:@@grid-settings-page.placeholders.peak-hours:Peak hours`,
      powerThreshold: $localize`:@@grid-settings-page.placeholders.power-threshold:Power threshold`,
      thresholdGranularity: $localize`:@@grid-settings-page.placeholders.threshold-granularity:Threshold granularity`,
    },
    titles: {
      advancedPeakShaving: $localize`:@@grid-settings-page.titles.advanced-peak-shaving:Advanced Peak Shaving`,
    },
  },
  internalServerErrorPage: {
    button: {
      goBack: $localize`:@@internal-server-error-page.button.go-back:Go Back`,
    },
    paragraph: {
      description: $localize`:@@internal-server-error-page.paragraph.description:Our server has encountered an internal error.`,
      supportEmail: $localize`:@@internal-server-error-page.paragraph.support-email: email our team `,
      supportMessage: (email: string | number) => {
        return format(
          $localize`:@@internal-server-error-page.paragraph.support-message:If you need assistance, please {{email}} and we will respond as soon as possible.`,
          { email },
        );
      },
    },
    title: {
      internalServerError: $localize`:@@internal-server-error-page.title.internal-server-error:Oops! Something went wrong.`,
    },
  },
  invitationAcceptancePage: {
    button: {
      accept: $localize`:@@invitation-acceptance-page.button.accept:Accept`,
    },
    label: {
      accessLevel: $localize`:@@invitation-acceptance-page.label.access-level:Access Level`,
      invitationDate: $localize`:@@invitation-acceptance-page.label.invitation-date:Invitation Date`,
      unitCode: $localize`:@@invitation-acceptance-page.label.unit-code:Unit Code`,
    },
    paragraph: {
      join: (fullName: string | number, name: string | number) => {
        return format(
          $localize`:@@invitation-acceptance-page.paragraph.join:{{fullName}} invited you to join {{name}}`,
          { fullName, name },
        );
      },
    },
    title: {
      join: (name: string | number) => {
        return format(
          $localize`:@@invitation-acceptance-page.title.join:Join {{name}}`,
          { name },
        );
      },
    },
  },
  invitationExpiredPage: {
    paragraph: {
      description: $localize`:@@invitation-expired-page.paragraph.description:That's just one of the ways we keep your account safe.`,
      supportMessage: $localize`:@@invitation-expired-page.paragraph.support-message:Please contact a system administrator and request a new invite.`,
    },
    title: {
      invitationExpired: $localize`:@@invitation-expired-page.title.invitation-expired:This invitation link has expired.`,
    },
  },
  invitationPage: {
    button: {
      back: $localize`:@@invitation-page.button.back:Back to all invitations`,
      removeAccess: $localize`:@@invitation-page.button.remove-access:Remove user access`,
      resendInvitation: $localize`:@@invitation-page.button.resend-invitation:Resend Invite`,
    },
    placeholder: {
      accessLevel: $localize`:@@invitation-page.placeholder.access-level:Access Level`,
      email: $localize`:@@invitation-page.placeholder.email:Email`,
      fullName: $localize`:@@invitation-page.placeholder.full-name:Full Name`,
      invitationDate: $localize`:@@invitation-page.placeholder.invitation-date:Invitation Date`,
    },
    title: {
      invitationDetails: $localize`:@@invitation-page.title.invitation-details:Invitation Details`,
      invitationSent: $localize`:@@invitation-page.title.invitation-sent:Invitation Sent`,
    },
  },
  loginPage: {
    button: { login: $localize`:@@login-page.button.login:Login` },
    link: {
      eula: $localize`:@@login-page.link.eula:End-User License Agreement`,
      signUp: $localize`:@@login-page.link.sign-up:Sign up`,
    },
    notification: {
      loginError: $localize`:@@login-page.notification.login-error:Incorrect Email or Password`,
    },
    paragraph: {
      forgotYourPassword: $localize`:@@login-page.paragraph.forgot-your-password:Forgot your password?`,
      noAccount: (signup: string | number) => {
        return format(
          $localize`:@@login-page.paragraph.no-account:Don't have an account? {{signup}}`,
          { signup },
        );
      },
      terms: (eula: string | number) => {
        return format(
          $localize`:@@login-page.paragraph.terms:By logging in you agree to our {{eula}}`,
          { eula },
        );
      },
    },
    placeholder: {
      email: $localize`:@@login-page.placeholder.email:Email address`,
      password: $localize`:@@login-page.placeholder.password:Password`,
      rememberMe: $localize`:@@login-page.placeholder.remember-me:Remember Me`,
    },
    validationError: {
      email: $localize`:@@login-page.validation-error.email:The given email address is invalid`,
      login: $localize`:@@login-page.validation-error.login:Incorrect Email or Password`,
    },
  },
  loginPortal: {
    title: {
      brightlayer: $localize`:@@login-portal.title.brightlayer:Brightlayer`,
      buildings: $localize`:@@login-portal.title.buildings:Buildings`,
      ems: $localize`:@@login-portal.title.ems:Energy Management Software`,
    },
  },
  noDevicePage: {
    paragraph: {
      description: $localize`:@@no-device-page.paragraph.description:Once you receive an invitation, you will\\nbe able to monitor your installation here.`,
    },
    title: {
      noDevice: $localize`:@@no-device-page.title.no-device:You are not connected to any unit yet.`,
    },
  },
  notFoundPage: {
    button: { goBack: $localize`:@@not-found-page.button.go-back:Go Back` },
    link: {
      backToLogin: $localize`:@@not-found-page.link.back-to-login:Back to Login`,
    },
    paragraph: {
      description: $localize`:@@not-found-page.paragraph.description:We can't find the page you are looking for.`,
      supportEmail: $localize`:@@not-found-page.paragraph.support-email: email our team `,
      supportMessage: (email: string | number) => {
        return format(
          $localize`:@@not-found-page.paragraph.support-message:If you need assistance, please {{email}} and we will respond as soon as possible.`,
          { email },
        );
      },
    },
    title: {
      notFound: $localize`:@@not-found-page.title.not-found:Oops! Page not found.`,
    },
  },
  notificationsDialog: {
    button: {
      markAllAsRead: $localize`:@@notifications-dialog.button.mark-all-as-read:Mark all as read`,
    },
    emptyState: {
      title: $localize`:@@notifications-dialog.empty-state.title:No notification yet.`,
    },
    label: {
      ago: $localize`:@@notifications-dialog.label.ago:ago`,
      timeAgo: (duration: string | number) => {
        return format(
          $localize`:@@notifications-dialog.label.time-ago:{{duration}} ago`,
          { duration },
        );
      },
    },
    notification: {
      markAllAsReadError: $localize`:@@notifications-dialog.notification.mark-all-as-read-error:An error has occurred while marking all the notifications as read.`,
    },
    sort: {
      byDate: $localize`:@@notifications-dialog.sort.by-date:Sort by date`,
      bySeverity: $localize`:@@notifications-dialog.sort.by-severity:Sort by severity`,
    },
    title: {
      notifications: $localize`:@@notifications-dialog.title.notifications:Notifications`,
      unreadNotifications: (count: string | number) => {
        return format(
          $localize`:@@notifications-dialog.title.unread-notifications:({{count}} unread)`,
          { count },
        );
      },
    },
  },
  passwordValidation: {
    atLeast1Digit: $localize`:@@password-validation.at-least-1-digit:At least 1 digit`,
    atLeast1LowercaseLetter: $localize`:@@password-validation.at-least-1-lowercase-letter:At least 1 lowercase letter`,
    atLeast1SpecialChar: $localize`:@@password-validation.at-least-1-special-char:At least 1 special character (!\#$%&amp;'()*+,-./:;&lt;=&gt;?@[]^_&grave;{|}~)`,
    atLeast1UppercaseLetter: $localize`:@@password-validation.at-least-1-uppercase-letter:At least 1 uppercase letter`,
    eightToSixteenCharsLength: $localize`:@@password-validation.eight-to-sixteen-chars-length:Between 8 and 16 characters in length`,
  },
  personalInformationPage: {
    notification: {
      saveError: $localize`:@@personal-information-page.notification.save-error:An error has occurred while saving your personal information.`,
      saveSuccess: $localize`:@@personal-information-page.notification.save-success:Your personal information has been saved successfully.`,
    },
    placeholder: {
      email: $localize`:@@personal-information-page.placeholder.email:Email address`,
      firstName: $localize`:@@personal-information-page.placeholder.first-name:First Name`,
      lastName: $localize`:@@personal-information-page.placeholder.last-name:Last Name`,
    },
  },
  preferencesPage: {
    notification: {
      saveError: $localize`:@@preferences-page.notification.save-error:An error has occurred while saving the preferences.`,
      saveSuccess: $localize`:@@preferences-page.notification.save-success:The preferences have been saved successfully.`,
    },
    placeholder: {
      dateFormat: $localize`:@@preferences-page.placeholder.date-format:Calendar`,
      language: $localize`:@@preferences-page.placeholder.language:Language`,
      temperatureUnit: $localize`:@@preferences-page.placeholder.temperature-unit:Temperature`,
    },
  },
  resetPasswordErrorPage: {
    button: {
      back: $localize`:@@reset-password-error-page.button.back:Go Back`,
    },
    paragraph: {
      error: $localize`:@@reset-password-error-page.paragraph.error:An issue occurred during your password reset, please try to go back and submit the form again. If the issue is happening again, please contact the support.`,
    },
    title: {
      passwordResetFailed: $localize`:@@reset-password-error-page.title.password-reset-failed:Password reset failed`,
    },
  },
  resetPasswordPage: {
    button: {
      next: $localize`:@@reset-password-page.button.next:Next`,
      sendNewCode: $localize`:@@reset-password-page.button.send-new-code:Send New Code`,
    },
    hint: {
      newCodeSent: $localize`:@@reset-password-page.hint.new-code-sent:Verification code sent successfully.`,
      passwordMustMatch: $localize`:@@reset-password-page.hint.password-must-match:Both passwords must match`,
    },
    placeholder: {
      confirmPassword: $localize`:@@reset-password-page.placeholder.confirm-password:Confirm password`,
      email: $localize`:@@reset-password-page.placeholder.email:Email address`,
      password: $localize`:@@reset-password-page.placeholder.password:New password`,
      verificationCode: $localize`:@@reset-password-page.placeholder.verification-code:Verification Code`,
    },
    title: {
      resetPassword: $localize`:@@reset-password-page.title.reset-password:Reset password`,
    },
    validationError: {
      newCodeNotSent: $localize`:@@reset-password-page.validation-error.new-code-not-sent:Verification code couldn't be sent.`,
    },
  },
  resetPasswordSuccessPage: {
    button: {
      backToLogin: $localize`:@@reset-password-success-page.button.back-to-login:Back to Login`,
    },
    paragraph: {
      success: $localize`:@@reset-password-success-page.paragraph.success:You can now use your new password to Login.`,
    },
    title: {
      passwordResetSuccessfully: $localize`:@@reset-password-success-page.title.password-reset-successfully:Password reset successfully`,
    },
  },
  schedulerDialog: {
    placeholder: {
      action: $localize`:@@scheduler-dialog.placeholder.action:Action`,
      asset: $localize`:@@scheduler-dialog.placeholder.asset:Asset`,
      assetType: $localize`:@@scheduler-dialog.placeholder.asset-type:Asset type`,
      from: $localize`:@@scheduler-dialog.placeholder.from:From`,
      maxPowerRate: $localize`:@@scheduler-dialog.placeholder.max-power-rate:Max Power Rate`,
      stateOfCharge: $localize`:@@scheduler-dialog.placeholder.state-of-charge:State of Charge`,
      until: $localize`:@@scheduler-dialog.placeholder.until:Until`,
    },
  },
  schedulerPage: {
    addButton: $localize`:@@scheduler-page.add-button:Add Event`,
    addDescription: $localize`:@@scheduler-page.add-description:Events added to the Routine Scheduler will repeat every day.`,
    editTitle: $localize`:@@scheduler-page.edit-title:Edit Event`,
    emptyState: {
      description: $localize`:@@scheduler-page.empty-state.description:Unfortunately, you do not have any EV charger in your installation that support scheduling.`,
      descriptionNoEvent: $localize`:@@scheduler-page.empty-state.description-no-event:You can add an event to control the behavior of your asset within the 24 hours of the day.`,
      title: $localize`:@@scheduler-page.empty-state.title:No schedulable assets`,
      titleNoEvent: $localize`:@@scheduler-page.empty-state.title-no-event:No event`,
    },
    notification: {
      deleteEventError: $localize`:@@scheduler-page.notification.delete-event-error:An error has occurred while deleting event`,
      deleteEventSuccess: $localize`:@@scheduler-page.notification.delete-event-success:The event has been deleted successfully`,
      saveEventError: $localize`:@@scheduler-page.notification.save-event-error:An error has occurred while saving the event.`,
      saveEventOverlapError: $localize`:@@scheduler-page.notification.save-event-overlap-error:Invalid scheduled event: the event overlaps in the scheduler for the selected asset.`,
      saveEventSuccess: $localize`:@@scheduler-page.notification.save-event-success:The event has been saved successfully.`,
    },
    timeLineTitle: $localize`:@@scheduler-page.time-line-title:Assets`,
  },
  serviceUnavailablePage: {
    paragraph: {
      description: $localize`:@@service-unavailable-page.paragraph.description:EMS is temporarily offline.`,
      serviceDown: $localize`:@@service-unavailable-page.paragraph.service-down:Cloud device2cloud communication service is down.`,
    },
    title: {
      serviceUnavailable: $localize`:@@service-unavailable-page.title.service-unavailable:Oops! Something went wrong.`,
    },
  },
  sessionExpiredPage: {
    button: { login: $localize`:@@session-expired-page.button.login:Login` },
    paragraph: {
      description: $localize`:@@session-expired-page.paragraph.description:That's just one of the ways we keep your account safe.`,
      login: $localize`:@@session-expired-page.paragraph.login:Please login again to access your dashboard.`,
    },
    title: {
      sessionExpired: $localize`:@@session-expired-page.title.session-expired:Your session has expired.`,
    },
  },
  settingsPage: {
    menu: {
      general: $localize`:@@settings-page.menu.general:General`,
      grid: $localize`:@@settings-page.menu.grid:Grid`,
      license: $localize`:@@settings-page.menu.license:License`,
      support: $localize`:@@settings-page.menu.support:Support`,
      systemGoals: $localize`:@@settings-page.menu.system-goals:System Goals`,
    },
  },
  solarPanelPage: {
    gatheringState: {
      description: $localize`:@@solar-panel-page.gathering-state.description:This process can take up to an hour.`,
      subtitle: $localize`:@@solar-panel-page.gathering-state.subtitle:The system is now gathering your Solar Panel details.`,
      title: $localize`:@@solar-panel-page.gathering-state.title:Gathering data`,
    },
    label: {
      current: $localize`:@@solar-panel-page.label.current:Current`,
      curtailmentLimit: $localize`:@@solar-panel-page.label.curtailment-limit:Curtailment Limit`,
      installedPvCapacity: $localize`:@@solar-panel-page.label.installed-pv-capacity:Installed PV Capacity`,
      inverterCapacity: $localize`:@@solar-panel-page.label.inverter-capacity:Inverter Capacity`,
      inverterTemperature: $localize`:@@solar-panel-page.label.inverter-temperature:Inverter Temperature`,
      phase1: $localize`:@@solar-panel-page.label.phase-1:Phase 1`,
      phase2: $localize`:@@solar-panel-page.label.phase-2:Phase 2`,
      phase3: $localize`:@@solar-panel-page.label.phase-3:Phase 3`,
      power: $localize`:@@solar-panel-page.label.power:Power`,
      totalAcActivePower: $localize`:@@solar-panel-page.label.total-ac-active-power:Total AC Active Power`,
      totalDcPower: $localize`:@@solar-panel-page.label.total-dc-power:Total DC Power`,
      voltage: $localize`:@@solar-panel-page.label.voltage:Voltage`,
    },
    offlineState: {
      description: $localize`:@@solar-panel-page.offline-state.description:Please review your asset details to solve the issue.`,
      subtitle: $localize`:@@solar-panel-page.offline-state.subtitle:The system is unable to gather your Solar Panel details.`,
      title: $localize`:@@solar-panel-page.offline-state.title:Offline`,
    },
    title: {
      activePower: $localize`:@@solar-panel-page.title.active-power:Active Power`,
      apparentPower: $localize`:@@solar-panel-page.title.apparent-power:Apparent Power`,
      current: $localize`:@@solar-panel-page.title.current:Current`,
      inverterAcOutput: $localize`:@@solar-panel-page.title.inverter-ac-output:Inverter AC output`,
      maximumPowerPointTracking: $localize`:@@solar-panel-page.title.maximum-power-point-tracking:Maximum Power Point Tracking`,
      mppTracker: (number: string | number) => {
        return format(
          $localize`:@@solar-panel-page.title.mpp-tracker:MPP Tracker {{number}}`,
          { number },
        );
      },
      pvArrayDcInput: $localize`:@@solar-panel-page.title.pv-array-dc-input:PV Array DC input`,
      reactivePower: $localize`:@@solar-panel-page.title.reactive-power:Reactive Power`,
      voltage: $localize`:@@solar-panel-page.title.voltage:Voltage`,
    },
  },
  storageUnitPage: {
    gatheringState: {
      description: $localize`:@@storage-unit-page.gathering-state.description:This process can take up to an hour.`,
      subtitle: $localize`:@@storage-unit-page.gathering-state.subtitle:The system is now gathering your Storage Unit details.`,
      title: $localize`:@@storage-unit-page.gathering-state.title:Gathering data`,
    },
    label: {
      activePower: $localize`:@@storage-unit-page.label.active-power:Active Power`,
      apparentPower: $localize`:@@storage-unit-page.label.apparent-power:Apparent Power`,
      capacityAvailableToCharge: $localize`:@@storage-unit-page.label.capacity-available-to-charge:Capacity Available to Charge`,
      current: $localize`:@@storage-unit-page.label.current:Current`,
      energyAvailableToDischarge: $localize`:@@storage-unit-page.label.energy-available-to-discharge:Energy Available to Discharge`,
      firmwareVersion: $localize`:@@storage-unit-page.label.firmware-version:Firmware Version`,
      frequency: $localize`:@@storage-unit-page.label.frequency:Frequency`,
      maxChargePower: $localize`:@@storage-unit-page.label.max-charge-power:Max Charge Power`,
      maxDischargePower: $localize`:@@storage-unit-page.label.max-discharge-power:Max Discharge Power`,
      outOfTotalStrings: (totalStrings: string | number) => {
        return format(
          $localize`:@@storage-unit-page.label.out-of-total-strings:out of {{totalStrings}}`,
          { totalStrings },
        );
      },
      phase1: $localize`:@@storage-unit-page.label.phase-1:Phase 1`,
      phase2: $localize`:@@storage-unit-page.label.phase-2:Phase 2`,
      phase3: $localize`:@@storage-unit-page.label.phase-3:Phase 3`,
      powerFactor: $localize`:@@storage-unit-page.label.power-factor:Power Factor`,
      reactivePower: $localize`:@@storage-unit-page.label.reactive-power:Reactive Power`,
      stateOfCharge: $localize`:@@storage-unit-page.label.state-of-charge:State of Charge`,
      stringsOnline: $localize`:@@storage-unit-page.label.strings-online:Strings Online`,
      total: $localize`:@@storage-unit-page.label.total:Total`,
      voltage: $localize`:@@storage-unit-page.label.voltage:Voltage`,
    },
    offlineState: {
      description: $localize`:@@storage-unit-page.offline-state.description:Please review your asset details to solve this issue.`,
      subtitle: $localize`:@@storage-unit-page.offline-state.subtitle:The system is unable to gather your Storage Unit details.`,
      title: $localize`:@@storage-unit-page.offline-state.title:Offline`,
    },
    title: {
      systemSummary: $localize`:@@storage-unit-page.title.system-summary:System Summary`,
      x1InputGridSide: $localize`:@@storage-unit-page.title.x1-input-grid-side:X1 Input (Grid side)`,
      x3OutputBackupSide: $localize`:@@storage-unit-page.title.x3-output-backup-side:X3 Output (Back-up side)`,
    },
    tooltip: {
      activePower: $localize`:@@storage-unit-page.tooltip.active-power:Also know as True Power, Real Power, Useful Power or Watt-full Power. It represents the Power which is really utilized and consumed for useful works in AC or DC circuit. Active power is expressed in W.`,
      apparentPower: $localize`:@@storage-unit-page.tooltip.apparent-power:The combination of active and reactive power, or the product of voltage and current if and only if the phase angle differences between current and voltage are ignored. Apparent power is expressed in VA.`,
      capacityAvailableToCharge: $localize`:@@storage-unit-page.tooltip.capacity-available-to-charge:Amount of energy that battery can charge`,
      energyAvailableToDischarge: $localize`:@@storage-unit-page.tooltip.energy-available-to-discharge:Amount of energy that battery can discharge`,
      maxChargePower: $localize`:@@storage-unit-page.tooltip.max-charge-power:Maximum allowable charging power`,
      maxDischargePower: $localize`:@@storage-unit-page.tooltip.max-discharge-power:Maximum allowable discharging power`,
      powerFactor: $localize`:@@storage-unit-page.tooltip.power-factor:Power factor (PF) is the ratio of active power to apparent power.`,
      reactivePower: $localize`:@@storage-unit-page.tooltip.reactive-power:Also known as Useless Power or Watt-less Power. It represents the power which moves and back (bounces back and forth) between source and load in AC circuits. Reactive power is expressed in Var.`,
      stringsOnline: $localize`:@@storage-unit-page.tooltip.strings-online:Number of battery strings online`,
    },
    topMetric: {
      activePowerX1: $localize`:@@storage-unit-page.top-metric.active-power-x1:Active Power X1`,
      activePowerX3: $localize`:@@storage-unit-page.top-metric.active-power-x3:Active Power X3`,
      frequency: $localize`:@@storage-unit-page.top-metric.frequency:Frequency`,
      maxCellTemperature: $localize`:@@storage-unit-page.top-metric.max-cell-temperature:Max. Cell Temperature`,
      powerFactor: $localize`:@@storage-unit-page.top-metric.power-factor:Power Factor`,
      stateOfCharge: $localize`:@@storage-unit-page.top-metric.state-of-charge:State of Charge`,
      totalActivePower: $localize`:@@storage-unit-page.top-metric.total-active-power:Total Active Power`,
      totalApparentPower: $localize`:@@storage-unit-page.top-metric.total-apparent-power:Total Apparent Power`,
      totalCurrent: $localize`:@@storage-unit-page.top-metric.total-current:Total Current`,
      totalReactivePower: $localize`:@@storage-unit-page.top-metric.total-reactive-power:Total Reactive Power`,
    },
  },
  supportPage: {
    action: {
      contactUs: $localize`:@@support-page.action.contact-us:Contact us`,
    },
    downloadItem: {
      downloadUnitLogs: {
        details: $localize`:@@support-page.download-item.download-unit-logs.details:Containing all changes done to this unit by the system and users`,
        title: $localize`:@@support-page.download-item.download-unit-logs.title:Download unit logs`,
      },
      systemInformation: {
        details: $localize`:@@support-page.download-item.system-information.details:Please attach the downloaded file to your support email request`,
        title: $localize`:@@support-page.download-item.system-information.title:System information`,
      },
    },
    paragraph: {
      support: (email: string | number) => {
        return format(
          $localize`:@@support-page.paragraph.support:For all support queries relating to Eaton BEMS - Building Energy Management System, please email {{email}}`,
          { email },
        );
      },
    },
  },
  systemGoalsPage: {
    subtitle: {
      limitEvCharging: $localize`:@@system-goals-page.subtitle.limit-ev-charging:Limit EV charging to avoid peak power consumption.`,
      maximizePower: $localize`:@@system-goals-page.subtitle.maximize-power:Maximize the power send to EVs regardless of the energy cost.`,
    },
    title: {
      maximizeEvCharging: $localize`:@@system-goals-page.title.maximize-ev-charging:Maximize EV Charging`,
      reduceCosts: $localize`:@@system-goals-page.title.reduce-costs:Reduce Costs`,
    },
  },
  topbar: {
    account: {
      eula: $localize`:@@topbar.account.eula:EULA`,
      settings: $localize`:@@topbar.account.settings:Account settings`,
      signOut: $localize`:@@topbar.account.sign-out:Sign out`,
    },
  },
  userInvitationDialog: {
    placeholder: {
      emailAddress: $localize`:@@user-invitation-dialog.placeholder.email-address:Email address`,
      role: $localize`:@@user-invitation-dialog.placeholder.role:Role`,
    },
  },
  userPage: {
    alert: {
      productUpdates: {
        description: $localize`:@@user-page.alert.product-updates.description:New releases`,
        name: $localize`:@@user-page.alert.product-updates.name:Product Updates`,
      },
      statusUpdates: {
        description: $localize`:@@user-page.alert.status-updates.description:Device is disconnected`,
        name: $localize`:@@user-page.alert.status-updates.name:Status Updates`,
      },
      summaryReports: {
        description: $localize`:@@user-page.alert.summary-reports.description:Monthly`,
        name: $localize`:@@user-page.alert.summary-reports.name:Summary Reports`,
      },
    },
    button: {
      back: $localize`:@@user-page.button.back:Back to all users`,
      removeAccess: $localize`:@@user-page.button.remove-access:Remove Access`,
    },
    notification: {
      saveEmailAlertsError: $localize`:@@user-page.notification.save-email-alerts-error:An error has occurred while saving your email alerts.`,
      saveEmailAlertsSuccess: $localize`:@@user-page.notification.save-email-alerts-success:Your email alerts have been saved successfully.`,
      saveUserDetailsError: $localize`:@@user-page.notification.save-user-details-error:An error has occurred while saving the user details.`,
      saveUserDetailsSuccess: $localize`:@@user-page.notification.save-user-details-success:The user details have been saved successfully.`,
    },
    placeholder: {
      active: $localize`:@@user-page.placeholder.active:Account Status`,
      displayName: $localize`:@@user-page.placeholder.display-name:Full Name`,
      email: $localize`:@@user-page.placeholder.email:Email`,
      invitationDate: $localize`:@@user-page.placeholder.invitation-date:Invitation Date`,
      role: $localize`:@@user-page.placeholder.role:Access Level`,
    },
    title: {
      emailAlerts: $localize`:@@user-page.title.email-alerts:My Email Alerts`,
      userDetails: $localize`:@@user-page.title.user-details:User Details`,
    },
  },
  usersPage: {
    button: {
      addUser: $localize`:@@users-page.button.add-user:Add User`,
      removeAccess: $localize`:@@users-page.button.remove-access:Remove user access`,
      resend: $localize`:@@users-page.button.resend:Resend Invite`,
      sendInvitation: $localize`:@@users-page.button.send-invitation:Send Invitation`,
    },
    column: {
      displayName: $localize`:@@users-page.column.display-name:Name`,
      email: $localize`:@@users-page.column.email:Name`,
      role: $localize`:@@users-page.column.role:Role`,
      status: $localize`:@@users-page.column.status:Status`,
    },
    flag: { you: $localize`:@@users-page.flag.you:you` },
    invitationsEmptyState: {
      button: $localize`:@@users-page.invitations-empty-state.button:Go to users list`,
      description: $localize`:@@users-page.invitations-empty-state.description:You can invite a user by going to the users list and click the add user button.`,
      title: $localize`:@@users-page.invitations-empty-state.title:No pending user yet.`,
    },
    notification: {
      deleteInvitationError: $localize`:@@users-page.notification.delete-invitation-error:An error has occurred while removing the access of this user.`,
      deleteInvitationSuccess: $localize`:@@users-page.notification.delete-invitation-success:The access to this user has been removed successfully.`,
      deletePermissionError: $localize`:@@users-page.notification.delete-permission-error:An error occurred while removing access for that user.`,
      deletePermissionSuccess: $localize`:@@users-page.notification.delete-permission-success:User successfully deleted.`,
      resendInvitationError: $localize`:@@users-page.notification.resend-invitation-error:An error occurred while resending the invitation.`,
      resendInvitationSuccess: $localize`:@@users-page.notification.resend-invitation-success:The invitation has been resent successfully.`,
      sendInvitationError: $localize`:@@users-page.notification.send-invitation-error:An error occurred while sending the invitation to the given email address.`,
      sendInvitationInvalidEmailError: $localize`:@@users-page.notification.send-invitation-invalid-email-error:An error occurred while sending the invitation because the email address was invalid.`,
      sendInvitationSuccess: $localize`:@@users-page.notification.send-invitation-success:The invitation has been sent successfully.`,
      sendInvitationWarning: $localize`:@@users-page.notification.send-invitation-warning:An invitation to the given email address already exists.`,
    },
    paragraph: {
      accountsUsed: (count: string | number, total: string | number) => {
        return format(
          $localize`:@@users-page.paragraph.accounts-used:{{count}} of {{total}} accounts used`,
          { count, total },
        );
      },
    },
    removeUserAccessDialog: {
      description: $localize`:@@users-page.remove-user-access-dialog.description:Are you sure you want to remove access for this user?`,
      title: $localize`:@@users-page.remove-user-access-dialog.title:Remove user access`,
    },
    resendInvitationDialog: {
      description: $localize`:@@users-page.resend-invitation-dialog.description:Do you wish to resend the invite?`,
      title: $localize`:@@users-page.resend-invitation-dialog.title:Resend Invite`,
    },
    status: {
      active: $localize`:@@users-page.status.active:Active`,
      inactive: $localize`:@@users-page.status.inactive:Inactive`,
      pending: $localize`:@@users-page.status.pending:Pending approval`,
    },
    tab: {
      invitations: $localize`:@@users-page.tab.invitations:Pending Users`,
      users: $localize`:@@users-page.tab.users:Users`,
    },
    transferOwnershipDialog: {
      button: {
        transferOwnership: $localize`:@@users-page.transfer-ownership-dialog.button.transfer-ownership:Transfer Ownership`,
      },
      paragraph: {
        description: $localize`:@@users-page.transfer-ownership-dialog.paragraph.description:You are the sole administrator on this account. You must provide another user account with admin access level before we can remove your access`,
      },
      placeholder: {
        newAdminEmail: $localize`:@@users-page.transfer-ownership-dialog.placeholder.new-admin-email:New Admin Email`,
      },
    },
  },
  verifyEmailPage: {
    button: {
      sendVerificationCode: $localize`:@@verify-email-page.button.send-verification-code:Send Verification Code`,
    },
    link: {
      backToLogin: $localize`:@@verify-email-page.link.back-to-login:Back to Login`,
      login: $localize`:@@verify-email-page.link.login:Login`,
    },
    paragraph: {
      hasAccount: (signup: string | number) => {
        return format(
          $localize`:@@verify-email-page.paragraph.has-account:Already have an account? {{signup}}`,
          { signup },
        );
      },
    },
    placeholder: {
      email: $localize`:@@verify-email-page.placeholder.email:Email address`,
    },
    title: {
      createAnAccount: $localize`:@@verify-email-page.title.create-an-account:Create an account`,
      resetPassword: $localize`:@@verify-email-page.title.reset-password:Reset password`,
    },
    validationError: {
      email: {
        conflict: $localize`:@@verify-email-page.validation-error.email.conflict:Given email already exists`,
      },
    },
  },
};

function format(str: string, params: Record<string, string | number>): string {
  // we are in the case of a plural message
  if (str.startsWith('{') && str.endsWith('}') && str.includes('plural')) {
    return formatMessage(str, params);
  }

  const newStr = str.replace(/{{/gm, '{').replace(/}}/gm, '}');

  return formatMessage(newStr, params);
}
