import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ProgressSpinnerComponent } from '../progress-spinner';
import { ImageComponent } from './image.component';

@NgModule({
  declarations: [ImageComponent],
  exports: [ImageComponent],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    ProgressSpinnerComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ImageModule {}
