import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  Optional,
  Output,
  Provider,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormControlStatus,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { isEmpty } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs';

export const CONTROL_CONTAINER_VIEW_PROVIDER: Provider = {
  provide: ControlContainer,
  useFactory: (container: ControlContainer) => {
    return container;
  },
  deps: [[new SkipSelf(), new Optional(), ControlContainer]],
};

export const FORM_FIELD_APPEARANCE = new InjectionToken<MatFormFieldAppearance>(
  'Appearance',
);

export const HIDE_REQUIRED_MARKER = new InjectionToken<boolean>(
  'hideRequiredMarker',
);

@Component({ template: '' })
export abstract class AbstractFormFieldComponent<T> implements OnChanges {
  public constructor(
    public container: ControlContainer,
    @Optional()
    @Inject(FORM_FIELD_APPEARANCE)
    public defaultAppearance: MatFormFieldAppearance,
    @Optional()
    @Inject(HIDE_REQUIRED_MARKER)
    public hideRequiredMarker: boolean,
  ) {
    this.setupFormControl();
  }

  public formControl: FormControl = new FormControl();

  @Input() public controlName: string | number | null = null;
  @Input() public placeholder: string | Nil = '';
  @Input() public hint: string | Nil;
  @Input() public validationErrorMessage: string | Nil;
  @Input() public set disabled(disabled: boolean | Nil) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input() public set required(required: boolean | Nil) {
    if (required) {
      this.formControl.addValidators([Validators.required]);
    } else {
      this.formControl.removeValidators([Validators.required]);
    }
    this.formControl.updateValueAndValidity();
  }

  public get required(): boolean {
    return this.formControl.hasValidator(Validators.required);
  }

  @Input() public appearance: MatFormFieldAppearance =
    this.defaultAppearance || 'fill';

  @Input() public set value(value: T | Nil) {
    this.formControl.setValue(value);
    this.onFormControlInit();
  }

  public get value(): T | Nil {
    return this.formControl.value;
  }

  private valueChangeSubject$ = new BehaviorSubject<Observable<any>>(
    this.formControl.valueChanges,
  );

  @Output() public valueChange = this.valueChangeSubject$.pipe(
    switchMap((valueChange) => {
      return valueChange;
    }),
    distinctUntilChanged(),
  );

  private statusChangeSubject$ = new BehaviorSubject<
    Observable<FormControlStatus>
  >(this.formControl.statusChanges);

  @Output() public statusChange = this.statusChangeSubject$.pipe(
    switchMap((statusChange) => {
      return statusChange;
    }),
    startWith(undefined),
    distinctUntilChanged(),
  );

  public ngOnChanges({ controlName }: SimpleChanges): void {
    if (
      isNotNil(this.container) &&
      isNotNil(controlName) &&
      isNotNil(this.controlName)
    ) {
      this.formControl = (this.container as FormGroupDirective).form.controls[
        this.controlName
      ] as FormControl;
      this.setupFormControl();
      this.onFormControlInit();
    }
  }

  public get empty(): boolean {
    return isEmpty(this.formControl.value);
  }

  protected onFormControlInit() {}

  protected onValidatorsAdd(_validators: ValidatorFn | ValidatorFn[]) {}
  protected onValidatorsRemove(_validators: ValidatorFn | ValidatorFn[]) {}
  protected onDisable(_opts?: { onlySelf?: boolean; emitEvent?: boolean }) {}
  protected onEnable(_opts?: { onlySelf?: boolean; emitEvent?: boolean }) {}

  // add extra callbacks to the form control that are called when
  // validators and enable/disable state are changing
  private setupFormControl() {
    this.statusChangeSubject$.next(this.formControl.statusChanges);
    this.valueChangeSubject$.next(this.formControl.valueChanges);

    const addValidators = this.formControl.addValidators.bind(this.formControl);
    const removeValidators = this.formControl.removeValidators.bind(
      this.formControl,
    );
    const disable = this.formControl.disable.bind(this.formControl);
    const enable = this.formControl.enable.bind(this.formControl);

    this.formControl.addValidators = (
      validators: ValidatorFn | ValidatorFn[],
    ) => {
      addValidators(validators);
      this.onValidatorsAdd(validators);
    };

    this.formControl.removeValidators = (
      validators: ValidatorFn | ValidatorFn[],
    ) => {
      removeValidators(validators);
      this.onValidatorsRemove(validators);
    };

    this.formControl.disable = (opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }) => {
      disable(opts);
      this.onDisable();
    };

    this.formControl.enable = (opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }) => {
      enable(opts);
      this.onEnable();
    };
  }
}
