import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { isNotNil } from '@core/is-not-nil';
import { filterNil } from '@core/rxjs/filter-nil';
import { CSRF_ACCESS_TOKEN } from '@store/bems-cloud/auth/auth.const';
import { CurrentUserService } from '@store/bems-cloud/current-user';
import { isEmpty } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs';
import { NavigationService } from '../services';

export const AuthGuard: CanActivateFn = () => {
  const cookie = inject(CookieService);
  const navigationService = inject(NavigationService);
  const currentUserService = inject(CurrentUserService);
  const csrfAccessToken = cookie.get(CSRF_ACCESS_TOKEN);
  currentUserService.currentUser$.pipe(
    filterNil(),
    map((user) => {
      if (isNotNil(user) && isEmpty(csrfAccessToken)) {
        navigationService.navigateToLogin();
        return false;
      }
      return true;
    }),
  );
  return true;
};
