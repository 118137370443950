import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Nil } from '@model';
import { Toast } from 'ngx-toastr';

import { Action, ActionComponent } from '../action';
import { IconComponent, IconName, IconSize } from '../icon';

@Component({
  selector: 'etn-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, ActionComponent],
})
export class NotificationComponent extends Toast implements OnInit {
  public iconName: IconName | Nil;
  public iconSize: IconSize = IconSize.Medium;

  public action: Action | Nil;

  public ngOnInit(): void {
    if (this.toastClasses.startsWith('toast-success')) {
      this.iconName = 'check_circle';
    } else if (this.toastClasses.startsWith('toast-error')) {
      this.iconName = 'cancel';
    } else if (this.toastClasses.startsWith('toast-info')) {
      this.iconName = 'info';
    } else if (this.toastClasses.startsWith('toast-warning')) {
      this.iconName = 'error';
    }
  }
}
