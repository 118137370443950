import { Direction, Directionality } from '@angular/cdk/bidi';

export const RTL_LANGUAGES = [
  'ar',
  'arc',
  'dv',
  'fa',
  'ha',
  'he',
  'khw',
  'ks',
  'ku',
  'ps',
  'ur',
  'yi',
];

export function getDirection(lang: string): Direction {
  return RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr';
}

export function isRtl(dir: Directionality): boolean {
  return dir.value === 'rtl';
}
