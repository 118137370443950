import { get } from 'lodash-es';

import { Nil } from './nil';

export type Embedded<T> = Record<string, T[]>;

export interface WithEmbedded<T> {
  _embedded?: Embedded<T>;
}

export function hasEmbedded<T>(
  embedded: WithEmbedded<T>,
  name: string,
): boolean {
  return getEmbedded(embedded, name) !== undefined;
}

export function getEmbedded<T>(
  embedded: WithEmbedded<T>,
  name: string,
): T[] | Nil {
  return get(embedded, ['_embedded', name], undefined);
}

export function makeEmbedded(): Embedded<any> {
  return {};
}
