import { ApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { actionNameFactory } from '@core/ngrx';
import { NotificationCount } from '@model/bems-cloud/bems/notifications';
import { createAction, props } from '@ngrx/store';

const createActionName = actionNameFactory('Notifications');

export const fetchNotificationCount = createAction(
  createActionName('fetch notifications count'),
);

export const fetchNotificationCountSuccess = createAction(
  createActionName('fetch notifications count success'),
  props<NotificationCount>(),
);

export const fetchNotificationCountError = createAction(
  createActionName('fetch notifications count error'),
  props<ApiErrorResponse>(),
);
