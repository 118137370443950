import { WithId, WithName } from '@model';

export interface Currency extends WithId, WithName {
  symbol: string;
  code: string;
}

export interface CurrencyExtended extends Currency {
  symbol: string;
  code: string;
  extendedName: string;
}
