import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { isNotNil } from '@core/is-not-nil';
import { filterNil } from '@core/rxjs/filter-nil';
import { CurrentDeviceService } from '@store/bems-cloud/current-device';
import { isNil } from 'lodash-es';
import { combineLatest, map } from 'rxjs';
import { NavigationService } from '../services';

export const currentDeviceGuard: CanActivateFn = () => {
  const currentDeviceService = inject(CurrentDeviceService);
  const navigationService = inject(NavigationService);
  return combineLatest([
    currentDeviceService.device$,
    currentDeviceService.devices$.pipe(filterNil()),
  ]).pipe(
    map(([device, devices]) => {
      if (isNil(device)) {
        if (isNotNil(devices) && devices.length > 0) {
          navigationService.navigateToDashboard(devices[0]);
        } else {
          navigationService.navigateToNoDevicePage();
        }
        return false;
      }
      return true;
    }),
  );
};
