import { Injectable } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Loading, RouteLoading } from '@layout/loading';
import { Id } from '@model';
import { CurrentLocation, MenuItem } from '@model/bems-cloud/bems//layout';
import { Nil } from '@model/nil';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, debounceTime, map } from 'rxjs';

import { Router } from '@angular/router';
import {
  ASSETS_NAV_ITEM_ID,
  ASSETS_URL_SEGMENT,
  USERS_NAV_ITEM_ID,
  USERS_URL_SEGMENT,
} from '@core/bems-cloud/bems/const';
import { TrackingService } from '@core/tracking';
import { findIndex } from 'lodash-es';
import { State } from '../store.state';
import {
  setActiveMenuItem,
  setCurrentLocation,
  setLoading,
  setRouteLoading,
  showTopBar,
} from './layout.actions';
import {
  getCurrentLocation,
  getCurrentLocationActiveMenuItemId,
  getCurrentLocationId,
  getCurrentLocationMenu,
  getCurrentLocationName,
  getLoading,
  getRouteLoading,
  getShowTopBar,
} from './layout.selectors';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public constructor(
    private store: Store<State>,
    private trackingService: TrackingService,
    private router: Router,
  ) {
    // no need to unsubscribe because we need this to be continuously running
    this.location$.pipe(debounceTime(500)).subscribe((location) => {
      this.trackLocation(location);
    });
  }

  private scrollTopSubject$ = new BehaviorSubject<number>(0);

  public scrollTop$ = this.scrollTopSubject$.asObservable();

  public showTopBar$: Observable<boolean> = this.store.select(getShowTopBar);

  public loading$: Observable<Loading | Nil> = this.store.select(getLoading);

  public routeLoading$: Observable<RouteLoading | Nil> =
    this.store.select(getRouteLoading);

  public location$: Observable<CurrentLocation | Nil> =
    this.store.select(getCurrentLocation);

  public locationName$: Observable<string | Nil> = this.store.select(
    getCurrentLocationName,
  );

  public locationMenu$: Observable<MenuItem[] | Nil> = this.store.select(
    getCurrentLocationMenu,
  );

  public locationHasMenu$: Observable<boolean> = this.locationMenu$.pipe(
    map(isNotNil),
  );

  public activeMenuItemId$: Observable<Id | Nil> = this.store.select(
    getCurrentLocationActiveMenuItemId,
  );

  public locationId$: Observable<Id | Nil> =
    this.store.select(getCurrentLocationId);

  public showTopBar(show: boolean): void {
    this.store.dispatch(showTopBar({ showTopBar: show }));
  }

  public setLocation(location: CurrentLocation | Nil): void {
    this.store.dispatch(setCurrentLocation({ location }));
  }

  public setActiveMenuItem(activeMenuItemId: Id): void {
    this.store.dispatch(setActiveMenuItem({ activeMenuItemId }));
  }

  public setLoading(loading: Loading | Nil): void {
    this.store.dispatch(setLoading({ loading }));
  }

  public setRouteLoading(routeLoading: RouteLoading | Nil): void {
    this.store.dispatch(setRouteLoading({ routeLoading }));
  }

  public setScrollTop(scrollTop: number): void {
    this.scrollTopSubject$.next(scrollTop);
  }

  private trackLocation(location: CurrentLocation | Nil) {
    if (isNotNil(location)) {
      this.trackingService.trackView(
        this.getViewName(location),
        this.router.routerState.snapshot.root.queryParams,
      );
    }
  }

  private getViewName(location: CurrentLocation): string {
    // if the "activeMenuItemId" is set we now in which sub menu we are
    if (location.activeMenuItemId) {
      return `${location.id} > ${location.activeMenuItemId}`;
    }

    // if we are in assets we need to check if we are on an asset details and retrieve the type
    if (location.id === ASSETS_NAV_ITEM_ID) {
      const urlSegment = this.getNextUrlSegment(ASSETS_URL_SEGMENT);
      if (isNotNil(urlSegment)) {
        return `${location.id} > ${urlSegment}`;
      }
    }

    // if we are in users we need to check if we are on an user details
    if (location.id === USERS_NAV_ITEM_ID) {
      const urlSegment = this.getNextUrlSegment(USERS_URL_SEGMENT);
      if (isNotNil(urlSegment)) {
        return `${location.id} > ${urlSegment}`;
      }
    }

    return `${location.id}`;
  }

  // Retrive the next url segment after the one passed in parameter
  private getNextUrlSegment(parent: string): string | Nil {
    const urlParts = this.router.url.split('/');
    const index = findIndex(urlParts, (part) => {
      return part === parent;
    });
    if (index >= 0) {
      return urlParts[index + 1];
    }
    return undefined;
  }
}
