import { Id, WithId, WithName } from '@model';

import { AssetStatus } from './enums/asset-status';

export interface ListAsset extends WithId, WithName {
  categoryId: Id;
  value: number | string;
  unit: string;
  status: AssetStatus;
  isControllable: boolean;
}
