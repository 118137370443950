import { Nil } from '@model';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isNotNil } from '../is-not-nil';

function valueIsNotNil<T>(value: T | Nil): value is T {
  return isNotNil(value);
}

export function filterNil<T>() {
  return (source: Observable<T | Nil>) => {
    return source.pipe(filter(valueIsNotNil));
  };
}
