export enum ConnectorType {
  Chademo = 'ChaDemo',
  ChaoJi = 'ChaoJi',
  DomesticA = 'DomesticA',
  DomesticB = 'DomesticB',
  DomesticC = 'DomesticC',
  DomesticD = 'DomesticD',
  DomesticE = 'DomesticE',
  DomesticF = 'DomesticF',
  DomesticG = 'DomesticG',
  DomesticH = 'DomesticH',
  DomesticI = 'DomesticI',
  DomesticJ = 'DomesticJ',
  DomesticK = 'DomesticK',
  DomesticL = 'DomesticL',
  DomesticM = 'DomesticM',
  DomesticN = 'DomesticN',
  DomesticO = 'DomesticO',
  GbtAc = 'GbtAc',
  GbtDc = 'GbtDc',
  IEC603092single16 = 'Iec603092Single16',
  IEC603092three16 = 'Iec603092Three16',
  IEC603092three32 = 'Iec603092Three32',
  IEC603092three64 = 'Iec603092Three64',
  IEC62196T1 = 'Iec62196T1',
  IEC62196T1COMBO = 'Iec62196T1Combo',
  IEC62196T2 = 'Iec62196T2',
  IEC62196T2COMBO = 'Iec62196T2Combo',
  IEC62196T3A = 'Iec62196T3A',
  IEC62196T3C = 'Iec62196T3C',
  Nema520 = 'Nema520',
  Nema630 = 'Nema630',
  Nema650 = 'Nema650',
  Nema1030 = 'Nema1030',
  Nema1050 = 'Nema1050',
  Nema1430 = 'Nema1430',
  Nema1450 = 'Nema1450',
  PantographBottomUp = 'PantographBottomUp',
  PantographTopDown = 'PantographTopDown',
  TeslaR = 'TeslaR',
  TeslaS = 'TeslaS',
  Unknown = 'Unknown',
}
