export enum FileExtension {
  Png = '.png',
  Jpg = '.jpg',
  Jpeg = '.jpeg',
  Csv = '.csv',
  Bin = '.bin',
  Fup = '.fup',
}

export const PNG = [FileExtension.Png];
export const JPG = [FileExtension.Jpeg, FileExtension.Jpg];
export const CSV = [FileExtension.Csv];
export const BIN = [FileExtension.Bin];
export const FUP = [FileExtension.Fup];
