import { Injectable } from '@angular/core';
import { NotificationCount } from '@model/bems-cloud/bems/notifications';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '../store.state';
import { fetchNotificationCount } from './notifications.actions';
import { getNotificationsState } from './notifications.selectors';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public constructor(private store: Store<State>) {}

  public notificationCount$: Observable<NotificationCount> = this.store.select(
    getNotificationsState,
  );

  public fetchNotificationCount(): void {
    this.store.dispatch(fetchNotificationCount());
  }
}
