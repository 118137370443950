import { Nil } from '@model';
import {
  ChartDataField,
  ChartDataFieldUnit,
} from '@model/bems-cloud/bems/charts';
import { ChartColor, ChartData, XaxisUnit } from '@ui/chart';

import {
  EMPTY_DATA_SET_GENERATORS,
  convertDataToTimezone,
  getWattValueTransformer,
  mergeChartData,
} from './charts.utils';

export function getBarChartData(
  chartDataField: ChartDataField | Nil,
  date: Date,
  color: ChartColor,
  timezone: string,
  negative: boolean,
  xAxisUnit: XaxisUnit,
  yAxisUnit: ChartDataFieldUnit,
): ChartData[] {
  const measured = mergeChartData(
    EMPTY_DATA_SET_GENERATORS[xAxisUnit](color, date, timezone),
    convertDataToTimezone(chartDataField?.measured, timezone),
    getWattValueTransformer(negative, yAxisUnit),
  );

  return mergeChartData(
    measured,
    convertDataToTimezone(chartDataField?.forecast, timezone),
    getWattValueTransformer(negative, yAxisUnit),
    true,
  );
}
