import { Nil } from '@model';
import { Month } from '../internals';

export interface AdvancedPeakShavingConfiguration {
  peakShavingEnabled: boolean;
  peakShavingStatus: PeakShavingStatus;
  billingPeriod: BillingPeriod | Nil;
  monthlyConfiguration?: MonthlyConfiguration[] | Nil;
  yearlyConfiguration?: YearlyConfiguration[] | Nil;
}

export interface MonthlyConfiguration {
  year: number;
  month: Month;
  limit: number;
  read?: number;
  unit: string;
  closed: boolean;
}

export interface YearlyConfiguration {
  year: number;
  limit: number;
  read?: number;
  unit: string;
  closed: boolean;
}

export enum PeakShavingStatus {
  Active = 'active',
  Pending = 'pending',
}

export enum BillingPeriod {
  Yearly = 'yearly',
  Monthly = 'monthly',
}
