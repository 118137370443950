import { WithId, WithName } from '@model';

export interface PostalCode extends WithId, WithName {
  code: string;
  extendedName: string;
  timezone: Timezone;
  countryId: string;
  coordinates: Coordinates;
}

export interface Timezone extends WithId, WithName {
  timezone: string;
  gmtOffset: number;
  dstOffset: number;
  rawOffset: number;
}

export interface Coordinates {
  lat: number;
  lng: number;
}
