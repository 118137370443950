export enum BemsAnimation {
  Expired = 'expired',
  Forbidden = 'forbidden',
  InternalServerError = 'internalServerError',
  NoDevice = 'noDevice',
  NotFound = 'notFound',
  ServiceUnavailable = 'serviceUnavailable',
}

export const ANIMATION_TO_PATH: Record<BemsAnimation, string> = {
  [BemsAnimation.Expired]: 'assets/animations/expired.json',
  [BemsAnimation.Forbidden]: 'assets/animations/error_403.json',
  [BemsAnimation.InternalServerError]: 'assets/animations/error_500.json',
  [BemsAnimation.NoDevice]: 'assets/animations/no_device.json',
  [BemsAnimation.NotFound]: 'assets/animations/error_404.json',
  [BemsAnimation.ServiceUnavailable]: 'assets/animations/error_503.json',
};
