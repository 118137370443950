export enum EVChargerStatus {
  Available = 1,
  Preparing = 2,
  Charging = 3,
  Suspendedevse = 4,
  Initialising = 5,
  Finishing = 6,
  Reserved = 7,
  Unavailable = 8,
  Fault = 9,
}
