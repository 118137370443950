import { ApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { actionNameFactory } from '@core/ngrx';
import { Id } from '@model';
import { createAction, props } from '@ngrx/store';

import { CurrentDeviceState } from './current-device.state';

const createActionName = actionNameFactory('Device');

export const fetchCurrentDevice = createAction(
  createActionName('fetch current device'),
  props<{ id?: Id }>(),
);

export const fetchCurrentDeviceError = createAction(
  createActionName('fetch current device error'),
  props<ApiErrorResponse>(),
);

export const noDeviceError = createAction(createActionName('no device error'));

export const fetchCurrentDeviceSuccess = createAction(
  createActionName('fetch current device success'),
  props<Pick<CurrentDeviceState, 'device' | 'devices'>>(),
);

export const fetchCurrentDeviceWeather = createAction(
  createActionName('fetch current device weather'),
);

export const fetchCurrentDeviceWeatherError = createAction(
  createActionName('fetch current device weather error'),
  props<ApiErrorResponse>(),
);

export const fetchCurrentDeviceWeatherSuccess = createAction(
  createActionName('fetch current device weather success'),
  props<Pick<CurrentDeviceState, 'weather'>>(),
);

export const fetchCurrentDeviceSettings = createAction(
  createActionName('fetch current device settings'),
  props<{ id: Id }>(),
);

export const fetchCurrentDeviceSettingsError = createAction(
  createActionName('fetch current device settings error'),
  props<ApiErrorResponse>(),
);

export const fetchCurrentDeviceSettingsSuccess = createAction(
  createActionName('fetch current device settings success'),
  props<Pick<CurrentDeviceState, 'settings'>>(),
);
