import {
  APP_BASE_HREF,
  PathLocationStrategy,
  PlatformLocation,
} from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { LOGIN_URL_SEGMENT } from './const';
@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  public constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  public override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);

    // we want to preserve query params (redirectUrl) for login related pages only
    if (!internal.startsWith(`/${LOGIN_URL_SEGMENT}`)) {
      return path;
    }

    const existingURLSearchParams = new URLSearchParams(
      this.platformLocation?.search ?? '',
    );
    const existingQueryParams = Object.fromEntries(
      existingURLSearchParams.entries(),
    );
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
    return urlTree.toString();
  }
}
