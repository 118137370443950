import { TrendType } from '@model/bems-cloud/bems/dashboard';
import { z } from 'zod';

export const ValueTrendDataJsonSchema = z.object({
  value: z.string(),
  unit: z.string(),
  trend: z.nativeEnum(TrendType),
});

export type ValueTrendDataJson = z.infer<typeof ValueTrendDataJsonSchema>;
