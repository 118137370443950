export type IconName =
  | 'access_time'
  | 'account_circle'
  | 'account_tree'
  | 'add'
  | 'add_circle'
  | 'add_circle_outline'
  | 'adf_scanner'
  | 'archive'
  | 'arrow_back'
  | 'arrow_downward'
  | 'arrow_drop_down'
  | 'arrow_drop_up'
  | 'arrow_forward'
  | 'arrow_upward'
  | 'attach_money'
  | 'av_timer'
  | 'bar_chart'
  | 'battery_0_bar'
  | 'battery_1_bar'
  | 'battery_2_bar'
  | 'battery_3_bar'
  | 'battery_4_bar'
  | 'battery_5_bar'
  | 'battery_6_bar'
  | 'battery_full'
  | 'blui-apple_pay'
  | 'blui-battery'
  | 'blui-building'
  | 'blui-car'
  | 'blui-cloud_off_filled'
  | 'blui-device_info'
  | 'blui-eaton'
  | 'blui-ev_plug_ccs_combo'
  | 'blui-ev_plug_chademo'
  | 'blui-ev_plug_domestic_a'
  | 'blui-ev_plug_domestic_b'
  | 'blui-ev_plug_domestic_c'
  | 'blui-ev_plug_domestic_d'
  | 'blui-ev_plug_domestic_e'
  | 'blui-ev_plug_domestic_f'
  | 'blui-ev_plug_domestic_g'
  | 'blui-ev_plug_domestic_h'
  | 'blui-ev_plug_domestic_i'
  | 'blui-ev_plug_domestic_j'
  | 'blui-ev_plug_domestic_k'
  | 'blui-ev_plug_domestic_l'
  | 'blui-ev_plug_iec_60309_2_single_16'
  | 'blui-ev_plug_iec_60309_2_three_16'
  | 'blui-ev_plug_iec_60309_2_three_32'
  | 'blui-ev_plug_iec_60309_2_three_64'
  | 'blui-ev_plug_iec_61851_gbt'
  | 'blui-ev_plug_iec_62196_t1'
  | 'blui-ev_plug_iec_62196_t1_combo'
  | 'blui-ev_plug_iec_62196_t2'
  | 'blui-ev_plug_iec_62196_t2_combo'
  | 'blui-ev_plug_iec_62196_t3a'
  | 'blui-ev_plug_iec_62196_t3c'
  | 'blui-ev_plug_tesla_r'
  | 'blui-ev_plug_tesla_s'
  | 'blui-ev_plug_type_one'
  | 'blui-ev_plug_type_two'
  | 'blui-maintenance'
  | 'blui-network_switch'
  | 'blui-outline_offline_bolt'
  | 'blui-pantograph_bottom_up'
  | 'blui-pantograph_top_down'
  | 'blui-payment_amex'
  | 'blui-payment_isracard'
  | 'blui-payment_maestro'
  | 'blui-payment_mastercard'
  | 'blui-payment_postfinance'
  | 'blui-payment_twint'
  | 'blui-paypal'
  | 'blui-servers'
  | 'blui-stopping'
  | 'blui-visa'
  | 'blui-waveform'
  | 'bolt'
  | 'build'
  | 'cable'
  | 'calendar_today'
  | 'call'
  | 'cancel'
  | 'card_giftcard'
  | 'card_membership'
  | 'check'
  | 'check_box'
  | 'check_box_outline_blank'
  | 'check_circle'
  | 'chevron_left'
  | 'chevron_right'
  | 'clear'
  | 'close'
  | 'cloud'
  | 'cloud_off'
  | 'comment'
  | 'compare_arrows'
  | 'contact_mail'
  | 'content_copy'
  | 'create'
  | 'credit_card'
  | 'date_range'
  | 'delete'
  | 'description'
  | 'disabled_by_default'
  | 'done'
  | 'error'
  | 'electrical_services'
  | 'euro'
  | 'ev_station'
  | 'event_available'
  | 'event_busy'
  | 'event_repeat'
  | 'exit_to_app'
  | 'expand_less'
  | 'expand_more'
  | 'fiber_manual_record'
  | 'fiber_new'
  | 'file_copy'
  | 'file_download'
  | 'filter_list'
  | 'flag'
  | 'fullscreen'
  | 'get_app'
  | 'etn-intercharge'
  | 'group'
  | 'help'
  | 'home'
  | 'hourglass_bottom'
  | 'hourglass_empty'
  | 'hourglass_top'
  | 'image'
  | 'indeterminate_check_box'
  | 'info'
  | 'insert_drive_file'
  | 'ios_share'
  | 'keyboard_arrow_left'
  | 'language'
  | 'launch'
  | 'layers'
  | 'light_mode'
  | 'link'
  | 'list'
  | 'load_factor'
  | 'local_parking'
  | 'location_city'
  | 'location_off'
  | 'location_on'
  | 'lock'
  | 'lock_open'
  | 'loyalty'
  | 'mail'
  | 'map'
  | 'menu'
  | 'meter_face'
  | 'mood'
  | 'more_time'
  | 'more_vert'
  | 'my_location'
  | 'near_me'
  | 'notifications'
  | 'notifications_active'
  | 'notifications_off'
  | 'offline_bolt'
  | 'paid'
  | 'palette'
  | 'password'
  | 'pause'
  | 'pause_circle'
  | 'pause_circle_filled'
  | 'payment'
  | 'people'
  | 'person'
  | 'pin_drop'
  | 'place'
  | 'play_circle_outline'
  | 'power'
  | 'power_settings_new'
  | 'psychology'
  | 'public'
  | 'query_builder'
  | 'query_stats'
  | 'question_mark'
  | 'radio_button_checked'
  | 'radio_button_unchecked'
  | 'receipt'
  | 'redo'
  | 'remove_circle_outline'
  | 'replay'
  | 'restart_alt'
  | 'save'
  | 'save_as'
  | 'schedule'
  | 'science'
  | 'search'
  | 'security'
  | 'send'
  | 'sentiment_satisfied_alt'
  | 'settings'
  | 'settings_backup_restore'
  | 'shield'
  | 'short_text'
  | 'smartphone'
  | 'sms'
  | 'sort'
  | 'star'
  | 'star_rate'
  | 'stop'
  | 'subdirectory_arrow_right'
  | 'summarize'
  | 'supervised_user_circle'
  | 'swap_horiz'
  | 'swap_horizontal_circle'
  | 'tag'
  | 'text_format'
  | 'text_snippet'
  | 'texture'
  | 'timelapse'
  | 'timer'
  | 'toggle_off'
  | 'toggle_on'
  | 'trending_down'
  | 'trending_flat'
  | 'trending_up'
  | 'unfold_less'
  | 'unfold_more'
  | 'update'
  | 'upload_file'
  | 'view_column'
  | 'view_week'
  | 'visibility'
  | 'visibility_off'
  | 'vpn_key'
  | 'warning'
  | 'warning_amber'
  | 'watch_later'
  | 'widgets'
  | 'wysiwyg'
  | 'table_rows'
  | 'start'
  | 'numbers';

export enum IconSize {
  Xsmall = 0.5,
  Small = 1,
  Msmall = 1.25,
  Default = 1.5,
  Medium = 2,
  Large = 2.5,
  Xlarge = 3,
  XXlarge = 3.5,
  EmptyStateSmall = 4,
  EmptyState = 6,
}

export interface Icon {
  name: IconName;
  size?: IconSize;
  color?: IconColor;
  tooltip?: string;
}

export enum IconColor {
  Accent = 'accent',
  Primary = 'primary',
  PrimaryContrast = 'primary-contrast',
  Grey = 'grey',
  Disabled = 'disabled',
  Warn = 'warn',
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  LightBlue = 'light-blue',
  Orange = 'orange',
}
