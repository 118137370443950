import { Nil } from '@model';
import { ChartDataset, ChartData as ChartJsData, ChartType } from 'chart.js';

export enum ChartColor {
  Green = 'green',
  LightBlue = 'light-blue',
  Orange = 'orange',
  Red = 'red',
  Yellow = 'yellow',
  Blue = 'blue',
  Purple = 'purple',
  Wine = 'Wine',
}

export interface ChartData {
  label: string | number;
  value: number;
  color: ChartColor;
  predictive?: boolean;
  isNil?: boolean;
}

export interface ChartDataSet {
  label: string;
  data: ChartData[];
}

export enum ChartSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Responsive = 'responsive',
}

export interface ChartLineAnnotation {
  label: string;
  value: number;
}

export interface ChartBoxAnnotation {
  label?: string;
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
  color: ChartColor;
}

export interface BarChartConfig {
  data: ChartJsData<'bar'>;
  horizontalLineAnnotation: ChartLineAnnotation | Nil;
  interactiveLegend: boolean | Nil;
  legendContainerId: string;
  size: ChartSize;
  stacked: boolean | Nil;
  unit: string | Nil;
  verticalLineAnnotation: ChartLineAnnotation | Nil;
  xAxisUnit: XaxisUnit | Nil;
}

export interface LineChartConfig {
  data: ChartJsData<'line'>;
  horizontalLineAnnotations: ChartLineAnnotation[] | Nil;
  interactiveLegend: boolean | Nil;
  legendContainerId: string;
  size: ChartSize;
  yAxisUnit: string | Nil;
  verticalLineAnnotations: ChartLineAnnotation[] | Nil;
  boxAnnotations: ChartBoxAnnotation[] | Nil;
  xAxisUnit: XaxisUnit | Nil;
}

export enum XaxisUnit {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

export type NullableChartDataset<TType extends ChartType = ChartType> =
  ChartDataset<TType> & {
    nullableData: (boolean | Nil)[];
  };
