import { Nil } from '@model';
import { createReducer, on } from '@ngrx/store';

import {
  loginSuccess,
  logoutSuccess,
  refreshTokenSuccess,
} from './auth.actions';
import { AuthState } from './auth.state';

export const AUTH_STATE_NAME = 'auth';

const INITIAL_STATE: AuthState | Nil = {};

export const authReducer = createReducer<AuthState>(
  INITIAL_STATE,
  on(loginSuccess, (_state, { authState: { exp, csrfToken } }) => {
    return { exp, csrfToken };
  }),
  on(refreshTokenSuccess, (_state, { exp, csrfToken }) => {
    return { exp, csrfToken };
  }),
  on(logoutSuccess, (_state) => {
    return {};
  }),
);
