export enum ProgressSpinnerSize {
  Medium = 'medium',
  Small = 'small',
}

export enum ProgressSpinnerPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
