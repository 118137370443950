import { Direction, Directionality } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { isNotNil } from '@core/is-not-nil';
import { Image } from '@design-system';
import { Nil } from '@model';
import { IconComponent } from '@ui/icon';
import { ImageModule } from '@ui/image';
import { isEmpty } from 'lodash-es';

import { Action, ActionComponent } from '../action';
import {
  ProgressSpinnerComponent,
  ProgressSpinnerSize,
} from '../progress-spinner';

@Component({
  selector: 'etn-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionComponent,
    CommonModule,
    IconComponent,
    ImageModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    ProgressSpinnerComponent,
  ],
})
export class CardComponent {
  public constructor(private dir: Directionality) {}

  @Input() public altActions: Action[] = [];
  @Input() public context: any = {};
  @Input() public showActionsDivider: boolean | Nil;
  @Input() public showTitleDivider = true;
  @Input() public headerActions: Action[] = [];
  @Input() public image: Image | Nil;
  @Input() public imageHeight: number | Nil;
  @Input() public imageSrc: string | Nil;
  @Input() public loading = false;
  @Input() public mainActions: Action[] = [];
  @Input() public subTitle: string | Nil;
  @Input() public title: string | Nil;

  @HostBinding('class.centered-header')
  @Input()
  public centeredHeader: boolean | Nil;

  @HostBinding('class.hide-shadow')
  @Input()
  public hideShadow: boolean | Nil;

  @Output() public actionClick = new EventEmitter<Action>();

  @HostBinding('class')
  public direction: Direction = this.dir.value;

  @HostBinding('class.no-actions')
  public get hasNoActions(): boolean {
    return isEmpty(this.mainActions) && isEmpty(this.altActions);
  }

  public spinnerSize = ProgressSpinnerSize.Small;

  public onActionClick(action: Action, loading: boolean): void {
    if (loading) {
      return;
    }
    this.actionClick.emit(action);
    if (action.callback) {
      action.callback(this.context);
    }
  }

  public get height(): number | Nil {
    return isNotNil(this.image) ? this.imageHeight || 128 : undefined;
  }
}
