export const ACCOUNT_URL_SEGMENT = 'account';
export const ASSETS_URL_SEGMENT = 'assets';
export const DEVICES_URL_SEGMENT = 'devices';
export const CHANGE_PASSWORD_URL_SEGMENT = 'change-password';
export const CHARTS_URL_SEGMENT = 'charts';
export const DASHBOARD_URL_SEGMENT = 'dashboard';
export const FORBIDDEN_URL_SEGMENT = 'forbidden';
export const EV_CHARGER_ID_URL_SEGMENT = 'chargerId';
export const EV_CHARGER_URL_SEGMENT = 'ev-charger';
export const GENERAL_URL_SEGMENT = 'general';
export const GRID_URL_SEGMENT = 'grid';
export const INVITATION_EXPIRED_URL_SEGMENT = 'invitation-expired';
export const INTERNAL_SERVER_ERROR_URL_SEGMENT = 'internal-server-error';
export const LICENSE_URL_SEGMENT = 'license';
export const LOGIN_URL_SEGMENT = 'login';
export const NOT_FOUND_URL_SEGMENT = 'not-found';
export const NO_DEVICE_URL_SEGMENT = 'no-device';
export const PERSONAL_INFORMATION_URL_SEGMENT = 'personal-information';
export const DOWNLOADS_URL_SEGMENT = 'downloads';
export const PREFERENCES_URL_SEGMENT = 'preferences';
export const SCHEDULER_URL_SEGMENT = 'scheduler';
export const SERVICE_UNAVAILABLE_URL_SEGMENT = 'service-unavailable';
export const SESSION_EXPIRED_URL_SEGMENT = 'session-expired';
export const SETTINGS_URL_SEGMENT = 'settings';
export const SUPPORT_URL_SEGMENT = 'support';
export const SYSTEM_GOALS_URL_SEGMENT = 'system-goals';
export const USERS_URL_SEGMENT = 'users';
export const SOLAR_PANEL_URL_SEGMENT = 'solar-panel';
export const STORAGE_UNIT_URL_SEGMENT = 'storage-unit';
export const STORAGE_UNIT_ID_PARAM = 'storageUnitId';
export const POWER_METER_URL_SEGMENT = 'power-meter';
export const PERMISSIONS_URL_SEGMENT = 'permissions';
export const INVITATIONS_URL_SEGMENT = 'invitations';
export const DELETE_URL_SEGMENT = 'delete';
export const ACCEPT_URL_SEGMENT = 'accept';
export const LOBBY_VIEW_URL_SEGMENT = 'lobby-view';

export const SOLAR_PANEL_ID_PARAM = 'solarPanelId';

export const ACCOUNT_CHANGE_PASSWORD_NAV_ITEM_ID = 'account-change-password';
export const ACCOUNT_NAV_ITEM_ID = 'account';
export const ACCOUNT_PERSONAL_INFORMATION_NAV_ITEM_ID =
  'account-personal-information';
export const DOWNLOAD_ACCOUNT_INFORMATION_NAV_ITEM_ID =
  'download-account-information';
export const ACCOUNT_PREFERENCES_NAV_ITEM_ID = 'preferences';
export const DELETE_ACCOUNT_NAV_ITEM_ID = 'delete-account';
export const ASSETS_NAV_ITEM_ID = 'assets';
export const CHARTS_NAV_ITEM_ID = 'charts';
export const DASHBOARD_NAV_ITEM_ID = 'dashboard';
export const SCHEDULER_NAV_ITEM_ID = 'scheduler';
export const SETTINGS_GENERAL_NAV_ITEM_ID = 'general';
export const SETTINGS_GRID_NAV_ITEM_ID = 'grid';
export const SETTINGS_LICENSE_NAV_ITEM_ID = 'license';
export const SETTINGS_NAV_ITEM_ID = 'settings';
export const SETTINGS_SUPPORT_NAV_ITEM_ID = 'support';
export const SETTINGS_SYSTEM_GOALS_NAV_ITEM_ID = 'system-goals';
export const USERS_NAV_ITEM_ID = 'users';
export const POWER_METER_ID_PARAM = 'powerMeterId';
