import { get, has, intersection, isNil, keys } from 'lodash-es';

import { Nil } from './nil';

export const SELF_RELATION = 'self';
export const SELF_EDIT_RELATION = 'self:edit';
export const SELF_DELETE_RELATION = 'self:delete';
export const COLLECTION_RELATION = 'collection';
export const COLLECTION_PAGE_RELATION = 'collection:page';
export const COLLECTION_SORT_RELATION = 'collection:sort';
export const COLLECTION_FILTER_RELATION = 'collection:filter';

export interface WithLinks {
  _links?: Links | undefined;
}

export type Links = Record<string, Link>;

export interface Link {
  href: string;
  method: LinkMethod;
  templated?: boolean;
}

export enum LinkMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
}

export type LinkParameters = Record<string, string | string[]>;

export function hasRelation(withLinks: WithLinks, relation: string): boolean {
  return getRelation(withLinks, relation) !== undefined;
}

export function hasSelfRelation(withLinks: WithLinks): boolean {
  return hasRelation(withLinks, SELF_RELATION);
}

export function hasEditRelation(withLinks: WithLinks): boolean {
  return hasRelation(withLinks, SELF_EDIT_RELATION);
}

export function hasDeleteRelation(withLinks: WithLinks): boolean {
  return hasRelation(withLinks, SELF_DELETE_RELATION);
}

export function getRelation(
  withLinks: WithLinks,
  relation: string,
): Link | Nil {
  const links = withLinks._links;
  if (isNil(links)) {
    return undefined;
  }
  return getLink(links, relation);
}

export function getSelfLink(withLinks: WithLinks): Link | Nil {
  return getRelation(withLinks, SELF_RELATION);
}

export function getMandatorySelfLink(withLinks: WithLinks | Links): Link {
  if (isWithLinks(withLinks)) {
    return getMandatoryRelation(withLinks, SELF_RELATION);
  } else {
    return getMandatoryLink(withLinks, SELF_RELATION);
  }
}

export function getEditLink(withLinks: WithLinks): Link | Nil {
  return getRelation(withLinks, SELF_EDIT_RELATION);
}

export function getMandatoryEditLink(withLinks: WithLinks): Link {
  return getMandatoryRelation(withLinks, SELF_EDIT_RELATION);
}

export function getMandatoryDeleteLink(withLinks: WithLinks): Link {
  return getMandatoryRelation(withLinks, SELF_DELETE_RELATION);
}

export function getMandatoryRelation(
  withLinks: WithLinks,
  relation: string,
): Link {
  const links = withLinks._links;
  if (isNil(links)) {
    throw new MissingLinkError(relation, withLinks);
  }
  const link = getLink(links, relation);

  if (isNil(link)) {
    throw new MissingLinkError(relation, withLinks);
  }

  return link;
}

export function isLink(value: any): value is Link {
  const valueProperties: string[] = keys(value);
  const linkProperties: (keyof Link)[] = ['href', 'method'];
  return (
    intersection(valueProperties, linkProperties).length ===
    linkProperties.length
  );
}

export function isWithLinks(value: any): value is WithLinks {
  return has(value, '_links');
}

export function getLinks(value: WithLinks): Links {
  return get(value, ['_links'], {});
}

export function getLink(links: Links | Nil, link: string): Link | Nil {
  if (isNil(links)) {
    return undefined;
  }
  return get(links, [link], undefined);
}

export function getMandatoryLink(links: Links | Nil, relation: string): Link {
  if (isNil(links)) {
    throw new MissingLinkError(relation);
  }
  const link = get(links, [relation], undefined);

  if (isNil(link)) {
    throw new MissingLinkError(relation);
  }

  return link;
}

export function getCollectionLink(links: Links | Nil): Link | Nil {
  return getLink(links, COLLECTION_RELATION);
}

export function getCollectionPageLink(withLinks: WithLinks): Link | Nil {
  return getRelation(withLinks, COLLECTION_PAGE_RELATION);
}

export function getCollectionSortLink(withLinks: WithLinks): Link | Nil {
  return getRelation(withLinks, COLLECTION_SORT_RELATION);
}

export function getCollectionFilterLink(withLinks: WithLinks): Link | Nil {
  return getRelation(withLinks, COLLECTION_FILTER_RELATION);
}

export function isReadOnly(withLinks: WithLinks | Nil): boolean {
  if (isNil(withLinks)) {
    return false;
  }

  return !hasRelation(withLinks, SELF_EDIT_RELATION);
}

export function makeLinkParameters(
  link: Link,
  params: LinkParameters,
): LinkParameters | undefined {
  if (!link.templated) {
    return undefined;
  }
  const regex = /\{(.*?)\}/gm;

  let m;
  const parameters: LinkParameters = {};

  while ((m = regex.exec(link.href)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    m.forEach((match, groupIndex) => {
      if (groupIndex === 1) {
        parameters[match] = '';
      }
    });
  }

  keys(params).forEach((key) => {
    if (!isNil(parameters[key])) {
      parameters[key] = params[key];
    }
  });

  return parameters;
}

export function makeLink(
  href: string,
  method: LinkMethod = LinkMethod.GET,
  templated?: boolean,
): Link {
  return {
    href,
    method,
    templated,
  };
}

export function makeLinks(): Links {
  return {};
}

export class MissingLinkError implements Error {
  public constructor(relation: string, parent?: WithLinks) {
    this.name = 'Missing HATEOAS link';
    this.message = relation;
    this.parent = parent;
  }

  public name: string;
  public message: string;
  public parent: WithLinks | Nil;
}
