import { IconName } from '../../../ui/src/lib/icon';

export enum PlugType {
  AvconConnector = 'AvconConnector',
  CcsCombo1PlugCableAttached = 'CcsCombo1PlugCableAttached',
  CcsCombo2PlugCableAttached = 'CcsCombo2PlugCableAttached',
  ChAdeMo = 'ChAdeMo',
  Iec60309SinglePhase = 'Iec60309SinglePhase',
  Iec60309ThreePhase = 'Iec60309ThreePhase',
  LargePaddleInductive = 'LargePaddleInductive',
  Nema520 = 'Nema520',
  SmallPaddleInductive = 'SmallPaddleInductive',
  TeslaConnector = 'TeslaConnector',
  Type1ConnectorCableAttached = 'Type1ConnectorCableAttached',
  Type2ConnectorCableAttached = 'Type2ConnectorCableAttached',
  Type2Outlet = 'Type2Outlet',
  Type3Outlet = 'Type3Outlet',
  TypeEFrenchStandard = 'TypeEFrenchStandard',
  TypeFSchuko = 'TypeFSchuko',
  TypeGBritishStandard = 'TypeGBritishStandard',
  TypeJSwissStandard = 'TypeJSwissStandard',
  Unspecified = 'Unspecified',
}

export const PLUG_TYPE_ICON: Record<PlugType, IconName> = {
  AvconConnector: 'power',
  CcsCombo1PlugCableAttached: 'blui-ev_plug_ccs_combo',
  CcsCombo2PlugCableAttached: 'blui-ev_plug_ccs_combo',
  ChAdeMo: 'blui-ev_plug_chademo',
  Iec60309SinglePhase: 'power',
  Iec60309ThreePhase: 'power',
  LargePaddleInductive: 'power',
  Nema520: 'power',
  SmallPaddleInductive: 'power',
  TeslaConnector: 'power',
  Type1ConnectorCableAttached: 'blui-ev_plug_type_one',
  Type2ConnectorCableAttached: 'blui-ev_plug_type_two',
  Type2Outlet: 'blui-ev_plug_type_two',
  Type3Outlet: 'power',
  TypeEFrenchStandard: 'power',
  TypeFSchuko: 'power',
  TypeGBritishStandard: 'power',
  TypeJSwissStandard: 'power',
  Unspecified: 'power',
};
