import { Injectable } from '@angular/core';
import { Id, Nil } from '@model';
import { every, isNil } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { CookieConsent } from './cookie-consent.types';

@Injectable()
export class CookieConsentService {
  public constructor(private cookieService: CookieService) {}

  public saveSubject$ = new BehaviorSubject<void>(undefined);

  public saveConsent(consents: CookieConsent[], userId: Id | Nil): void {
    consents.forEach((consent) => {
      this.cookieService.set(
        this.getCookieName(consent, userId),
        `${consent.accepted}`,
        365,
        '/',
      );
    });
    this.saveSubject$.next(undefined);
  }

  public hasConsent(consents: CookieConsent[], userId: Id | Nil): boolean {
    return every(consents, (consent) => {
      return (
        this.cookieService.get(this.getCookieName(consent, userId)).length > 0
      );
    });
  }

  public hasConsentObservable(
    consents: CookieConsent[],
    userId: Id | Nil,
  ): Observable<boolean> {
    return this.saveSubject$.pipe(
      map(() => {
        return this.hasConsent(consents, userId);
      }),
    );
  }

  public getCookieValue(
    consent: CookieConsent,
    userId: Id | Nil,
  ): boolean | undefined {
    const value = this.cookieService.get(this.getCookieName(consent, userId));
    if (value.length === 0) {
      return undefined;
    }
    return value === 'true';
  }

  public getCookieValueObservable(
    consent: CookieConsent,
    userId: Id | Nil,
  ): Observable<boolean | undefined> {
    return this.saveSubject$.pipe(
      map(() => {
        return this.getCookieValue(consent, userId);
      }),
    );
  }

  private getCookieName(consent: CookieConsent, userId: Id | Nil): string {
    if (isNil(userId)) {
      return `etn-cookie-consent-${consent.id}`;
    }

    return `etn-cookie-consent-${userId}-${consent.id}`;
  }
}
