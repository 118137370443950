import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Nil } from '@model';
import { ChangePassword, User } from '@model/bems-cloud/bems/users';
import { AuditLogFileName } from '@model/bems-cloud/bems/users/audit-log-file-name';
import { UserFileName } from '@model/bems-cloud/bems/users/user-file-name';
import { Observable } from 'rxjs';

import { BemsApiService } from '../bems-api.service';
import { ApiErrorResponse } from '../bems-api.types';
import {
  AuditLogFileNameJsonSchema,
  AuditLogFilenameJson,
} from './io/audit-log-file-name.io';
import { ChangePasswordJson } from './io/change-password.io';
import {
  UserFileNameJsonSchema,
  UserFilenameJson,
} from './io/user-file-name.io';
import { UserProfileJson, UserProfileJsonSchema } from './io/user-profile.io';
import { UserJson, UserJsonSchema, deserializeUser } from './io/user.io';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends BemsApiService {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getUserProfile(): Observable<User | ApiErrorResponse> {
    return this.get<UserProfileJson, User>({
      endpoint: 'account/users/me',
      schema: UserProfileJsonSchema,
      deserializer: (response: UserProfileJson) => {
        return deserializeUser(response.user);
      },
    });
  }

  public getUserFileName(): Observable<UserFileName | ApiErrorResponse> {
    return this.get<UserFilenameJson, UserFileName>({
      endpoint: 'account/export/users/me',
      schema: UserFileNameJsonSchema,
      deserializer: (response: UserFilenameJson) => {
        return response;
      },
    });
  }
  public getAuditLogsFileName(): Observable<
    AuditLogFileName | ApiErrorResponse
  > {
    return this.get<AuditLogFilenameJson, AuditLogFileName>({
      endpoint: 'device/logs',
      schema: AuditLogFileNameJsonSchema,
      deserializer: (response: AuditLogFilenameJson) => {
        return response;
      },
    });
  }

  public getFile(filename: string): Observable<string | ApiErrorResponse> {
    return this.getText({
      endpoint: `account/files/${filename}`,
    });
  }

  public saveUserProfile(
    user: Partial<User>,
  ): Observable<User | ApiErrorResponse> {
    return this.patch<Partial<User>, Partial<UserJson>, User, UserJson>({
      endpoint: 'account/users/me',
      payload: user,
      responseSchema: UserJsonSchema,
      payloadSerializer: (payload: Partial<User>) => {
        return payload;
      },
      responseDeserializer: (response: UserJson) => {
        return deserializeUser(response);
      },
    });
  }

  public changePassword(
    payload: ChangePassword,
  ): Observable<undefined | ApiErrorResponse> {
    return this.postWithoutResponse<ChangePassword, ChangePasswordJson>({
      endpoint: 'account/users/me/password',
      payload,
      payloadSerializer: ({ oldPassword, newPassword }: ChangePassword) => {
        return {
          currentPassword: oldPassword,
          newPassword,
        };
      },
    });
  }

  public deleteAccount(): Observable<ApiErrorResponse | Nil> {
    return this.deleteWithoutResponse({
      endpoint: 'account/users/me',
    });
  }
}
