import { Injectable } from '@angular/core';
import { Nil } from '@model';
import { every, isNil } from 'lodash-es';

@Injectable()
export class SaveTrapService {
  public constructor() {
    console.log('save trap servce instance created');
  }

  private pristine = true;

  public markAsDirty(): void {
    this.pristine = false;
  }

  public markAsPristine(): void {
    this.pristine = true;
  }

  public isPristine(): boolean {
    return this.pristine;
  }

  public setPristine(...forms: (any | Nil)[]): void {
    this.pristine = every(forms, (form) => {
      return isNil(form) || form.formGroup.pristine;
    });
  }
}
