import { IdJsonSchema } from '@api';
import { PaginatedResultsJsonSchemaFactory } from '@api/bems-cloud/bems/bems-api.types';
import { z } from 'zod';

export const CurrencyJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
  symbol: z.string(),
  code: z.string(),
});

export type CurrencyJson = z.infer<typeof CurrencyJsonSchema>;

const PaginatedCurrencyJsonSchema =
  PaginatedResultsJsonSchemaFactory(CurrencyJsonSchema);

export type PaginatedCurrencyJson = z.infer<typeof PaginatedCurrencyJsonSchema>;
