import { ApiErrorResponse } from '@api/bems-cloud/login-portal/login-portal-api.types';
import { actionNameFactory } from '@core/ngrx';
import { Login } from '@model/bems-cloud/login-portal/auth';
import { createAction, props } from '@ngrx/store';

import { AuthState } from './auth.state';

const createActionName = actionNameFactory('Auth');

export const setAuth = createAction(
  createActionName('set auth'),
  props<AuthState>(),
);

// LOGIN
export const login = createAction(createActionName('login'), props<Login>());
export const loginSuccess = createAction(
  createActionName('login success'),
  props<{
    authState: AuthState;
    redirectUrl?: string;
  }>(),
);
export const loginError = createAction(
  createActionName('login error'),
  props<ApiErrorResponse>(),
);

// REFRESH TOKEN
export const refreshToken = createAction(createActionName('refresh token'));
export const refreshTokenSuccess = createAction(
  createActionName('refresh token success'),
  props<AuthState>(),
);
export const refreshTokenError = createAction(
  createActionName('refresh token error'),
);

// LOGOUT
export const logout = createAction(createActionName('logout'));
export const logoutSuccess = createAction(createActionName('logout success'));
export const logoutError = createAction(createActionName('logout error'));
