import { Nil } from '@model';
import { isNil } from 'lodash-es';

export function isValidEnumValue<T>(e: T, value: any): value is T {
  return Object.values(e as any).includes(value);
}

export function castEnum<T>(e: any, value: any): T | Nil {
  if (isNil(value)) {
    return undefined;
  }

  if (isValidEnumValue(e, value)) {
    return value as T;
  }

  const error = `Invalid enumeration detected: ${JSON.stringify(
    e,
  )} => ${value}`;
  console.error(error);
  throw new Error(error);
}
