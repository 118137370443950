<etn-card>
  <div class="card-content">
    <div class="title">{{ messages?.title }}</div>
    <div>
      <div class="explanation">{{ messages?.explanation }}</div>
      <a [href]="messages?.linkUrl" class="link" target="_blank">{{
        messages?.link
      }}</a>
    </div>
    @if (showSettings) {
      <div class="settings-container">
        <div class="settings-message">{{ messages?.consent }}</div>
        <div class="settings">
          <etn-checkbox [value]="true" [disabled]="true">
            {{ messages?.required }}
          </etn-checkbox>
          @for (consent of currentConsents; track consent.id) {
            <etn-checkbox
              [value]="consent.accepted"
              (valueChange)="onCensentToggle(consent, $event)"
            >
              {{ consent.name }}
            </etn-checkbox>
          }
        </div>
      </div>
    }
    <div class="actions">
      <div class="left">
        <etn-action [action]="settingsAction"></etn-action>
      </div>
      <div class="right">
        <etn-action [action]="acceptCookieAction"></etn-action>
      </div>
    </div>
  </div>
</etn-card>
