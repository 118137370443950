import { createSelector } from '@ngrx/store';

import { State } from '../store.state';

const getLayoutState = (state: State) => {
  return state.layout;
};

export const getCurrentLocation = createSelector(getLayoutState, (layout) => {
  return layout.location;
});

export const getCurrentLocationName = createSelector(
  getLayoutState,
  (layout) => {
    return layout.location?.name;
  },
);

export const getCurrentLocationMenu = createSelector(
  getLayoutState,
  (layout) => {
    return layout.location?.menu;
  },
);

export const getCurrentLocationActiveMenuItemId = createSelector(
  getLayoutState,
  (layout) => {
    return layout.location?.activeMenuItemId;
  },
);

export const getCurrentLocationId = createSelector(getLayoutState, (layout) => {
  return layout.location?.id;
});

export const getLoading = createSelector(getLayoutState, (layout) => {
  return layout.loading;
});

export const getRouteLoading = createSelector(getLayoutState, (layout) => {
  return layout.routeLoading;
});

export const getShowTopBar = createSelector(getLayoutState, (layout) => {
  return layout.showTopBar;
});
