import { ModuleWithProviders, NgModule } from '@angular/core';

import { TrackingService } from './tracking.service';

@NgModule({
  imports: [],
  providers: [TrackingService],
})
export class TrackingModule {
  public static forRoot(
    googleTagManagerId?: string,
  ): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [
        { provide: 'googleTagManagerId', useValue: googleTagManagerId },
      ],
    };
  }
}
