export type Brand =
  | 'neutral'
  | 'greenmotion'
  | 'evpass'
  | 'eaton'
  | 'residenza'
  | 'emoti'
  | 'valt'
  | 'nisko'
  | 'vmotion'
  | 'smotion';
