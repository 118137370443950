import { Action } from '../action';

export enum NotificationPosition {
  TopLeft = 'toast-top-left',
  TopCenter = 'toast-top-center',
  TopRight = 'toast-top-right',
  BottomLeft = 'toast-bottom-left',
  BottomCenter = 'toast-bottom-center',
  BottomRight = 'toast-bottom-right',
}

export interface NotificationConfig {
  position: NotificationPosition;
  action: Action;
  timeOut: number;
}
