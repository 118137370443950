import { Role } from '@model/bems-cloud/bems/roles';
import { createReducer, on } from '@ngrx/store';

import { logoutSuccess } from '../auth';
import {
  fetchCurrentUserError,
  fetchCurrentUserRoleError,
  fetchCurrentUserRoleSuccess,
  fetchCurrentUserSuccess,
} from './current-user.actions';
import { CurrentUserState } from './current-user.state';

export const CURRENT_USER_STATE_NAME = 'currentUser';

const INITIAL_STATE: CurrentUserState = {};

export const currentUserReducer = createReducer<CurrentUserState>(
  INITIAL_STATE,
  on(fetchCurrentUserSuccess, (state, { user }) => {
    return { ...state, user };
  }),
  on(fetchCurrentUserError, (state) => {
    return { ...state, user: undefined };
  }),
  on(fetchCurrentUserRoleSuccess, (state, { role }) => {
    return { ...state, role };
  }),
  on(fetchCurrentUserRoleError, (state) => {
    return { ...state, role: Role.Reader };
  }),
  on(logoutSuccess, (state) => {
    return {
      ...state,
      user: undefined,
      role: undefined,
    };
  }),
);
