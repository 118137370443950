import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filterNil } from '@core/rxjs/filter-nil';
import { CurrentUserService } from '@store/bems-cloud/current-user';
import { map } from 'rxjs';

export const currentUserGuard: CanActivateFn = () => {
  return inject(CurrentUserService).currentUser$.pipe(
    filterNil(),
    map(() => {
      return true;
    }),
  );
};
