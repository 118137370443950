import { Id } from '@model';
import { isNil } from 'lodash';

import { AssetCategoryTypeId } from '../assets';
import { SchedulerEventActionType } from './scheduler-events-list';

export interface SchedulerEvent {
  assetTypeId: Id;
  assetId: Id;
  from: Date;
  until: Date;
  actionId: SchedulerEventActionType;
  stateOfCharge?: number;
  maxPowerRate?: number;
}

export function makeSchedulerEvent(
  partial: Partial<SchedulerEvent>,
): SchedulerEvent {
  if (isNil(partial.assetTypeId)) {
    throw new Error('Missing Scheduler Event property: assetTypeId');
  }
  if (isNil(partial.assetId)) {
    throw new Error('Missing Scheduler Event property: assetId');
  }
  if (isNil(partial.from)) {
    throw new Error('Missing Scheduler Event property: from');
  }
  if (isNil(partial.until)) {
    throw new Error('Missing Scheduler Event property: until');
  }
  if (isNil(partial.actionId)) {
    throw new Error('Missing Scheduler Event property: actionId');
  }
  if (
    partial.assetTypeId === AssetCategoryTypeId.EvCharger &&
    isNil(partial.maxPowerRate)
  ) {
    throw new Error('Missing Scheduler Event property: maxPowerRate');
  }
  if (
    partial.assetTypeId === AssetCategoryTypeId.StorageUnit &&
    isNil(partial.stateOfCharge)
  ) {
    throw new Error('Missing Scheduler Event property: stateOfCharge');
  }

  return {
    assetTypeId: partial.assetTypeId,
    assetId: partial.assetId,
    from: partial.from,
    until: partial.until,
    actionId: partial.actionId,
    stateOfCharge: partial.stateOfCharge,
    maxPowerRate: partial.maxPowerRate,
  };
}
