import { MESSAGES } from '@i18n/bems-cloud';
import { LanguageCode } from '@model/bems-cloud/bems/users';

export const LANGUAGES_TRANSLATIONS: Record<LanguageCode, string> = {
  [LanguageCode.English]: MESSAGES.general.language.en,
  [LanguageCode.French]: MESSAGES.general.language.fr,
  [LanguageCode.German]: MESSAGES.general.language.de,
  [LanguageCode.Dutch]: MESSAGES.general.language.nl,
  [LanguageCode.Norwegian]: MESSAGES.general.language.no,
};
