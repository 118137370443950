import { yellow } from '@brightlayer-ui/colors';
import { BLUIColor } from '@brightlayer-ui/types';
import { MESSAGES } from '@i18n/bems-cloud';
import { Nil } from '@model';
import { WeatherCode } from '@model/bems-cloud/bems/devices';

export const WEATHER_CODE_TRANSLATIONS: Record<WeatherCode, string> = {
  [WeatherCode.Clear]: MESSAGES.general.weather.clear,
  [WeatherCode.Cloudy]: MESSAGES.general.weather.cloudy,
  [WeatherCode.Drizzle]: MESSAGES.general.weather.drizzle,
  [WeatherCode.Flurries]: MESSAGES.general.weather.flurries,
  [WeatherCode.Fog]: MESSAGES.general.weather.fog,
  [WeatherCode.FreezingDrizzle]: MESSAGES.general.weather.freezingDrizzle,
  [WeatherCode.FreezingRain]: MESSAGES.general.weather.freezingRain,
  [WeatherCode.HeavyFreezingRain]: MESSAGES.general.weather.heavyFreezingRain,
  [WeatherCode.HeavyIcePellets]: MESSAGES.general.weather.heavyIcePellets,
  [WeatherCode.HeavyRain]: MESSAGES.general.weather.heavyRain,
  [WeatherCode.HeavySnow]: MESSAGES.general.weather.heavySnow,
  [WeatherCode.IcePellets]: MESSAGES.general.weather.icePellets,
  [WeatherCode.LightFog]: MESSAGES.general.weather.lightFog,
  [WeatherCode.LightFreezingRain]: MESSAGES.general.weather.lightFreezingRain,
  [WeatherCode.LightIcePellets]: MESSAGES.general.weather.lightIcePellets,
  [WeatherCode.LightRain]: MESSAGES.general.weather.lightRain,
  [WeatherCode.LightSnow]: MESSAGES.general.weather.lightSnow,
  [WeatherCode.LightWind]: MESSAGES.general.weather.lightWind,
  [WeatherCode.MostlyClear]: MESSAGES.general.weather.mostlyClear,
  [WeatherCode.MostlyCloudy]: MESSAGES.general.weather.mostlyCloudy,
  [WeatherCode.PartlyCloudy]: MESSAGES.general.weather.partlyCloudy,
  [WeatherCode.Rain]: MESSAGES.general.weather.rain,
  [WeatherCode.Snow]: MESSAGES.general.weather.snow,
  [WeatherCode.StrongWind]: MESSAGES.general.weather.strongWind,
  [WeatherCode.Thunderstorm]: MESSAGES.general.weather.thunderstorm,
  [WeatherCode.Unknown]: '',
  [WeatherCode.Wind]: MESSAGES.general.weather.wind,
};

export const WEATHER_CODE_ICONS: Record<WeatherCode, string> = {
  [WeatherCode.Clear]: 'wi-day-sunny',
  [WeatherCode.Cloudy]: 'wi-cloudy',
  [WeatherCode.Drizzle]: 'wi-showers',
  [WeatherCode.Flurries]: 'wi-snow-wind',
  [WeatherCode.Fog]: 'wi-fog',
  [WeatherCode.FreezingDrizzle]: 'wi-sleet',
  [WeatherCode.FreezingRain]: 'wi-sleet',
  [WeatherCode.HeavyFreezingRain]: 'wi-sleet',
  [WeatherCode.HeavyIcePellets]: 'wi-hail',
  [WeatherCode.HeavyRain]: 'wi-rain',
  [WeatherCode.HeavySnow]: 'wi-snow-wind',
  [WeatherCode.IcePellets]: 'wi-hail',
  [WeatherCode.LightFog]: 'wi-day-fog',
  [WeatherCode.LightFreezingRain]: 'wi-sleet',
  [WeatherCode.LightIcePellets]: 'wi-hail',
  [WeatherCode.LightRain]: 'wi-rain',
  [WeatherCode.LightSnow]: 'wi-snow',
  [WeatherCode.LightWind]: 'wi-windy',
  [WeatherCode.MostlyClear]: 'wi-day-sunny',
  [WeatherCode.MostlyCloudy]: 'wi-cloudy',
  [WeatherCode.PartlyCloudy]: 'wi-day-cloudy',
  [WeatherCode.Rain]: 'wi-rain',
  [WeatherCode.Snow]: 'wi-snow',
  [WeatherCode.StrongWind]: 'wi-strong-wind',
  [WeatherCode.Thunderstorm]: 'wi-thunderstorm',
  [WeatherCode.Unknown]: 'wi-na',
  [WeatherCode.Wind]: 'wi-windy',
};

export const WEATHER_CODE_COLOR: Record<WeatherCode, BLUIColor | Nil> = {
  [WeatherCode.Clear]: yellow,
  [WeatherCode.Cloudy]: undefined,
  [WeatherCode.Drizzle]: undefined,
  [WeatherCode.Flurries]: undefined,
  [WeatherCode.Fog]: undefined,
  [WeatherCode.FreezingDrizzle]: undefined,
  [WeatherCode.FreezingRain]: undefined,
  [WeatherCode.HeavyFreezingRain]: undefined,
  [WeatherCode.HeavyIcePellets]: undefined,
  [WeatherCode.HeavyRain]: undefined,
  [WeatherCode.HeavySnow]: undefined,
  [WeatherCode.IcePellets]: undefined,
  [WeatherCode.LightFog]: undefined,
  [WeatherCode.LightFreezingRain]: undefined,
  [WeatherCode.LightIcePellets]: undefined,
  [WeatherCode.LightRain]: undefined,
  [WeatherCode.LightSnow]: undefined,
  [WeatherCode.LightWind]: undefined,
  [WeatherCode.MostlyClear]: yellow,
  [WeatherCode.MostlyCloudy]: undefined,
  [WeatherCode.PartlyCloudy]: undefined,
  [WeatherCode.Rain]: undefined,
  [WeatherCode.Snow]: undefined,
  [WeatherCode.StrongWind]: undefined,
  [WeatherCode.Thunderstorm]: undefined,
  [WeatherCode.Unknown]: undefined,
  [WeatherCode.Wind]: undefined,
};
