export function wattToKwh(watts: number, timeInHours = 1): number {
  return (watts * timeInHours) / 1000;
}

export function kwhToWatt(kwh: number, timeInHours = 1): number {
  return kwh * timeInHours * 1000;
}

export function wattToKiloWatt(value: number): number {
  return value / 1000;
}

export function wattToMegaWatt(value: number): number {
  return value / 1000_000;
}

export function wattToGigaWatt(value: number): number {
  return value / 1000_000_000;
}
