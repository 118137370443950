import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Nil } from '@model';
import { first, has, isNil } from 'lodash-es';

export function getData<T>(
  route: ActivatedRoute | ActivatedRouteSnapshot,
  property: string,
): T {
  const value = isActivatedRoute(route)
    ? route.snapshot.data[property]
    : route.data[property];

  if (isNil(value)) {
    throw new Error(`Missing route data property: ${property}`);
  }

  return value;
}

export function getOptionalData<T>(
  route: ActivatedRoute,
  property: string,
): T | Nil {
  return route.snapshot.data[property];
}

export function getChildPath(route: ActivatedRouteSnapshot): string | Nil {
  const child = first(route.children);
  if (isNil(child)) {
    return undefined;
  }
  return first(child.url)?.path;
}

function isActivatedRoute(
  route: ActivatedRoute | ActivatedRouteSnapshot,
): route is ActivatedRoute {
  return has(route, 'snapshot');
}

export function isRootRoute(): boolean {
  return new RegExp(`^(\/\\w{2})?\/$`).test(window.location.pathname);
}

/**
 * The device id, if there, is always the first segment of the url
 * use regex to ignore url with localization
 * @returns device id
 */
export function getDeviceIdFromRoute(): string | undefined {
  const deviceRegEx = new RegExp(
    `^(\/\\w{2})?\/(?<deviceId>[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})`,
  );
  return window.location.pathname.match(deviceRegEx)?.groups?.deviceId;
}

/**
 * Parse url and remove localization part 'en', 'it', etc.
 * @param url current url
 * @returns new url
 */
export function getUrlWithoutLocalization(url: string): string {
  const reg = /^(\/\w{2})?(?<url>\/.*)/.exec(url);
  return reg?.groups?.url ?? '';
}

/**
 * Check if any url match with current URL
 * @param urls List of possible urls
 * @returns true if match
 */
export function checkUrlMatch(urls: string[]) {
  const path = window.location.pathname;
  const authUrls = urls.join('|');
  // ignores localization in url
  const reg = new RegExp(`^(\/\\w{2})?\/(${authUrls})`);
  return reg.test(path);
}
