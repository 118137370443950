import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Loading } from '@layout/loading';
import { Id, isWithId } from '@model';
import { AssetCategoryTypeId, ListAsset } from '@model/bems-cloud/bems/assets';
import { Device } from '@model/bems-cloud/bems/devices';
import { Invitation } from '@model/bems-cloud/bems/invitations';
import { Permission } from '@model/bems-cloud/bems/permissions';
import { CurrentDeviceService } from '@store/bems-cloud/current-device';
import { LayoutService } from '@store/bems-cloud/layout';

import {
  EULA_ACCEPT_PAGE_URL_SEGMENTS,
  EULA_PAGE_URL_SEGMENTS,
  LOGIN_URL_SEGMENT,
} from '@core/bems-cloud/login-portal/const';
import { environment } from '@env/bems-cloud/environment';
import { isEmpty } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import {
  ACCOUNT_URL_SEGMENT,
  ASSETS_URL_SEGMENT,
  DASHBOARD_URL_SEGMENT,
  FORBIDDEN_URL_SEGMENT,
  INTERNAL_SERVER_ERROR_URL_SEGMENT,
  INVITATIONS_URL_SEGMENT,
  INVITATION_EXPIRED_URL_SEGMENT,
  NOT_FOUND_URL_SEGMENT,
  NO_DEVICE_URL_SEGMENT,
  PERMISSIONS_URL_SEGMENT,
  SCHEDULER_URL_SEGMENT,
  SERVICE_UNAVAILABLE_URL_SEGMENT,
  SESSION_EXPIRED_URL_SEGMENT,
  USERS_URL_SEGMENT,
} from '../const';
import {
  ASSET_CATEGORY_ID_URL_SEGMENT,
  checkUrlMatch,
  isRootRoute,
} from '../utils';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public constructor(
    private router: Router,
    private currentDeviceService: CurrentDeviceService,
    private layoutService: LayoutService,
    private cookiesService: CookieService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  public readonly redirectUrlName = 'redirectUrl';

  public navigateToRoot() {
    this.router.navigate(['']);
  }

  /**
   * Redirect to new url with page refresh
   * @param url destinition url
   * @param includeRedirectUrl add current url as query param
   */
  public redirect(url: string, includeRedirectUrl = false) {
    const redirectUrl = this.getCurrentUrl();
    if (includeRedirectUrl && !isEmpty(redirectUrl)) {
      window.location.href = `${url}?${this.redirectUrlName}=${redirectUrl}`;
    } else {
      window.location.href = url;
    }
  }

  /**
   * Redirect user to new localized app if needed
   * @param language name of the new language
   */
  public updateCurrentLanguage(language: string): void {
    if (!environment.local && language !== this.locale) {
      this.cookiesService.set('prefered_language', language, undefined, '/');
      const newUrl = window.location.href.replace(
        `/${this.locale}/`,
        `/${language}/`,
      );

      this.redirect(newUrl);
    }
  }

  /*
   * LOGIN
   */
  public navigateToLogin(): void {
    this.router.navigate(
      [LOGIN_URL_SEGMENT],
      this.getRedirectUrl(LOGIN_URL_SEGMENT),
    );
  }

  /*
   * ACCOUNT
   */
  public navigateToAccount(): void {
    this.router.navigate([ACCOUNT_URL_SEGMENT]);
  }

  /**
   * EULA
   */
  public navigateToEULAAccept() {
    this.router.navigate(EULA_ACCEPT_PAGE_URL_SEGMENTS, this.getRedirectUrl());
  }

  public navigateToEULA() {
    this.router.navigate(EULA_PAGE_URL_SEGMENTS);
  }

  /*
   * DASHBOARD
   */
  public navigateToDashboard(deviceOrId: Device | Id): void {
    const id = isWithId(deviceOrId) ? deviceOrId.id : deviceOrId;
    this.router.navigate([id, DASHBOARD_URL_SEGMENT]);
    this.currentDeviceService.fetchCurrentDevice(id);
  }

  /*
   * DEVICE
   */
  public navigateToDevice(currentDeviceId: Id, newDeviceId: Id): void {
    // since the user can be anywhere in the application but we want to keep
    // the current route, we need to replace the current device id with the new id
    this.router.navigateByUrl(
      this.router.url.replace(
        currentDeviceId.toString(),
        newDeviceId.toString(),
      ),
    );
    this.currentDeviceService.fetchCurrentDevice(newDeviceId);
    this.layoutService.setLoading(Loading.App);
  }

  /*
   * ASSETS
   */
  public navigateToAssets(device: Device) {
    this.router.navigate([device.id, ASSETS_URL_SEGMENT]);
  }

  public navigateToAsset(
    categoryId: AssetCategoryTypeId | Id,
    device: Device,
    asset: ListAsset,
  ) {
    const assetTypeSegment = ASSET_CATEGORY_ID_URL_SEGMENT[categoryId];
    this.router.navigate([
      device.id,
      ASSETS_URL_SEGMENT,
      assetTypeSegment,
      asset.id,
    ]);
  }

  public navigateToAssetsCategory(
    categoryId: AssetCategoryTypeId,
    device: Device,
  ) {
    this.router.navigate([device.id, ASSETS_URL_SEGMENT], {
      queryParams: {
        category: categoryId,
      },
    });
  }

  /*
   * USERS
   */
  public navigateToUsers(device: Device): void {
    this.router.navigate([device.id, USERS_URL_SEGMENT]);
  }

  public navigateToUser(device: Device, permission: Permission): void {
    this.router.navigate([
      device.id,
      USERS_URL_SEGMENT,
      PERMISSIONS_URL_SEGMENT,
      permission.id,
    ]);
  }

  public navigateToUsersInvitations(device: Device) {
    this.router.navigate([
      device.id,
      USERS_URL_SEGMENT,
      INVITATIONS_URL_SEGMENT,
    ]);
  }

  public navigateToUserInvitation(device: Device, invitation: Invitation) {
    this.router.navigate([
      device.id,
      USERS_URL_SEGMENT,
      INVITATIONS_URL_SEGMENT,
      invitation.id,
    ]);
  }

  public navigateToScheduler(device: Device) {
    this.router.navigate([device.id, SCHEDULER_URL_SEGMENT]);
  }

  /*
   * ERRORS
   */
  public navigateToNoDevicePage(): void {
    this.router.navigate([NO_DEVICE_URL_SEGMENT]);
  }

  public navigateToExpiredInvitation(): void {
    this.router.navigate([INVITATION_EXPIRED_URL_SEGMENT]);
  }

  public navigateToSessionExpired(): void {
    this.router.navigate([SESSION_EXPIRED_URL_SEGMENT], this.getRedirectUrl());
  }

  public navigateToForbidden(): void {
    this.router.navigate([FORBIDDEN_URL_SEGMENT]);
  }

  public navigateToNotFound(): void {
    this.router.navigate([NOT_FOUND_URL_SEGMENT]);
  }

  public navigateToInternalServerError(): void {
    this.router.navigate([
      INTERNAL_SERVER_ERROR_URL_SEGMENT,
      {
        [this.redirectUrlName]: this.router.url.includes(LOGIN_URL_SEGMENT)
          ? LOGIN_URL_SEGMENT
          : DASHBOARD_URL_SEGMENT,
      },
    ]);
  }

  public navigateToServiceUnavailable(): void {
    this.router.navigate([SERVICE_UNAVAILABLE_URL_SEGMENT]);
  }

  /**
   * @returns Url with query params without host
   */
  private getCurrentUrl(): string {
    return isRootRoute()
      ? ''
      : window.location.pathname + window.location.search;
  }

  private getRedirectUrl(nextUrl = ''): NavigationExtras | undefined {
    return isRootRoute() ||
      window.location.search.includes(this.redirectUrlName) ||
      (!isEmpty(nextUrl) && checkUrlMatch([nextUrl]))
      ? undefined
      : {
          queryParams: {
            [this.redirectUrlName]: this.getCurrentUrl(),
          },
          queryParamsHandling: 'merge',
        };
  }
}
