import { TooltipPosition as MatTooltipPosition } from '@angular/material/tooltip';
import { TooltipOriginPosition, TooltipPosition } from './tooltip.types';

export const tooltipPositionsData: TooltipOriginPosition = {
  right: {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
  },
  left: {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
  },
  top: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  },
  bottom: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
};

export const MAT_TOOLTIP_POSITIONS: Record<
  TooltipPosition,
  MatTooltipPosition
> = {
  ['left']: 'left',
  ['right']: 'right',
  ['top']: 'above',
  ['bottom']: 'below',
};

export const TOOLTIP_POSITIONS: Record<MatTooltipPosition, TooltipPosition> = {
  ['left']: 'left',
  ['right']: 'right',
  ['above']: 'top',
  ['below']: 'bottom',
  ['before']: 'top',
  ['after']: 'bottom',
};
