import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { DevicesApiService } from '@api/bems-cloud/bems/devices';
import { DASHBOARD_URL_SEGMENT } from '@core/bems-cloud/bems/const';
import { NavigationService } from '@core/bems-cloud/bems/services';
import { Id, Nil } from '@model';
import { Device } from '@model/bems-cloud/bems/devices';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { find, first, get, isNil, isString } from 'lodash-es';
import { Observable, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import {
  getDeviceIdFromRoute,
  isRootRoute,
  isUserInvitationAcceptPage,
} from '@core/bems-cloud/bems/utils';
import { fetchCurrentUserRole } from '../current-user';
import { setLoading } from '../layout';
import {
  fetchCurrentDevice,
  fetchCurrentDeviceError,
  fetchCurrentDeviceSettings,
  fetchCurrentDeviceSettingsError,
  fetchCurrentDeviceSettingsSuccess,
  fetchCurrentDeviceSuccess,
  fetchCurrentDeviceWeather,
  fetchCurrentDeviceWeatherError,
  fetchCurrentDeviceWeatherSuccess,
  noDeviceError,
} from './current-device.actions';
import { CurrentDeviceService } from './current-device.service';

@Injectable()
export class CurrentDeviceEffects {
  public constructor(
    private actions$: Actions,
    private currentDeviceService: CurrentDeviceService,
    private deviceApiService: DevicesApiService,
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  public fetchCurrentDevice$ = createEffect(() => {
    return this.fetchCurrentDeviceEffect();
  });

  public fetchCurrentDeviceWeather = createEffect(() => {
    return this.fetchCurrentDeviceWeatherEffect();
  });

  public fetchCurrentDeviceSuccess$ = createEffect(() => {
    return this.fetchCurrentDeviceSuccessEffect();
  });

  public fetchCurrentDeviceSettings$ = createEffect(() => {
    return this.fetchCurrentDeviceSettingsEffect();
  });

  public fetchCurrentDeviceSettingsSuccess$ = createEffect(() => {
    return this.fetchCurrentDeviceSettingsSuccessEffect();
  });

  public noDeviceError$ = createEffect(
    () => {
      return this.noDeviceErrorEffect();
    },
    { dispatch: false },
  );

  private fetchCurrentDeviceSettingsEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentDeviceSettings),
      switchMap(({ id }) => {
        return this.deviceApiService.getDeviceSettings(id);
      }),
      map((response) => {
        if (isApiErrorResponse(response)) {
          return fetchCurrentDeviceSettingsError(response);
        }
        return fetchCurrentDeviceSettingsSuccess({ settings: response });
      }),
    );
  }

  private fetchCurrentDeviceEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentDevice),
      withLatestFrom(this.currentDeviceService.device$),
      switchMap(([{ id }, device]) => {
        return this.fetchCurrentDevice(id || device);
      }),
    );
  }

  private fetchCurrentDeviceWeatherEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentDeviceWeather),
      withLatestFrom(this.currentDeviceService.device$),
      switchMap(([_, device]) => {
        if (isNil(device)) {
          return of(undefined);
        }
        return this.deviceApiService.getDeviceWeather(device.id);
      }),
      map((results) => {
        if (isApiErrorResponse(results)) {
          return fetchCurrentDeviceWeatherError(results);
        }

        return fetchCurrentDeviceWeatherSuccess({
          weather: results,
        });
      }),
    );
  }

  private fetchCurrentDeviceSuccessEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentDeviceSuccess),
      tap((state) => {
        // we need to redirect the user to dashboard if he is right now on the root '/'
        if (isRootRoute()) {
          this.router.navigate([state.device?.id, DASHBOARD_URL_SEGMENT]);
        }
      }),
      switchMap((state) => {
        const actions: Action[] = [];

        if (isNil(state.device)) {
          return [setLoading({ loading: undefined })];
        }

        actions.push(fetchCurrentDeviceSettings({ id: state.device.id }));

        actions.push(fetchCurrentDeviceWeather());

        actions.push(fetchCurrentUserRole(state.device));

        return actions;
      }),
    );
  }

  private fetchCurrentDeviceSettingsSuccessEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentDeviceSettingsSuccess),
      map(() => {
        return setLoading({ loading: undefined });
      }),
    );
  }

  private noDeviceErrorEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(noDeviceError),
      tap(() => {
        // We have one special case with the invitation accept page
        // We can't fetch the device because the user might not have any or access to it for now
        // So we need to let the user access the invitation page instead of redirecting him to the no device page
        if (!isUserInvitationAcceptPage(window.location.pathname)) {
          this.navigationService.navigateToNoDevicePage();
        }
      }),
    );
  }

  private fetchCurrentDevice(
    currentDevice: Device | Id | Nil,
  ): Observable<Action> {
    // There is no direct way to fetch the current device so we have to fetch all of them then "pick" right one
    return this.deviceApiService.getDevices().pipe(
      switchMap((results) => {
        if (isApiErrorResponse(results)) {
          return of(fetchCurrentDeviceError(results));
        }

        // we get the id from the fetchCurrentDevice action or from the current device or from the url
        const currentDeviceId = this.getCurrentDeviceId(
          currentDevice,
          results.items,
        );

        if (isNil(currentDeviceId) || results.total === 0) {
          return of(noDeviceError());
        }

        return this.deviceApiService.getDevice(currentDeviceId).pipe(
          map((device) => {
            if (isApiErrorResponse(device)) {
              return fetchCurrentDeviceError(device);
            }
            if (isNil(device)) {
              return noDeviceError();
            }
            return fetchCurrentDeviceSuccess({
              device,
              devices: results.items,
            });
          }),
        );
      }),
    );
  }

  private getCurrentDeviceId(
    currentDevice: Device | Nil | Id,
    devices: Device[],
  ): Id | Nil {
    // if the id isn't provided we take it from the url
    if (isNil(currentDevice)) {
      return getDeviceIdFromRoute() || first(devices)?.id;
    }

    // if currentDevice is an Id we use it in priority
    if (isString(currentDevice)) {
      return currentDevice;
    }

    // if the currentDevice is a Device we use its id
    const id = get(currentDevice, 'id');

    //we must check if the id is part of the devices list
    if (isNil(id) || isNil(find(devices, { id }))) {
      return first(devices)?.id;
    }

    return id;
  }
}
