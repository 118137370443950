import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

import { IconComponent, IconSize } from '../icon';
import { Action, ActionColor } from './action.types';

@Component({
  selector: 'etn-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
})
export class ActionComponent {
  @Input() public action: Action | Nil;
  @Input() public context: any | Nil;
  @Output() public actionClick = new EventEmitter<Action>();

  @HostBinding('class.hover')
  public hover = false;

  public defaultIconSize: IconSize = IconSize.Small;

  public get id(): string {
    return `action-${this.action?.id}`;
  }

  @HostBinding('class.full-width')
  public get fullWidth(): boolean {
    return this.action?.fullWidth || false;
  }

  @HostBinding('class.disabled')
  public get disabled(): boolean {
    return this.action?.disabled || false;
  }

  @HostBinding('class.uppercase')
  public get uppercase(): boolean {
    return this.action?.uppercase || false;
  }

  @HostBinding('class.rounded')
  public get rounded(): boolean {
    return this.action?.rounded || false;
  }

  @HostBinding('class.loading')
  public get loading(): boolean {
    return this.action?.loading || false;
  }

  @HostBinding('class')
  public get class(): string {
    return `action-${this.action?.id}`;
  }

  public get color(): ActionColor | Nil {
    return this.hover && this.action?.hoverColor
      ? this.action?.hoverColor
      : this.action?.color;
  }

  public onActionClick(event: MouseEvent, action: Action): void {
    if (isNil(action.preventDefault) || action.preventDefault === true) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.actionClick.emit(action);
    if (action.callback) {
      action.callback(this.context);
    }
  }

  public onMouseEnter(): void {
    this.hover = true;
  }

  public onMouseLeave(): void {
    this.hover = false;
  }

  public get buttonStyle(): Record<string, string> {
    return {
      height: this.action?.height ? `${this.action.height}px` : 'auto',
    };
  }
}
