import { AssetMetric } from '../../../features/assets/components/asset-metric';

export interface PowerMeterTopMetrics {
  totalActivePowerMetric: AssetMetric;
  totalApparentPowerMetric: AssetMetric;
  totalReactivePowerMetric: AssetMetric;
  totalCurrentMetric: AssetMetric;
  powerFactorMetric: AssetMetric;
  frequencyMetric: AssetMetric;
}
