import { createSelector } from '@ngrx/store';

import { State } from '../store.state';

const getCurrentUserState = (state: State) => {
  return state.currentUser;
};

export const getCurrentUser = createSelector(getCurrentUserState, (state) => {
  return state.user;
});

export const getCurrentUserRole = createSelector(
  getCurrentUserState,
  (state) => {
    return state.role;
  },
);
