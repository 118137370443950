import { MESSAGES } from '@i18n/bems-cloud';
import { Role } from '@model/bems-cloud/bems/roles';
import { SelectableItem } from '@ui/selectable-item';

export const ROLES: SelectableItem[] = [
  {
    id: Role.Administrator,
    name: MESSAGES.general.userRole.administrator,
  },
  {
    id: Role.Reader,
    name: MESSAGES.general.userRole.reader,
  },
];

export const ROLES_TRANSLATIONS: Record<Role, string> = {
  [Role.Administrator]: MESSAGES.general.userRole.administrator,
  [Role.Reader]: MESSAGES.general.userRole.reader,
};
