import { z } from 'zod';

export const MonthlyHistoryJsonSchema = z.object({
  year: z.number(),
  month: z.number(),
  read: z.number(),
  unit: z.string(),
});

export const YearlyHistoryJsonSchema = z.object({
  year: z.number(),
  read: z.number(),
  unit: z.string(),
});

export const AdvancedPeakShavingHistoryJsonSchema = z.object({
  monthlyHistory: z.array(MonthlyHistoryJsonSchema),
  yearlyHistory: z.array(YearlyHistoryJsonSchema),
});

export type AdvancedPeakShavingHistoryJson = z.infer<
  typeof AdvancedPeakShavingHistoryJsonSchema
>;
