import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/bems-cloud/environment';
import { mergeMap, take } from 'rxjs';

import { AuthService } from './store/auth';

export const apiInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);

  if (request.url.startsWith(environment.urls.api)) {
    return authService.auth$.pipe(
      take(1),
      mergeMap((auth) => {
        const headers = request.headers.set(
          'X-CSRF-TOKEN',
          `${auth?.csrfToken}`,
        );

        return next(
          request.clone({
            headers,
          }),
        );
      }),
    );
  }

  return next(request);
};
