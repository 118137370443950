import { WithId, WithName } from '@model';

export interface Device extends WithId, WithName {
  profileId?: string;
  macAddress?: string;
  serial?: string;
  asset?: string;
  family?: string;
  hardware?: string;
  model?: string;
  modelLongName?: string;
  modelShortName?: string;
  role?: string;
  software?: string;
  vendor?: string;
}
