import { Nil } from '@model';
import { isWeekend } from 'date-fns';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

export function getToday(): Date {
  const now = new Date();
  now.setMinutes(0);
  now.setHours(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

export function getTomorrow(): Date {
  const now = getToday();
  now.setDate(now.getDate() + 1);
  now.setMinutes(0);
  now.setHours(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

export function addDays(date: Date, days: number): Date {
  return moment(date).add(days, 'days').toDate();
}

export function addMinutes(date: Date, minutes: number): Date {
  return moment(date).add(minutes, 'minute').toDate();
}

export function subtractDays(date: Date, days: number): Date {
  return moment(date).subtract(days, 'days').toDate();
}

export function subtractSeconds(date: Date, seconds: number): Date {
  return moment(date).subtract(seconds, 'seconds').toDate();
}

export function formatDate(date: Date, format?: string): string {
  return moment(date).format(format || 'L LTS');
}

/**
 * Convert a date to locale string
 * If the hour is midnight we don't display it
 * If the hour is midnight the "excludedMessage" will be used to signify the date is not included.
 * @param date the date to convert
 * @param excludedMessage excluded message wrapper
 * @returns the date a locale string
 */
export function getLocaleString(
  date: Date,
  excludedMessage?: (date: string) => string,
): string {
  if (
    date.getHours() === 0 &&
    date.getMinutes() === 0 &&
    date.getSeconds() === 0
  ) {
    const value = date.toLocaleDateString();
    return isNil(excludedMessage) ? value : excludedMessage(value);
  }
  return date.toLocaleString();
}

export function getWeekDays(): string[] {
  return new Array(7).fill(undefined).map((_, index) => {
    return moment().weekday(index).format('dddd');
  });
}

export function getWeekendDays(): number[] {
  return new Array(7)
    .fill(undefined)
    .map((_, index) => {
      return moment().weekday(index);
    })
    .filter((date) => {
      return isWeekend(date.toDate());
    })
    .map((date) => {
      return date.day();
    });
}

export function getLastDayOfTheMonth(): Date {
  const now = new Date();
  const day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  return day;
}

export function getLastDayOfTheLastMonth(): Date {
  const now = new Date();
  const day = new Date(now.getFullYear(), now.getMonth(), 0);
  return day;
}

export function getFirstDayOfTheMonth(): Date {
  const now = new Date();
  const day = new Date(now.getFullYear(), now.getMonth(), 1);
  return day;
}

export function getFirstDayOfTheLastMonth(): Date {
  const now = new Date();
  const day = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  return day;
}

export function daysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

export function isCurrentMonth(date: Date): boolean {
  const now = new Date();
  return (
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

export function getCurrentQuarter(): number {
  return Math.floor(new Date().getMonth() / 3);
}

export function getCurrentQuarterStartDate(): Date {
  const today = new Date();
  const quarter = getCurrentQuarter();
  return new Date(today.getFullYear(), quarter * 3, 1);
}

export function getLastQuarterStartDate(): Date {
  const today = new Date();
  const quarter = getCurrentQuarter() - 1;
  if (quarter < 0) {
    return new Date(today.getFullYear() - 1, 9, 1);
  }
  return new Date(today.getFullYear(), quarter * 3, 1);
}

export function getCurrentQuarterEndDate(): Date {
  const startDate = getCurrentQuarterStartDate();
  return new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
}

export function getLastQuarterEndDate(): Date {
  const startDate = getLastQuarterStartDate();
  return new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
}

/**
 * Convert a time string in 24h format into a date
 * The date will be today with the time passed as parameter
 * @param time time string (ex: 08:12:56)
 */
export function getDateFromTime(time: string): Date {
  const today = getToday();

  const parts: number[] = time.split(':').map((part) => {
    return parseInt(part, 10);
  });

  if (parts.length !== 3) {
    throw new Error(`Invalid time string: ${time}`);
  }

  today.setHours(parts[0]);
  today.setMinutes(parts[1]);
  today.setSeconds(parts[2]);

  return today;
}

/**
 * Convers date into number 15:20 => 1520, 9:35 => 935
 * @param date date or null
 * @returns number
 */
export function getMilitaryTime(date: Date | Nil): number {
  if (isNil(date)) {
    return 0;
  }
  const momentDate = moment(date);
  return momentDate.hours() * 100 + momentDate.minutes();
}
/**
 * Convers number into date 520 => '2023-08-01T05:20:00.000Z'
 * @param value number
 * @returns current date with time
 */
export function getDateFromMilitaryTime(value: number | Nil): Date | Nil {
  if (isNil(value)) {
    return null;
  }
  const leadingZero = '0000' + value;
  const newValue = leadingZero.substring(leadingZero.length - 4);
  return moment(newValue, 'HHmm').toDate();
}

/**
 * Parse string into date time ignoring timezone
 * @param stringDate iso string date time
 * @returns local time in local format
 */
export function getTimeInLocalFormat(stringDate: string) {
  return moment(stringDate, 'YYYY-MM-DDTHH:mm:ss')
    .toDate()
    .toLocaleTimeString();
}

/**
 * Combine input date and time
 * @param date selected time to combine
 * @param time selected time to combine
 * @returns combine date
 */
export function combineDateTime(
  date: Date | Nil,
  time: Date | Nil,
): Date | undefined {
  if (isNil(date) || isNil(time)) {
    return undefined;
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
  );
}
