export enum WeatherCode {
  Clear = 1000,
  Cloudy = 1001,
  Drizzle = 4000,
  Flurries = 5001,
  Fog = 2000,
  FreezingDrizzle = 6000,
  FreezingRain = 6001,
  HeavyFreezingRain = 6201,
  HeavyIcePellets = 7101,
  HeavyRain = 4201,
  HeavySnow = 5101,
  IcePellets = 7000,
  LightFog = 2100,
  LightFreezingRain = 6200,
  LightIcePellets = 7102,
  LightRain = 4200,
  LightSnow = 5100,
  LightWind = 3000,
  MostlyClear = 1100,
  MostlyCloudy = 1102,
  PartlyCloudy = 1101,
  Rain = 4001,
  Snow = 5000,
  StrongWind = 3002,
  Thunderstorm = 8000,
  Unknown = 0,
  Wind = 3001,
}
