import { Nil } from '@model';
import { filter } from 'lodash-es';

import { Chart } from './chart';
import { ChartType } from './enums/chart-type';
import { SolarRadiation } from './solar-radiation';

export interface Charts {
  charts: Chart[];
  timezone: string;
  solarRadiation: SolarRadiation | Nil;
}

export function getSourceChart({ charts }: Charts): Chart[] {
  return filter(charts, { type: ChartType.Source });
}

export function getLoadChart({ charts }: Charts): Chart[] {
  return filter(charts, { type: ChartType.Load });
}

export function getPerformanceMetricsChart({ charts }: Charts): Chart[] {
  return filter(charts, { type: ChartType.PerformanceMetrics });
}
