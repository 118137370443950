import { z } from 'zod';

import { ValueTrendDataJsonSchema } from './value-trend-data.io';

export const ValueTrendJsonSchema = z.object({
  day: ValueTrendDataJsonSchema,
  month: ValueTrendDataJsonSchema,
});

export type ValueTrendJson = z.infer<typeof ValueTrendJsonSchema>;
