import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { DevicesApiService } from '@api/bems-cloud/bems/devices';
import { UsersApiService } from '@api/bems-cloud/bems/users';
import { LOGIN_URL_SEGMENT } from '@core/bems-cloud/bems/const';
import { NavigationService } from '@core/bems-cloud/bems/services';
import {
  checkUrlMatch,
  getUrlWithoutLocalization,
} from '@core/bems-cloud/bems/utils';
import { AUTH_URL_SEGMENT } from '@core/bems-cloud/login-portal/const';
import { EMPTY_ACTION } from '@core/ngrx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { fetchCurrentDevice } from '../current-device';
import { setLoading } from '../layout';
import { fetchNotificationCount } from '../notifications';
import {
  acceptEula,
  fetchCurrentUser,
  fetchCurrentUserError,
  fetchCurrentUserRole,
  fetchCurrentUserRoleError,
  fetchCurrentUserRoleSuccess,
  fetchCurrentUserSuccess,
} from './current-user.actions';

@Injectable()
export class CurrentUserEffects {
  public constructor(
    @Inject(LOCALE_ID) public locale: string,
    private actions$: Actions,
    private devicesApiService: DevicesApiService,
    private usersApiService: UsersApiService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  public fetchCurrentUser$ = createEffect(() => {
    return this.fetchCurrentUserEffect();
  });

  public acceptEula$ = createEffect(() => {
    return this.acceptEulaEffect();
  });

  public fetchCurrentUserError = createEffect(() => {
    return this.fetchCurrentUserErrorEffect();
  });

  public fetchCurrentUserSuccess = createEffect(() => {
    return this.fetchCurrentUserSuccessEffect();
  });

  public fetchCurrentUserRole$ = createEffect(() => {
    return this.fetchCurrentUserRoleEffect();
  });

  private fetchCurrentUserEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentUser),
      switchMap(() => {
        return this.usersApiService.getUserProfile();
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.status !== HttpStatusCode.Unauthorized) {
          throw response;
        }
        return of(response.error);
      }),
      map((response) => {
        if (isApiErrorResponse(response)) {
          return fetchCurrentUserError(response);
        }

        // if the user has changed the language we need to redirect the user to the proper application
        const language = response.language.split('-')[0];
        this.navigationService.updateCurrentLanguage(language);

        return fetchCurrentUserSuccess({ user: response });
      }),
    );
  }

  private fetchCurrentUserErrorEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentUserError),
      tap(() => {
        if (!checkUrlMatch([LOGIN_URL_SEGMENT, AUTH_URL_SEGMENT])) {
          this.navigationService.redirect(LOGIN_URL_SEGMENT, true);
        }
      }),
      map(() => {
        return EMPTY_ACTION;
      }),
    );
  }

  private fetchCurrentUserSuccessEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentUserSuccess),
      switchMap(() => {
        return [fetchCurrentDevice({}), fetchNotificationCount()];
      }),
      tap(() => {
        setLoading({ loading: undefined });
      }),
    );
  }

  private acceptEulaEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(acceptEula),
      switchMap((data) => {
        return this.usersApiService.saveUserProfile({
          acceptedEula: data.version,
        });
      }),
      tap(() => {
        const redirectUrl = getUrlWithoutLocalization(
          this.route.snapshot.queryParams.redirectUrl,
        );
        this.router.navigateByUrl(redirectUrl);
      }),
      map((response) => {
        if (isApiErrorResponse(response)) {
          return fetchCurrentUserError(response);
        }

        return fetchCurrentUserSuccess({ user: response });
      }),
    );
  }

  private fetchCurrentUserRoleEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchCurrentUserRole),
      switchMap((device) => {
        return this.devicesApiService.getUserRole(device.id);
      }),
      map((response) => {
        if (isApiErrorResponse(response)) {
          return fetchCurrentUserRoleError(response);
        }

        return fetchCurrentUserRoleSuccess({ role: response });
      }),
    );
  }
}
