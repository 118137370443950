export enum DateFormat {
  DMY = 'dd/mm/yyyy',
  YMD = 'yyyy/mm/dd',
  MDY = 'mm/dd/yyyy',
}

export const DateFormatExample: Record<DateFormat, string> = {
  [DateFormat.DMY]: '22/01/2020',
  [DateFormat.YMD]: '2020/01/22',
  [DateFormat.MDY]: '01/22/2020',
};
