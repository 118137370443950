import { isNotNil } from '@core/is-not-nil';
import { wattToKiloWatt } from '@core/math';
import { Nil } from '@model';
import {
  ChartDataField,
  ChartDataFieldUnit,
} from '@model/bems-cloud/bems/charts';
import { ChartColor, ChartData, XaxisUnit } from '@ui/chart';
import { findIndex, isNil } from 'lodash-es';

import {
  EMPTY_DATA_SET_GENERATORS,
  convertDataToTimezone,
  getWattValueTransformer,
  mergeChartData,
} from './charts.utils';

export function getLineChartData(
  chartDataField: ChartDataField | Nil,
  date: Date,
  color: ChartColor,
  timezone: string,
  negative: boolean,
  xAxisUnit: XaxisUnit,
  yAxisUnit: ChartDataFieldUnit,
): ChartData[] {
  const measured = mergeChartData(
    EMPTY_DATA_SET_GENERATORS[xAxisUnit](color, date, timezone),
    convertDataToTimezone(chartDataField?.measured, timezone),
    getWattValueTransformer(negative, yAxisUnit),
  );

  return fillForecastData(chartDataField, measured, negative, timezone);
}

export function getMergedLineChartData(
  load: ChartDataField | Nil,
  source: ChartDataField | Nil,
  date: Date,
  timezone: string,
  color: ChartColor,
  xAxisUnit: XaxisUnit,
  yAxisUnit: ChartDataFieldUnit,
): ChartData[] {
  let data = EMPTY_DATA_SET_GENERATORS[xAxisUnit](color, date, timezone);

  data = mergeChartData(
    data,
    convertDataToTimezone(load?.measured, timezone),
    getWattValueTransformer(false, yAxisUnit),
  );

  data = mergeChartData(
    data,
    convertDataToTimezone(source?.measured, timezone),
    (value, currentValue) => {
      // the backend returns positive values for loads so we need to negate them
      // if the value is null we don't want to override the current value
      if (isNil(value)) {
        return currentValue;
      }
      return getWattValueTransformer(true, yAxisUnit)(value, currentValue);
    },
  );

  return data;
}

// Fill the chart data with the additional forecast data
function fillForecastData(
  field: ChartDataField | Nil,
  data: ChartData[],
  negative: boolean,
  timezone: string,
): ChartData[] {
  const forecast = convertDataToTimezone(field?.forecast, timezone);

  if (isNotNil(forecast) && forecast.length > 0) {
    // the forecast data don't necessarly start at the beginning of the day
    // so we must find the index of the first data point in the final data set
    const index = findIndex(data, { label: forecast[0].x });

    if (index > 0) {
      data[index] = {
        ...data[index],
        predictive: true,
      };
    }

    forecast.forEach((value, i) => {
      const y = value.y || 0;
      const currentValue = data[index + i].value;
      if (isNaN(currentValue)) {
        data[index + i] = {
          ...data[index + i],
          label: value.x,
          value: wattToKiloWatt(negative ? -y : y),
          isNil: isNil(value.y),
          predictive: true,
        };
      }
    });
  }
  return data;
}
