import { IdJsonSchema } from '@api';
import { Id, Nil } from '@model';
import {
  DeviceSettings,
  SelectedPostalCode,
} from '@model/bems-cloud/bems/devices';
import { isNil } from 'lodash-es';
import { z } from 'zod';

import { PaginatedSelectableResultsJsonSchemaFactory } from '../../bems-api.types';
import { CurrencyJsonSchema } from '../../internals/io';

export const CountryInfoJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
});

export const TimezoneJsonSchema = z.object({
  id: IdJsonSchema,
  timezone: z.string(),
  name: z.string(),
  dstOffset: z.number(),
  rawOffset: z.number(),
});

export const PostalCodeInfoJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
  timezone: TimezoneJsonSchema,
});

export const PaginatedSelectableResultsJsonSchema =
  PaginatedSelectableResultsJsonSchemaFactory(CountryInfoJsonSchema);

export const PaginatedPostalCodeInfoJsonSchema =
  PaginatedSelectableResultsJsonSchemaFactory(PostalCodeInfoJsonSchema);

export const LobbyViewUrlJsonSchema = z.object({
  isActive: z.boolean(),
  url: z.string().optional(),
});

export const DeviceSettingsJsonSchema = z.object({
  id: IdJsonSchema,
  deviceId: IdJsonSchema,
  deviceName: z.string(),
  countryInfo: PaginatedSelectableResultsJsonSchema,
  postalCodeInfo: PaginatedPostalCodeInfoJsonSchema,
  gridSupplierCurrency: CurrencyJsonSchema,
  systemGoal: z.number(),
  lobbyView: LobbyViewUrlJsonSchema.optional(),
});

export type PaginatedSelectableResultsJson = z.infer<
  typeof PaginatedSelectableResultsJsonSchema
>;

export type PaginatedPostalCodeInfoJson = z.infer<
  typeof PaginatedPostalCodeInfoJsonSchema
>;

export type DeviceSettingsJson = z.infer<typeof DeviceSettingsJsonSchema>;

export function serializeDeviceSettings(
  response: DeviceSettingsJson,
): DeviceSettings {
  return {
    id: response.id,
    deviceId: response.deviceId,
    name: response.deviceName,
    country: getSelectableResultName(response.countryInfo),
    countryId: getSelectableResultId(response.countryInfo),
    postalCode: getSelectableResultName(response.postalCodeInfo),
    postalCodeId: getSelectableResultId(response.postalCodeInfo),
    postalCodeInfo: getPostalCode(response.postalCodeInfo),
    currency: response.gridSupplierCurrency,
    currencyId: response.gridSupplierCurrency.id,
    systemGoal: response.systemGoal,
    lobbyView: {
      isActive: response.lobbyView?.isActive ?? true,
      url:
        response.lobbyView?.url ??
        'https://brightlayer-bems-dev.eaton.com/en/7eb85e03-cc2b-43ab-b7cc-76bc0f0aeeda/dashboard/',
    },
  };
}

function getPostalCode(
  postalCode: PaginatedPostalCodeInfoJson,
): SelectedPostalCode | Nil {
  const selectedPos = postalCode.selectedPos;
  if (
    isNil(selectedPos) ||
    isNil(postalCode.items) ||
    selectedPos >= postalCode.items.length
  ) {
    return undefined;
  }

  const selectedPostalCode = postalCode.items[selectedPos];

  return {
    extendedName: selectedPostalCode.name,
    ...selectedPostalCode,
  };
}

function getSelectableResultId(
  settings: PaginatedSelectableResultsJson,
): Id | Nil {
  const selectedPos = settings.selectedPos;
  if (
    isNil(selectedPos) ||
    isNil(settings.items) ||
    selectedPos >= settings.items.length
  ) {
    return undefined;
  }
  return settings.items[selectedPos].id;
}

function getSelectableResultName(
  settings: PaginatedSelectableResultsJson,
): string | Nil {
  const selectedPos = settings.selectedPos;
  if (
    isNil(selectedPos) ||
    isNil(settings.items) ||
    selectedPos >= settings.items.length
  ) {
    return undefined;
  }
  return settings.items[selectedPos].name;
}
