import { Nil } from '@model';

export enum Role {
  Administrator = 0,
  Reader = 1,
}

export function isAdministrator(role: Role | Nil): boolean {
  return role === Role.Administrator;
}

export function isReader(role: Role | Nil): boolean {
  return role === Role.Reader;
}
