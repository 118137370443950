import { has } from 'lodash-es';
import { z } from 'zod';

export interface ApiErrorResponse {
  errors?: ApiError[];
  code: number;
  message?: string;
}

export interface ApiError {
  field: string;
  messages: ApiErrorMessage[];
}

export interface ApiErrorMessage {
  code: number;
  message: string;
  description: string;
}

export function isApiErrorResponse(
  response: unknown,
): response is ApiErrorResponse {
  return has(response, 'errors');
}

export interface PaginatedResults<T> {
  size: number;
  total: number;
  next: string;
  previous: string;
  items: T[];
}

export const PaginatedResultsJsonSchema = <Schema extends z.Schema>(
  schema: Schema,
) => {
  return z.object({
    size: z.number(),
    total: z.number(),
    next: z.string(),
    previous: z.string(),
    items: z.array(schema).or(z.null()),
  });
};
