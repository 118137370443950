import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LogoutResponse,
  RefreshTokenResponse,
} from '@model/bems-cloud/bems/auth';
import { Observable } from 'rxjs';

import { BemsApiService } from '../bems-api.service';
import { ApiErrorResponse } from '../bems-api.types';
import { LogoutResponseJson, LogoutResponseJsonSchema } from './io/logout.io';
import {
  RefreshTokenResponseJson,
  RefreshTokenResponseJsonSchema,
} from './io/refresh-token.io';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BemsApiService {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public signOut(): Observable<LogoutResponse | ApiErrorResponse> {
    return this.postWithoutPayload<LogoutResponse, LogoutResponseJson>({
      endpoint: 'auth/sign-out',
      responseSchema: LogoutResponseJsonSchema,
      responseDeserializer: (response) => {
        return response;
      },
    });
  }

  public refreshToken(): Observable<RefreshTokenResponse | ApiErrorResponse> {
    return this.postWithoutPayload<
      RefreshTokenResponse,
      RefreshTokenResponseJson
    >({
      endpoint: 'auth/refresh-token',
      responseSchema: RefreshTokenResponseJsonSchema,
      responseDeserializer: (response) => {
        return response;
      },
    });
  }
}
