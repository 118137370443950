import { z } from 'zod';

export const UserFileNameJsonSchema = z.object({
  name: z.string(),
  size: z.number(),
  type: z.string(),
  uri: z.string(),
});

export type UserFilenameJson = z.infer<typeof UserFileNameJsonSchema>;
