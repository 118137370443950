import { Nil } from '@model';
import {
  Device,
  DeviceSettings,
  Weather,
} from '@model/bems-cloud/bems/devices';

export interface CurrentDeviceState {
  device: Device | Nil;
  devices: Device[] | Nil;
  weather: Weather | Nil;
  settings: DeviceSettings | Nil;
}
