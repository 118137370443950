import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { isNotNil } from '@core/is-not-nil';
import { IMAGE_MAPPING, Image } from '@design-system';
import { Nil } from '@model';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { isNil } from 'lodash-es';
import { Observable, ReplaySubject, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { ImageInfo } from './image.types';

@Component({
  selector: 'etn-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit {
  public constructor(
    private iconReg: SvgIconRegistryService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {}

  @Input() public src: string | SafeUrl | Nil;
  @Input() public set name(name: Image | Nil) {
    this.svgName = undefined;
    if (name) {
      this.loadSvgSubject$.next(name);
    }
  }

  @HostBinding('style.height.px')
  @Input()
  public height: number | Nil;

  @Output() public imageLoad = new EventEmitter<ImageInfo>();
  @Output() public svgLoad = new EventEmitter<SVGElement>();

  private loadSvgSubject$ = new ReplaySubject<Image>(1);

  public loading = true;
  public svgName: string | Nil;

  public ngOnInit(): void {
    this.loadSvgSubject$
      .pipe(
        mergeMap((name) => {
          return this.loadSvg(name);
        }),
      )
      .subscribe();
  }

  public get svgStyle(): any {
    return {
      display: 'block',
      height: this.height,
    };
  }

  public get imgHeight(): string {
    return `${this.height}px`;
  }

  public onImageLoad(event: any): void {
    const width = event.currentTarget.width;
    const height = event.currentTarget.height;
    this.loading = false;
    this.imageLoad.emit({ width, height });
  }

  public get showLoadingSpinner(): boolean {
    return this.loading && isNotNil(this.height);
  }

  public get spinnerPosition(): string {
    if (isNil(this.diameter)) {
      return '0';
    }
    return `calc(50% - ${this.diameter / 2}px)`;
  }

  @HostBinding('style.min-width.px')
  public get diameter(): number | undefined {
    return this.height ? this.height / 4 : undefined;
  }

  private loadSvg(name: Image): Observable<SVGElement | Nil> {
    const obs = this.iconReg.loadSvg(`images/${IMAGE_MAPPING[name]}`, name);
    if (isNil(obs)) {
      return of(undefined);
    }
    return obs.pipe(
      tap((svgElement) => {
        this.loading = false;
        this.svgName = name;
        this.svgLoad.emit(svgElement);
        this.changeDetectionRef.markForCheck();
      }),
    );
  }
}
