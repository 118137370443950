import { Nil } from '@model';

export interface Resource {
  id: string;
  name: string;
  color: TimeLineColor;
}

export interface ResourceEvent {
  id: string;
  name: string;
  start?: Date | Nil;
  end?: Date | Nil;
  resourceId: string;
  color: TimeLineColor;
  description: string;
  tooltip: string;
  rrule?: string | Nil;
  duration?: number | Nil;
}

export enum TimeLineColor {
  Accent = 'accent',
  Primary = 'primary',
  PrimaryContrast = 'primary-contrast',
  Grey = 'grey',
  Disabled = 'disabled',
  Warn = 'warn',
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  LightBlue = 'light-blue',
  Orange = 'orange',
}
