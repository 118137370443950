import { IdJsonSchema } from '@api';
import { PaginatedResultsJsonSchemaFactory } from '@api/bems-cloud/bems/bems-api.types';
import { z } from 'zod';

export const CountryJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
  geonameId: z.string(),
  isoAlpha2: z.string(),
  isoAlpha3: z.string(),
  isoAlphaNumeric: z.number(),
});

export type CountryJson = z.infer<typeof CountryJsonSchema>;

const PaginatedCountryJsonSchema =
  PaginatedResultsJsonSchemaFactory(CountryJsonSchema);

export type PaginatedCountryJson = z.infer<typeof PaginatedCountryJsonSchema>;
