import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Id } from '@model';
import { ValueTrend, ValueTrendType } from '@model/bems-cloud/bems/dashboard';
import { Observable } from 'rxjs';

import { BemsApiService } from '../bems-api.service';
import { ApiErrorResponse } from '../bems-api.types';
import { ValueTrendJson } from './io/value-trend.io';
import { ValueTrendsJson, ValueTrendsJsonSchema } from './io/value-trends.io';

@Injectable({
  providedIn: 'root',
})
export class ValueTrendApiService extends BemsApiService {
  public constructor(
    httpClient: HttpClient,
    @Inject(LOCALE_ID) public language: string,
  ) {
    super(httpClient);
  }

  public getValueTrendData(
    id: Id,
  ): Observable<ValueTrend[] | ApiErrorResponse> {
    return this.get<ValueTrendsJson, ValueTrend[]>({
      endpoint: `devices/${id}/valuetrends`,
      schema: ValueTrendsJsonSchema,
      deserializer: (response) => {
        return this.valueTrendsDeserializer(response);
      },
    });
  }

  private valueTrendsDeserializer(response: ValueTrendsJson): ValueTrend[] {
    const valueTrends: ValueTrend[] = [];
    if (isNotNil(response.gridNode)) {
      const newGridNode = this.formatValue(response.gridNode);
      valueTrends.push({
        ...newGridNode,
        type: ValueTrendType.GridNode,
      });
    }

    if (isNotNil(response.pvNode)) {
      const newPVNode = this.formatValue(response.pvNode);
      valueTrends.push({ ...newPVNode, type: ValueTrendType.PvNode });
    }

    if (isNotNil(response.selfSufficiencyNode)) {
      valueTrends.push({
        ...response.selfSufficiencyNode,
        type: ValueTrendType.SelfSufficiencyNode,
      });
    }

    if (isNotNil(response.evChargerNode)) {
      const newEvChargerNode = this.formatValue(response.evChargerNode);
      valueTrends.push({
        ...newEvChargerNode,
        type: ValueTrendType.EvChargerNode,
      });
    }

    if (isNotNil(response.energyCostsNode)) {
      const newEnergyCostsNode = this.formatValue(response.energyCostsNode);
      valueTrends.push({
        ...newEnergyCostsNode,
        type: ValueTrendType.EnergyCostsNode,
      });
    }

    return valueTrends;
  }

  private formatValue(node: ValueTrendJson): ValueTrendJson {
    const dayValueNumber = new Intl.NumberFormat(this.language).format(
      parseFloat(node.day.value),
    );

    const monthValueNumber = new Intl.NumberFormat(this.language).format(
      parseFloat(node.month.value),
    );

    return {
      ...node,
      day: {
        ...node.day,
        value: dayValueNumber,
      },
      month: {
        ...node.month,
        value: monthValueNumber,
      },
    };
  }
}
