import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { isNotNil } from '@core/is-not-nil';
import { filterNil } from '@core/rxjs/filter-nil';
import { TrackingService } from '@core/tracking';
import { LoadingComponent } from '@layout/loading';
import { Nil } from '@model';
import {
  CookieConsent,
  CookieConsentComponent,
  CookieConsentMessages,
  CookieConsentPosition,
  CookieConsentService,
} from '@ui/cookie-consent';
import { SaveTrapComponent } from '@ui/save-trap';
import { filter, map, mergeMap, take, tap } from 'rxjs';
import { MESSAGES } from '../i18n/messages';
import { checkUrlMatch } from './bems/core/utils';
import { User } from './bems/models/users';
import { AUTH_URL_SEGMENT, LOGIN_URL_SEGMENT } from './login-portal/core/const';
import { AuthService } from './store/auth';
import { CurrentUserService } from './store/current-user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CookieConsentComponent,
    LoadingComponent,
    RouterModule,
    SaveTrapComponent,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  public constructor(
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private trackingService: TrackingService,
    private cookieConsentService: CookieConsentService,
  ) {}

  private currentUser$ = this.currentUserService.currentUser$;

  public currentUser = toSignal(this.currentUser$);

  public cookieConsentPosition = CookieConsentPosition.BottomRight;

  public cookieConsentMessages: CookieConsentMessages =
    MESSAGES.app.cookieConsent;

  public cookieConsents: CookieConsent[] = [
    {
      id: 'analytics',
      name: MESSAGES.app.cookieConsent.analytics,
      accepted: true,
    },
  ];

  public cookiesNotAccepted$ = this.currentUser$.pipe(
    filterNil(),
    mergeMap((currentUser) => {
      return this.cookieConsentService.hasConsentObservable(
        this.cookieConsents,
        currentUser.id,
      );
    }),
    map((accepted) => {
      return !accepted;
    }),
  );

  public analyticsCookiesAccepted$ = this.currentUser$.pipe(
    filterNil(),
    mergeMap((currentUser) => {
      return this.cookieConsentService.getCookieValueObservable(
        this.cookieConsents[0],
        currentUser.id,
      );
    }),
  );

  public ngOnInit(): void {
    this.analyticsCookiesAccepted$
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => {
          this.trackingService.initialize();
        }),
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.currentUserService.fetchCurrentUser();

    // TODO: find a better solution to detect the login application.
    if (!checkUrlMatch([LOGIN_URL_SEGMENT, AUTH_URL_SEGMENT])) {
      this.authService.triggerRefershTokenMechanism();
    }
  }

  public onAcceptCookies(consents: CookieConsent[], currentUser: User | Nil) {
    if (isNotNil(currentUser)) {
      this.cookieConsentService.saveConsent(consents, currentUser.id);
    }
  }
}
