import { getDateFromMilitaryTime, getMilitaryTime } from '@core/date';
import {
  ConsumptionRateType,
  GridSettings,
  RateTypes,
} from '@model/bems-cloud/bems/devices';
import { z } from 'zod';

import { CurrencyJsonSchema } from '../../internals/io';

export const GridConsumptionRateJsonSchema = z.object({
  type: z.nativeEnum(ConsumptionRateType),
  start: z.number(),
  end: z.number(),
  tariff: z.number(),
});

export const GridSettingsWithCurrencyJsonSchema = z.object({
  gridSupplierCurrency: CurrencyJsonSchema,
  consumptionRates: z.array(GridConsumptionRateJsonSchema),
});

export const GridSettingsJsonSchema = z.object({
  consumptionRates: z.array(GridConsumptionRateJsonSchema),
});

export type GridConsumptionRateJson = z.infer<
  typeof GridConsumptionRateJsonSchema
>;
export type GridSettingsWithCurrencyJson = z.infer<
  typeof GridSettingsWithCurrencyJsonSchema
>;
export type GridSettingsJson = z.infer<typeof GridSettingsJsonSchema>;

export function deserializeGridSettings(model: GridSettingsJson): GridSettings {
  const peak = model.consumptionRates?.find((x) => {
    return x.type === ConsumptionRateType.Peak;
  });
  const offPeak = model.consumptionRates?.find((x) => {
    return x.type === ConsumptionRateType.OffPeak;
  });

  return {
    rateType:
      model.consumptionRates?.length === 2
        ? RateTypes.TwoRates
        : RateTypes.OneRate,
    tariff: model.consumptionRates?.[0]?.tariff ?? 0,
    startPeak: getDateFromMilitaryTime(peak?.start),
    endPeak: getDateFromMilitaryTime(peak?.end),
    tariffPeak: peak?.tariff ?? 0,
    startOffPeak: getDateFromMilitaryTime(offPeak?.start),
    endOffPeak: getDateFromMilitaryTime(offPeak?.end),
    tariffOffPeak: offPeak?.tariff ?? 0,
  };
}

export function serializeGridSettings(
  payload: Partial<GridSettings>,
): Partial<GridSettingsJson> {
  let consumptionRates: GridConsumptionRateJson[] = [];
  if (payload.rateType === RateTypes.OneRate) {
    consumptionRates.push({
      type: ConsumptionRateType.OffPeak,
      start: 0,
      end: 0,
      tariff: payload.tariff ?? 0,
    });
  } else {
    consumptionRates = [
      {
        type: ConsumptionRateType.Peak,
        start: getMilitaryTime(payload.startPeak) ?? 0,
        end: getMilitaryTime(payload.endPeak) ?? 0,
        tariff: payload.tariffPeak ?? 0,
      },
      {
        type: ConsumptionRateType.OffPeak,
        start: getMilitaryTime(payload.startOffPeak) ?? 0,
        end: getMilitaryTime(payload.endOffPeak) ?? 0,
        tariff: payload.tariffOffPeak ?? 0,
      },
    ];
  }
  return {
    consumptionRates,
  };
}
