import { Id, WithId, WithName } from '@model';

export interface SchedulerEventsList {
  assets: SchedulerAssetDetails[];
  events: SchedulerEventDetails[];
}

export interface SchedulerAssetDetails extends WithId, WithName {
  categoryId: Id;
}

export interface SchedulerEventDetails extends WithId {
  startDate: string;
  endDate: string;
  assetId: Id;
  action: SchedulerEventActionType;
  fields: SchedulerField[];
  rrule?: string;
  duration?: number;
}

export interface SchedulerField {
  name: EventActionFieldType;
  value: string;
}

export enum SchedulerEventActionType {
  EvPowerLimit = 'evPowerLimit',
  TargetSoC = 'targetSoC',
}

export enum EventActionFieldType {
  Limit = 'limit',
  SoC = 'soC',
}
