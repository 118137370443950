<mat-card>
  @if (config.title) {
    <div class="title-container">
      <div>{{ config.title }}</div>
      <etn-action [action]="closeAction$ | async"></etn-action>
    </div>
  }

  @if (config.showTitleContentDivider) {
    <etn-list-item-separator></etn-list-item-separator>
  }

  <div class="dialog-content" [ngStyle]="{ 'min-width.px': config.minWidth }">
    @if (config.fabIcon) {
      <div class="fab-icon">
        <etn-fab-icon
          [name]="config.fabIcon"
          [color]="config.fabIconColor"
        ></etn-fab-icon>
      </div>
    }
    @if (config.hint) {
      <div class="hint">{{ config.hint }}</div>
    }
    @if (config.component) {
      <ng-template
        [cdkPortalOutlet]="config.component"
        (attached)="onAttached($event)"
      ></ng-template>
    }
    @if (config.content) {
      <div class="content" [innerHTML]="content"></div>
    }
  </div>
  @if (config.confirmAction || config.cancelAction) {
    <div class="actions-container">
      <div class="left-column">
        @if (cancelAction$ | async) {
          <etn-action [action]="cancelAction$ | async"></etn-action>
        }
      </div>

      <div class="right-column">
        <etn-action [action]="confirmAction$ | async"></etn-action>
      </div>
    </div>
  }
</mat-card>
