import { Injectable } from '@angular/core';
import { filterNil } from '@core/rxjs/filter-nil';
import { Loading } from '@layout/loading';
import { Id } from '@model';
import {
  Device,
  DeviceSettings,
  Weather,
} from '@model/bems-cloud/bems/devices';
import { Nil } from '@model/nil';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { setLoading } from '../layout';
import { State } from '../store.state';
import {
  fetchCurrentDevice,
  fetchCurrentDeviceSettings,
  fetchCurrentDeviceSettingsSuccess,
} from './current-device.actions';
import {
  getCurrentDevice,
  getCurrentDeviceId,
  getCurrentDeviceName,
  getCurrentDeviceSettings,
  getCurrentDeviceSettingsCountry,
  getCurrentDeviceWeather,
  getDevices,
} from './current-device.selectors';

@Injectable({
  providedIn: 'root',
})
export class CurrentDeviceService {
  public constructor(private store: Store<State>) {}

  public device$: Observable<Device | Nil> =
    this.store.select(getCurrentDevice);

  public deviceRequired$: Observable<Device> = this.store
    .select(getCurrentDevice)
    .pipe(filterNil());

  public weather$: Observable<Weather | Nil> = this.store.select(
    getCurrentDeviceWeather,
  );

  public devices$: Observable<Device[] | Nil> = this.store.select(getDevices);

  public deviceName$: Observable<string | Nil> =
    this.store.select(getCurrentDeviceName);

  public deviceId$: Observable<Id | Nil> =
    this.store.select(getCurrentDeviceId);

  public deviceIdRequired$: Observable<Id> = this.deviceId$.pipe(filterNil());

  public deviceSettings$: Observable<DeviceSettings | Nil> = this.store.select(
    getCurrentDeviceSettings,
  );

  public deviceSettingsRequired$: Observable<DeviceSettings> = this.store
    .select(getCurrentDeviceSettings)
    .pipe(filterNil());

  public country$: Observable<string | Nil> = this.store.select(
    getCurrentDeviceSettingsCountry,
  );

  public fetchCurrentDeviceSettings(id: Id): void {
    this.store.dispatch(fetchCurrentDeviceSettings({ id }));
  }

  public updateCurrentDeviceSettings(settings: DeviceSettings): void {
    this.store.dispatch(fetchCurrentDeviceSettingsSuccess({ settings }));
  }

  public fetchCurrentDevice(id?: Id) {
    this.store.dispatch(setLoading({ loading: Loading.App }));
    this.store.dispatch(fetchCurrentDevice({ id }));
  }
}
