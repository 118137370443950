import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Id } from '@model';
import {
  AdvancedPeakShavingConfiguration,
  AdvancedPeakShavingHistory,
  BillingPeriod,
  Device,
  DeviceSettings,
  GridSettings,
  PeakShavingStatus,
  Weather,
} from '@model/bems-cloud/bems/devices';
import { Role } from '@model/bems-cloud/bems/roles';
import { Observable, of } from 'rxjs';

import { BemsApiService } from '../bems-api.service';
import { ApiErrorResponse, PaginatedResults } from '../bems-api.types';

import { Month } from '@model/bems-cloud/bems/internals';
import {
  AdvancedPeakShavingHistoryJson,
  AdvancedPeakShavingHistoryJsonSchema,
} from './io/advanced-peak-shaving-history.io';
import {
  DeviceSettingsJson,
  DeviceSettingsJsonSchema,
  serializeDeviceSettings,
} from './io/device-settings.io';
import {
  DeviceUserRoleJson,
  DeviceUserRoleJsonSchema,
} from './io/device-user-role.io';
import { DeviceJson, DeviceJsonSchema } from './io/device.io';
import {
  GridSettingsJson,
  GridSettingsJsonSchema,
  GridSettingsWithCurrencyJson,
  GridSettingsWithCurrencyJsonSchema,
  deserializeGridSettings,
  serializeGridSettings,
} from './io/grid-settings.io';
import { WeatherJson, WeatherJsonSchema } from './io/weather.io';

@Injectable({
  providedIn: 'root',
})
export class DevicesApiService extends BemsApiService {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getDevices(): Observable<PaginatedResults<Device> | ApiErrorResponse> {
    return this.list<DeviceJson, Device>({
      endpoint: 'devices',
      schema: DeviceJsonSchema,
      deserializer: (response) => {
        return response;
      },
    });
  }

  public getDevice(id: Id): Observable<Device | ApiErrorResponse> {
    return this.get<DeviceJson, Device>({
      endpoint: `devices/${id}`,
      schema: DeviceJsonSchema,
      deserializer: (response) => {
        return response;
      },
    });
  }

  public getDeviceSettings(
    id: Id,
  ): Observable<DeviceSettings | ApiErrorResponse> {
    return this.get<DeviceSettingsJson, DeviceSettings>({
      endpoint: `devices/${id}/settings`,
      schema: DeviceSettingsJsonSchema,
      deserializer: (response) => {
        return serializeDeviceSettings(response);
      },
    });
  }

  public getDeviceWeather(id: Id): Observable<Weather | ApiErrorResponse> {
    return this.get<WeatherJson, Weather>({
      endpoint: `devices/${id}/weather`,
      schema: WeatherJsonSchema,
      deserializer: (response) => {
        return response;
      },
    });
  }

  public saveDeviceSettings(
    id: Id,
    deviceSettings: Partial<DeviceSettings>,
  ): Observable<DeviceSettings | ApiErrorResponse> {
    return this.patch<
      Partial<DeviceSettings>,
      Partial<DeviceSettingsJson>,
      DeviceSettings,
      DeviceSettingsJson
    >({
      endpoint: `devices/${id}/settings`,
      payload: deviceSettings,
      responseSchema: DeviceSettingsJsonSchema,
      payloadSerializer: (payload: Partial<DeviceSettings>) => {
        return {
          deviceName: payload.name,
          countryId: payload.countryId,
          currencyId: payload.currencyId,
          postalCodeId: payload.postalCodeId,
          systemGoal: payload.systemGoal,
        };
      },
      responseDeserializer: (response: DeviceSettingsJson) => {
        return serializeDeviceSettings(response);
      },
    });
  }

  public getGridSettings(id: Id): Observable<GridSettings | ApiErrorResponse> {
    return this.get<GridSettingsWithCurrencyJson, GridSettings>({
      endpoint: `devices/${id}/settings/grid/consumption-rates`,
      schema: GridSettingsWithCurrencyJsonSchema,
      deserializer: (model) => {
        return deserializeGridSettings(model);
      },
    });
  }

  public saveGridSettings(
    id: Id,
    settings: Partial<GridSettings>,
  ): Observable<GridSettings | ApiErrorResponse> {
    return this.put<
      Partial<GridSettings>,
      Partial<GridSettingsJson>,
      GridSettings,
      GridSettingsJson
    >({
      endpoint: `devices/${id}/settings/grid/consumption-rates`,
      payload: settings,
      responseSchema: GridSettingsJsonSchema,
      payloadSerializer: (payload: Partial<GridSettings>) => {
        return serializeGridSettings(payload);
      },
      responseDeserializer: (model: GridSettingsJson) => {
        return deserializeGridSettings(model);
      },
    });
  }

  public getAdvancedPeakShavingHistory(
    id: Id,
  ): Observable<AdvancedPeakShavingHistory | ApiErrorResponse> {
    return this.get<AdvancedPeakShavingHistoryJson, AdvancedPeakShavingHistory>(
      {
        endpoint: `devices/${id}/settings/grid/consumption-rates`,
        schema: AdvancedPeakShavingHistoryJsonSchema,
        deserializer: (model) => {
          return model;
        },
      },
    );
  }

  public getAdvancedPeakShavingConfiguration(
    id: Id,
  ): Observable<AdvancedPeakShavingConfiguration | ApiErrorResponse> {
    return of({
      peakShavingEnabled: true,
      peakShavingStatus: PeakShavingStatus.Active,
      billingPeriod: BillingPeriod.Yearly,
      monthlyConfiguration: [
        {
          year: 2024,
          month: Month.January,
          limit: 50,
          read: 48,
          unit: 'kW',
          closed: true,
        },
        {
          year: 2024,
          month: Month.February,
          limit: 60,
          read: 55,
          unit: 'kW',
          closed: true,
        },
        {
          year: 2024,
          month: Month.March,
          limit: 61,
          read: 53,
          unit: 'kW',
          closed: false,
        },
        {
          year: 2025,
          month: Month.January,
          limit: 33,
          unit: 'kW',
          closed: false,
        },
      ],
      yearlyConfiguration: [
        {
          year: 2024,
          limit: 50,
          read: 55,
          unit: 'kW',
          closed: false,
        },
        {
          year: 2025,
          limit: 100,
          unit: 'kW',
          closed: false,
        },
      ],
    });
    // return this.get<
    //   AdvancedPeakShavingConfigurationJson,
    //   AdvancedPeakShavingConfiguration
    // >({
    //   endpoint: `devices/${id}/settings/grid/consumption-rates`,
    //   schema: AdvancedPeakShavingConfigurationJsonSchema,
    //   deserializer: (model) => {
    //     return model;
    //   },
    // });
  }

  public getUserRole(id: Id): Observable<Role | ApiErrorResponse> {
    return this.get<DeviceUserRoleJson, Role>({
      endpoint: `devices/${id}/role`,
      deserializer: (response) => {
        return response.role;
      },
      schema: DeviceUserRoleJsonSchema,
    });
  }
}
