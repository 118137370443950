export type TooltipPosition = 'right' | 'left' | 'top' | 'bottom';

export type OriginXPosition = 'end' | 'start' | 'center';

export type OriginYPosition = 'top' | 'bottom' | 'center';

export interface OriginOverlayPosition {
  originX: OriginXPosition;
  originY: OriginYPosition;
  overlayX: OriginXPosition;
  overlayY: OriginYPosition;
}

export type TooltipOriginPosition = Record<
  TooltipPosition,
  OriginOverlayPosition
>;
