import { uniqueId } from 'lodash-es';

import { Action, ActionColor } from './action.types';

export function makeAccentAction(action: Partial<Action>): Action {
  return {
    id: action.id || uniqueId(),
    ...action,
    raised: true,
    color: ActionColor.Accent,
  };
}

export function makeWarnAction(action: Partial<Action>): Action {
  return {
    id: action.id || uniqueId(),
    ...action,
    raised: true,
    color: ActionColor.Warn,
  };
}
