<div
  class="container"
  [style.display]="state.value === 'inactive' ? 'none' : ''"
>
  @if (iconName) {
    <div class="icon">
      <etn-icon [name]="iconName" [size]="iconSize"></etn-icon>
    </div>
  }
  <div class="body">
    @if (title) {
      <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
    }
    @if (message && options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>
    }
    @if (message && !options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
    }
  </div>
  @if (action) {
    <div class="action">
      <etn-action [action]="action"></etn-action>
    </div>
  }
</div>
@if (options.progressBar) {
  <div>
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
}
