export enum ChartDataFieldUnit {
  GigaWatt = 'GW',
  GigaWattHour = 'GWh',
  KiloWatt = 'kW',
  KiloWattHour = 'kWh',
  MegaWatt = 'MW',
  MegaWattHour = 'MWh',
  Watt = 'W',
  WattHour = 'Wh',
  Percentage = '%',
}
