import { Nil } from '@model';
import { isNil } from 'lodash-es';

import { EnergyPointProperty } from './energy-point-property';
import { EnergyPointConnection } from './enums/energy-point-connection';
import { EnergyPointType } from './enums/energy-point-type';
import { EnergyPointUnit } from './enums/energy-point-unit';

export interface EnergyPoint {
  node: EnergyPointType;
  properties: EnergyPointProperty[];
  type: EnergyPointConnection;
}

export function getPower(energyPoint: EnergyPoint): EnergyPointProperty | Nil {
  return getProperty(energyPoint, 'power');
}

export function getPowerValue(energyPoint: EnergyPoint): number | Nil {
  return getPropertyValue(energyPoint, 'power');
}

export function getPowerUnit(energyPoint: EnergyPoint): EnergyPointUnit | Nil {
  return getPropertyUnit(energyPoint, 'power');
}

export function getInstalledValue(energyPoint: EnergyPoint): number | Nil {
  return getPropertyValue(energyPoint, 'installed');
}

export function getCriticalValue(energyPoint: EnergyPoint): number | Nil {
  return getPropertyValue(energyPoint, 'critical');
}

export function getSocValue(energyPoint: EnergyPoint): number | Nil {
  return getPropertyValue(energyPoint, 'soc');
}
export function getSocUnit(energyPoint: EnergyPoint): EnergyPointUnit | Nil {
  return getPropertyUnit(energyPoint, 'soc');
}

export function getProperty(
  energyPoint: EnergyPoint,
  key: string,
): EnergyPointProperty | undefined {
  return energyPoint.properties.find((property) => {
    return property.key.endsWith(key);
  });
}

export function getPropertyValue(
  energyPoint: EnergyPoint,
  key: string,
): number | Nil {
  const property = getProperty(energyPoint, key);

  if (isNil(property)) {
    return undefined;
  }

  const value = Number(property.value);

  if (isNaN(value)) {
    return undefined;
  }

  return value;
}

export function getPropertyUnit(
  energyPoint: EnergyPoint,
  key: string,
): EnergyPointUnit | Nil {
  const property = getProperty(energyPoint, key);

  if (isNil(property)) {
    return undefined;
  }

  return property.unit;
}
