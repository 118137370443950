export enum AssetStatus {
  Available = 'Available',
  Charging = 'Charging',
  Consuming = 'Consuming',
  Disabled = 'Disabled',
  Discharging = 'Discharging',
  Enabled = 'Enabled',
  Error = 'Error',
  Fault = 'Fault',
  Faulted = 'Faulted',
  Finishing = 'Finishing',
  Idle = 'Idle',
  Initialising = 'Initialising',
  Injecting = 'Injecting',
  Offline = 'Offline',
  Online = 'Online',
  Preparing = 'Preparing',
  Reserved = 'Reserved',
  SuspendedEVSE = 'Suspended EVSE',
  Unavailable = 'Unavailable',
  Unknown = '',
}
