<router-outlet></router-outlet>

<!-- SAVE TRAP -->
<etn-save-trap></etn-save-trap>

<!-- COOKIE CONSENT -->
@if (cookiesNotAccepted$ | async) {
  <etn-cookie-consent
    [messages]="cookieConsentMessages"
    [userId]="currentUser()?.id"
    [consents]="cookieConsents"
    [position]="cookieConsentPosition"
    (accept)="onAcceptCookies($event, currentUser())"
  ></etn-cookie-consent>
}
