export enum ListItemDivider {
  Full = 'full',
  Partial = 'partial',
}

export enum TagColor {
  Blue = 'blue',
  Gray = 'gray',
  Green = 'green',
  LightBlue = 'light-blue',
  LightGray = 'light-gray',
  LightGreen = 'light-green',
  Orange = 'orange',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow',
}

export enum ListItemStatusColor {
  Primary = '#007bc1',
  Accent = '#0088f2',
  Warn = '#ca3c3d',
  Red = '#ca3c3d',
  Green = '#39b620',
  Gold = '#f0aa1f',
  Purple = '#983fef',
  Gray = '#727e84',
  Sky = '#e0eff8',
  Blue = '#007bc1',
  Yellow = '#f0cb2f',
}
