import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { Nil, WithId } from '@model';

import { SelectableItem } from '@ui/selectable-item';
import { IconSize } from '../icon';
import { IconName } from '../icon/icon.types';

export enum ActionColor {
  Accent = 'accent',
  AccentTransparent = 'accent-transparent',
  Light = 'light',
  LightTransparent = 'light-transparent',
  Primary = 'primary',
  PrimaryTransparent = 'primary-transparent',
  Transparent = 'transparent',
  Warn = 'warn',
  WarnTransparent = 'warn-transparent',
}

export enum ActionAlign {
  Left = 'left',
  Right = 'Right',
  Center = 'Center',
}

export type ActionTooltipPosition = TooltipPosition;

export interface Action<Ctx = any> extends WithId {
  align?: ActionAlign;
  altIcon?: IconName;
  altIconSize?: IconSize;
  basic?: boolean;
  border?: boolean;
  callback?: (context?: Ctx | Nil) => void;
  caption?: string;
  color?: ActionColor;
  counter?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: number;
  hoverColor?: ActionColor;
  icon?: IconName;
  iconSize?: IconSize;
  loading?: boolean;
  name?: string;
  preventDefault?: boolean;
  raised?: boolean;
  rounded?: boolean;
  tooltip?: string;
  tooltipPosition?: ActionTooltipPosition;
  uppercase?: boolean;
}

export interface ActionMenuItem extends SelectableItem {
  callback?: () => void;
}

export function makeMainAction(action: Action): Action {
  return { ...action, raised: true, color: ActionColor.Accent };
}
