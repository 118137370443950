import { Route } from '@angular/router';
import {
  AUTH_URL_SEGMENT,
  LOGIN_URL_SEGMENT,
} from '@core/bems-cloud/login-portal/const';

import { NOT_FOUND_URL_SEGMENT } from './bems/core/const';
import { AuthGuard } from './bems/core/guards';

export const appRoutes: Route[] = [
  {
    path: LOGIN_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./login-portal/login-portal-app.routes'))
        .loginAppRoutes;
    },
  },
  {
    path: AUTH_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./login-portal/login-portal-app.routes'))
        .loginAppAuthRoutes;
    },
  },
  {
    path: '',
    loadChildren: async () => {
      return (await import('./bems/bems-app.routes')).bemsAppRoutes;
    },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    redirectTo: NOT_FOUND_URL_SEGMENT,
  },
];
