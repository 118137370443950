import {
  Overlay,
  ScrollDispatcher,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  Optional,
  ViewContainerRef,
} from '@angular/core';

import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS,
  MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY,
  MatLegacyTooltip,
  MatLegacyTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { TooltipPosition } from './tooltip.types';
import { MAT_TOOLTIP_POSITIONS, TOOLTIP_POSITIONS } from './tooltip.utils';

@Directive({
  selector: '[etnTooltip]',
  exportAs: 'etnTooltip',
})
export class TooltipDirective extends MatLegacyTooltip {
  public constructor(
    overlay: Overlay,
    elementRef: ElementRef<HTMLElement>,
    scrollDispatcher: ScrollDispatcher,
    viewContainerRef: ViewContainerRef,
    ngZone: NgZone,
    platform: Platform,
    ariaDescriber: AriaDescriber,
    focusMonitor: FocusMonitor,
    @Inject(MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY)
    scrollStrategy: ScrollStrategy,
    dir: Directionality,
    @Optional()
    @Inject(MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS)
    defaultOptions: MatLegacyTooltipDefaultOptions,
    @Inject(DOCUMENT) document: any,
  ) {
    super(
      overlay,
      elementRef,
      scrollDispatcher,
      viewContainerRef,
      ngZone,
      platform,
      ariaDescriber,
      focusMonitor,
      scrollStrategy,
      dir,
      defaultOptions,
      document,
    );
  }

  @Input('etnTooltip')
  public get text(): string | Nil {
    return this.message;
  }

  public set text(value: string | Nil) {
    this.message = value ?? '';
  }

  @Input('etnTooltipPosition')
  public get location(): TooltipPosition {
    return TOOLTIP_POSITIONS[this.position];
  }

  public set location(value: TooltipPosition | Nil) {
    if (isNotNil(value)) {
      this.position = MAT_TOOLTIP_POSITIONS[value];
      this.tooltipClass = value;
    }
  }
}
