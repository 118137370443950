import { Nil } from '@model';

export interface GridSettings {
  rateType: RateTypes | Nil;
  tariff: number | Nil;
  startOffPeak: Date | Nil;
  endOffPeak: Date | Nil;
  tariffOffPeak: number | Nil;
  startPeak: Date | Nil;
  endPeak: Date | Nil;
  tariffPeak: number | Nil;
}

export enum RateTypes {
  OneRate = 'oneRate',
  TwoRates = 'twoRates',
}

export enum ConsumptionRateType {
  OffPeak = 'off-peak',
  Peak = 'peak',
}

export enum ThresholdGranularity {
  Yearly = 'yearly',
  Monthly = 'monthly',
}
