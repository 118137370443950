import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { AUTH_STATE_NAME, authReducer } from './auth';
import {
  CURRENT_DEVICE_STATE_NAME,
  currentDeviceReducer,
} from './current-device';
import { CURRENT_USER_STATE_NAME, currentUserReducer } from './current-user';
import { LAYOUT_REDUCER_STATE_NAME, layoutReducer } from './layout';
import {
  NOTIFICATIONS_STATE_NAME,
  notificationsReducer,
} from './notifications';
import { State } from './store.state';

export const reducers: ActionReducerMap<State> = {
  [AUTH_STATE_NAME]: authReducer,
  [CURRENT_DEVICE_STATE_NAME]: currentDeviceReducer,
  [CURRENT_USER_STATE_NAME]: currentUserReducer,
  [LAYOUT_REDUCER_STATE_NAME]: layoutReducer,
  [NOTIFICATIONS_STATE_NAME]: notificationsReducer,
};

function localStorageSyncReducer(
  reducer: ActionReducer<State>,
): ActionReducer<State> {
  return localStorageSync({
    keys: [AUTH_STATE_NAME, { [CURRENT_DEVICE_STATE_NAME]: ['device'] }],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];
