import { WithId, WithName } from '@model';

export interface CookieConsent extends WithId, WithName {
  accepted: boolean;
}

export interface CookieConsentMessages {
  title: string;
  explanation: string;
  link: string;
  linkUrl: string;
  consent: string;
  accept: string;
  settings: string;
  required: string;
}

export enum CookieConsentPosition {
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  TopLeft = 'top-left',
  TopRight = 'top-right',
}
