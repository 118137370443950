import { DOCUMENT } from '@angular/common';
import { Directive, Inject, LOCALE_ID } from '@angular/core';

import { getDirection } from './language-utils';

@Directive({
  selector: '[etnLanguage]',
})
export class LanguageDirective {
  public constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(LOCALE_ID) public language: string,
  ) {
    document.dir = getDirection(language);
    document.documentElement.lang = language;
  }
}
