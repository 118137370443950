import { Injectable } from '@angular/core';
import { ApiErrorMessage } from '@api/bems-cloud/login-portal/login-portal-api.types';
import { Login } from '@model/bems-cloud/login-portal/auth';
import { Nil } from '@model/nil';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '../store.state';
import { login, loginError, logout, refreshToken } from './auth.actions';
import { getAuth, getTokenExpirationDate } from './auth.selectors';
import { AuthState } from './auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) {}

  public auth$: Observable<AuthState | Nil> = this.store.select(getAuth);
  public tokenExpirationTime$: Observable<number | Nil> = this.store.select(
    getTokenExpirationDate,
  );

  public loginError$: Observable<ApiErrorMessage> = this.actions$.pipe(
    ofType(loginError),
  );

  public login(data: Login): void {
    this.store.dispatch(login(data));
  }

  public logout(): void {
    this.store.dispatch(logout());
  }

  public triggerRefershTokenMechanism(): void {
    this.store.dispatch(refreshToken());
  }
}
