export enum DataType {
  Grid = 'grid',
  OtherLoad = 'other_load',
  PV = 'pv',
  EvCharger = 'ev_charger',
  Storage = 'storage',
  SelfSufficiency = 'self_sufficiency',
  SelfGeneration = 'self_generation',
  StateOfCharge = 'soc',
}
