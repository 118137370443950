import { z } from 'zod';

export const RefreshTokenResponseDetailsJsonSchema = z.object({
  exp: z.string(),
  iat: z.string(),
});

export const RefreshTokenResponseJsonSchema = z.object({
  details: RefreshTokenResponseDetailsJsonSchema,
  response: z.string(),
});

export type RefreshTokenResponseJson = z.infer<
  typeof RefreshTokenResponseJsonSchema
>;
