import { daysInMonth } from '@core/date';
import { wattToGigaWatt, wattToKiloWatt, wattToMegaWatt } from '@core/math';
import { MESSAGES } from '@i18n/bems-cloud';
import { Nil } from '@model';
import {
  ChartDataFieldStep,
  ChartDataFieldUnit,
  ChartDataValue,
  ChartData as ModelChartData,
  SolarRadiation,
} from '@model/bems-cloud/bems/charts';
import {
  ChartBoxAnnotation,
  ChartColor,
  ChartData,
  ChartLineAnnotation,
  XaxisUnit,
} from '@ui/chart';
import { addDays } from 'date-fns';
import { isNil } from 'lodash-es';
import moment from 'moment-timezone';

const FIVE_MINUTES = 300000;

export type ValueTransformerFn = (
  value: number | Nil,
  currentValue: number,
) => number;

export const EMPTY_DATA_SET_GENERATORS: Record<
  XaxisUnit,
  (color: ChartColor, date: Date, timezone: string) => ChartData[]
> = {
  [XaxisUnit.Hour]: getEmptyDayData,
  [XaxisUnit.Day]: getEmptyBarMonthData,
  [XaxisUnit.Month]: getEmptyBarYearData,
};

// Generate "zero" data for each interval of five minutes because the backend does not return data for every interval
export function getEmptyDayData(
  color: ChartColor,
  date: Date,
  timezone: string,
): ChartData[] {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  const now = addOffset(new Date().getTime(), getTimezoneOffset(timezone));

  return new Array<ChartData>(288)
    .fill({
      color,
      label: 0,
      value: 0,
    })
    .map((item, index) => {
      const label = startOfDay.getTime() + index * FIVE_MINUTES;
      return {
        ...item,
        label,
        // if the label/date is in the past, set the value to 0, otherwise set it to NaN so it is not displayed
        value: label < now ? 0 : NaN,
      };
    });
}

// Generate "zero" data for each day of the month because the backend does not return data for every day
export function getEmptyBarMonthData(
  color: ChartColor,
  date: Date,
): ChartData[] {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  const now = new Date().getDate();

  const days = daysInMonth(startOfDay.getMonth() + 1, startOfDay.getFullYear());
  const firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  return new Array<ChartData>(days)
    .fill({
      color,
      label: 0,
      value: 0,
    })
    .map((item, index) => {
      return {
        ...item,
        label: addDays(firstDayOfTheMonth, index).getTime(),
        // if the label/date is in the past, set the value to 0, otherwise set it to NaN so it is not displayed
        value: index < now ? 0 : NaN,
      };
    });
}

// Generate "zero" data for each month of the year because the backend does not return data for every month
export function getEmptyBarYearData(
  color: ChartColor,
  date: Date,
): ChartData[] {
  const now = new Date().getMonth();

  return new Array<ChartData>(12)
    .fill({
      color,
      label: 0,
      value: 0,
    })
    .map((item, index) => {
      return {
        ...item,
        label: new Date(date.getFullYear(), index, 1).getTime(),
        // if the label/date is in the past, set the value to 0, otherwise set it to NaN so it is not displayed
        value: index < now ? 0 : NaN,
      };
    });
}

// Generate the chart vertical line annotations for the current time
export function getCurrentTimeVerticalLineAnnotation(
  label: string,
  step: ChartDataFieldStep,
  timezone: string,
): ChartLineAnnotation {
  const now = new Date();

  if (step === ChartDataFieldStep.FiveMinutes) {
    now.setMinutes(Math.floor(now.getMinutes() / 5) * 5);
  }

  return {
    label,
    value: addOffset(now.getTime(), getTimezoneOffset(timezone)),
  };
}

// Check if the date is the same as the second date or today if missing
export function isSameDate(date: Date, otherDate?: Date): boolean {
  const now = otherDate ?? new Date();
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}

export function mergeChartData(
  data: ChartData[],
  otherData: ChartDataValue[] | Nil,
  transformValue: ValueTransformerFn,
  predictive?: boolean,
): ChartData[] {
  if (isNil(otherData) || otherData.length === 0) {
    return data;
  }

  let cursorIndex = 0;

  return data.map((item) => {
    if (
      cursorIndex >= otherData.length ||
      item.label !== otherData[cursorIndex].x
    ) {
      return item;
    }

    const newData: ChartData = {
      ...item,
      label: otherData[cursorIndex].x,
      value: transformValue(otherData[cursorIndex].y, item.value),
      predictive: predictive ?? false,
      isNil:
        (item.isNil || item.isNil === undefined) &&
        isNil(otherData[cursorIndex].y),
    };

    cursorIndex++;

    return newData;
  });
}

// Generate the chart box annotations for the solar radiation
export function getSolarRadiationAnnotations(
  dataSet:
    | {
        solarRadiation: SolarRadiation | Nil;
        date: Date;
        timezone: string;
      }
    | Nil,
): ChartBoxAnnotation[] | Nil {
  if (
    isNil(dataSet) ||
    isNil(dataSet.solarRadiation) ||
    isNil(dataSet.timezone)
  ) {
    return undefined;
  }

  const startOfDay = new Date(dataSet.date);
  startOfDay.setHours(0, 0, 0, 0);

  const offset = getTimezoneOffset(dataSet.timezone);

  return [
    {
      minX: startOfDay.getTime(),
      maxX: addOffset(dataSet.solarRadiation.periodStart.getTime(), offset),
      color: ChartColor.Blue,
    },
    {
      minX: addOffset(dataSet.solarRadiation.periodStart.getTime(), offset),
      maxX: addOffset(dataSet.solarRadiation.periodEnd.getTime(), offset),
      color: ChartColor.Yellow,
    },
    {
      minX: addOffset(dataSet.solarRadiation.periodEnd.getTime(), offset),
      color: ChartColor.Blue,
    },
  ];
}

export function convertDataToTimezone(
  data: ChartDataValue[] | Nil,
  timezone: string,
): ChartDataValue[] | Nil {
  if (isNil(data)) {
    return undefined;
  }

  const offset = getTimezoneOffset(timezone);
  return data.map((value) => {
    return {
      ...value,
      x: addOffset(value.x, offset),
    };
  });
}

function getTimezoneOffset(timezone: string): number {
  const offset = moment().utcOffset();
  const timezoneOffset = moment.tz(timezone).utcOffset();
  return timezoneOffset - offset;
}

function addOffset(timestamp: number, offset: number): number {
  return timestamp + offset * 60 * 1000;
}

export function getWattValueTransformer(
  negative: boolean,
  unit: ChartDataFieldUnit,
): ValueTransformerFn {
  return (value: number | Nil) => {
    return negative
      ? DATA_CONVERTER[unit](-(value ?? 0))
      : DATA_CONVERTER[unit](value ?? 0);
  };
}

export function convertDateToTimezone(date: Date, timezone: string): Date {
  const offset = getTimezoneOffset(timezone);
  return new Date(addOffset(date.getTime(), offset));
}

export const DATA_CONVERTER: Record<
  ChartDataFieldUnit,
  (value: number) => number
> = {
  [ChartDataFieldUnit.GigaWatt]: wattToGigaWatt,
  [ChartDataFieldUnit.GigaWattHour]: wattToGigaWatt,
  [ChartDataFieldUnit.MegaWatt]: wattToMegaWatt,
  [ChartDataFieldUnit.MegaWattHour]: wattToMegaWatt,
  [ChartDataFieldUnit.KiloWatt]: wattToKiloWatt,
  [ChartDataFieldUnit.KiloWattHour]: wattToKiloWatt,
  [ChartDataFieldUnit.Watt]: defaultValue,
  [ChartDataFieldUnit.WattHour]: defaultValue,
  [ChartDataFieldUnit.Percentage]: defaultValue,
};

export const CHART_DATA_FIELD_UNIT_TRANSLATIONS: Record<
  ChartDataFieldUnit,
  string
> = {
  [ChartDataFieldUnit.GigaWatt]: MESSAGES.general.unit.energy.gWatt,
  [ChartDataFieldUnit.GigaWattHour]: MESSAGES.general.unit.energy.gWh,
  [ChartDataFieldUnit.MegaWatt]: MESSAGES.general.unit.energy.mWatt,
  [ChartDataFieldUnit.MegaWattHour]: MESSAGES.general.unit.energy.mWh,
  [ChartDataFieldUnit.KiloWatt]: MESSAGES.general.unit.energy.kWatt,
  [ChartDataFieldUnit.KiloWattHour]: MESSAGES.general.unit.energy.kWh,
  [ChartDataFieldUnit.Watt]: MESSAGES.general.unit.energy.watt,
  [ChartDataFieldUnit.WattHour]: MESSAGES.general.unit.energy.wh,
  [ChartDataFieldUnit.Percentage]: '%',
};

function defaultValue(value: number): number {
  return value;
}

/**
 * Find the greater and more used unit in chart data
 */
export function getChartUnit(
  loads: ModelChartData | Nil,
  sources: ModelChartData | Nil,
): ChartDataFieldUnit {
  const allUnits = (
    loads?.fields.map((filed) => {
      return filed.dataUnit;
    }) ?? []
  ).concat(
    sources?.fields.map((filed) => {
      return filed.dataUnit;
    }) ?? [],
  );

  // Create a map with indices for quick access to pattern indices
  const patternMap = new Map(
    CHART_UNIT_ORDER.map((item, index) => {
      return [item, index];
    }),
  );

  // Create an object to count the frequency of each element
  const frequencyMap = allUnits.reduce((acc, str) => {
    acc[str] = (acc[str] || 0) + 1;
    return acc;
  }, getDefaultUnitMap());

  // Find the maximum frequency
  const maxFrequency = Math.max(...Object.values(frequencyMap));

  // Gather all elements with the maximum frequency
  const mostFrequentElements = Object.keys(frequencyMap).filter((key) => {
    return frequencyMap[key as ChartDataFieldUnit] === maxFrequency;
  }) as ChartDataFieldUnit[];

  // Sort the array of strings according to the indices in the pattern
  mostFrequentElements.sort((a, b) => {
    return (patternMap.get(a) ?? Infinity) - (patternMap.get(b) ?? Infinity);
  });

  return mostFrequentElements[0] ?? ChartDataFieldUnit.Watt;
}

function getDefaultUnitMap(): Record<ChartDataFieldUnit, number> {
  return {
    [ChartDataFieldUnit.GigaWatt]: 0,
    [ChartDataFieldUnit.GigaWattHour]: 0,
    [ChartDataFieldUnit.MegaWatt]: 0,
    [ChartDataFieldUnit.MegaWattHour]: 0,
    [ChartDataFieldUnit.KiloWatt]: 0,
    [ChartDataFieldUnit.KiloWattHour]: 0,
    [ChartDataFieldUnit.Watt]: 0,
    [ChartDataFieldUnit.WattHour]: 0,
    [ChartDataFieldUnit.Percentage]: 0,
  };
}

/** list of unit ordered by size */
const CHART_UNIT_ORDER = [
  ChartDataFieldUnit.GigaWatt,
  ChartDataFieldUnit.GigaWattHour,
  ChartDataFieldUnit.MegaWatt,
  ChartDataFieldUnit.MegaWattHour,
  ChartDataFieldUnit.KiloWatt,
  ChartDataFieldUnit.KiloWattHour,
  ChartDataFieldUnit.Watt,
  ChartDataFieldUnit.WattHour,
  ChartDataFieldUnit.Percentage,
];
