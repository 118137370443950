import { getTimeInLocalFormat } from '@core/date';
import { isNotNil } from '@core/is-not-nil';
import { kwhToWatt, parseNumber, wattToKiloWatt } from '@core/math';
import { MESSAGES } from '@i18n/bems-cloud';
import { Id, Nil } from '@model';
import {
  AssetCategory,
  AssetCategoryType,
  ListAsset,
} from '@model/bems-cloud/bems/assets';
import {
  EventActionFieldType,
  SchedulerAssetDetails,
  SchedulerEventActionType,
  SchedulerEventDetails,
  SchedulerEventsList,
  SchedulerField,
} from '@model/bems-cloud/bems/scheduler';
import { Resource, ResourceEvent, TimeLineColor } from '@ui/time-line';
import { secondsToMilliseconds } from 'date-fns';
import { find, isNil } from 'lodash-es';
import moment from 'moment';

export const SCHEDULER_EVENT_ACTION_TYPE_TRANSLATIONS: Record<
  SchedulerEventActionType,
  string
> = {
  [SchedulerEventActionType.EvPowerLimit]:
    MESSAGES.general.scheduler.eventActionType.evPowerLimit,
  [SchedulerEventActionType.TargetSoC]:
    MESSAGES.general.scheduler.eventActionType.evPowerLimit,
};

export const SCHEDULER_EVENT_ACTION_TYPE_WITH_ASSET_TRANSLATIONS: Record<
  SchedulerEventActionType,
  (assetName: string | number) => string
> = {
  [SchedulerEventActionType.EvPowerLimit]:
    MESSAGES.general.scheduler.eventActionType.evPowerLimitAsset,
  [SchedulerEventActionType.TargetSoC]:
    MESSAGES.general.scheduler.eventActionType.targetSocAsset,
};

export const SCHEDULER_EVENT_ACTION_FIELD_TYPE_TRANSLATIONS: Record<
  EventActionFieldType,
  (amount: string | number) => string
> = {
  [EventActionFieldType.Limit]:
    MESSAGES.general.scheduler.eventActionFieldType.limitAmount,
  [EventActionFieldType.SoC]:
    MESSAGES.general.scheduler.eventActionFieldType.socAmount,
};

export const SCHEDULER_EVENT_ACTION_FIELD_VALUE_CONVERTER: Record<
  EventActionFieldType,
  (amount: string) => string
> = {
  [EventActionFieldType.Limit]: (amount) => {
    return wattToKiloWatt(parseNumber(amount)).toString();
  },
  [EventActionFieldType.SoC]: (amount) => {
    return amount;
  },
};

export const ASSET_CATEGORY_TYPE_COLOR: Record<
  AssetCategoryType,
  TimeLineColor
> = {
  [AssetCategoryType.EvCharger]: TimeLineColor.Orange,
  [AssetCategoryType.StorageUnit]: TimeLineColor.Green,
  [AssetCategoryType.SolarPanel]: TimeLineColor.Disabled,
  [AssetCategoryType.PowerMeter]: TimeLineColor.Disabled,
  [AssetCategoryType.BinaryLoad]: TimeLineColor.Disabled,
};

export const SCHEDULER_ACTION_TYPE_TO_FIELD_TYPE: Record<
  SchedulerEventActionType,
  EventActionFieldType
> = {
  [SchedulerEventActionType.EvPowerLimit]: EventActionFieldType.Limit,
  [SchedulerEventActionType.TargetSoC]: EventActionFieldType.SoC,
};

export function getResourceEvent(
  scheduler: SchedulerEventsList,
  categories: AssetCategory[],
  assetId?: Id | Nil,
) {
  return scheduler.events
    .filter((r) => {
      return isNil(assetId) || r.assetId === assetId;
    })
    .map((e) => {
      return mapResourceEvent(e, scheduler, categories);
    })
    .filter(isNotNil);
}

function mapResourceEvent(
  eventDetails: SchedulerEventDetails,
  scheduler: SchedulerEventsList,
  categories: AssetCategory[],
): ResourceEvent | Nil {
  const asset = find(scheduler.assets, { id: eventDetails.assetId });
  if (isNil(asset)) {
    return null;
  }

  const category = find(categories, { id: asset.categoryId });
  if (isNil(category)) {
    return null;
  }

  const selectionFields = eventDetails.fields
    .map((field) => {
      return SCHEDULER_EVENT_ACTION_FIELD_TYPE_TRANSLATIONS[field.name](
        SCHEDULER_EVENT_ACTION_FIELD_VALUE_CONVERTER[field.name](field.value),
      );
    })
    .join('\n');

  return {
    id: eventDetails.id.toString(),
    name: SCHEDULER_EVENT_ACTION_TYPE_TRANSLATIONS[eventDetails.action],
    description: asset.name,
    resourceId: eventDetails.assetId.toString(),
    color: ASSET_CATEGORY_TYPE_COLOR[category.type],
    tooltip:
      MESSAGES.general.scheduler.placeholder.tooltip(
        getTimeInLocalFormat(eventDetails.startDate),
        getTimeInLocalFormat(eventDetails.endDate),
      ) +
      '\n' +
      SCHEDULER_EVENT_ACTION_TYPE_WITH_ASSET_TRANSLATIONS[eventDetails.action](
        asset.name,
      ) +
      '\n' +
      selectionFields,
    duration: secondsToMilliseconds(eventDetails.duration || 0),
    rrule: `DTSTART:${moment().format('YYYYMMDD')}T000000Z\n${
      eventDetails.rrule || ''
    }`,
  };
}

export function getSchedulerAssets(
  scheduler: SchedulerEventsList,
  categories: AssetCategory[],
  assetId?: Id | Nil,
  controllableAsset?: ListAsset[] | Nil,
): Resource[] {
  return scheduler.assets
    .filter((r) => {
      return isNil(assetId) || r.id === assetId;
    })
    .filter((r) => {
      return (
        isNil(controllableAsset) ||
        controllableAsset.find((v) => {
          return v.id === r.id;
        })
      );
    })
    .map((a) => {
      return mapAssetToResource(categories, a);
    })
    .filter(isNotNil);
}

function mapAssetToResource(
  categories: AssetCategory[],
  asset: SchedulerAssetDetails,
): Resource | Nil {
  const category = find(categories, { id: asset.categoryId });
  if (isNil(category)) {
    return null;
  }
  return {
    id: asset.id.toString(),
    color: ASSET_CATEGORY_TYPE_COLOR[category.type],
    name: asset.name,
  };
}

export function getSchedulerFieldFromActionType(
  action: SchedulerEventActionType,
  value: number | undefined,
): SchedulerField {
  if (action === SchedulerEventActionType.EvPowerLimit) {
    return {
      name: SCHEDULER_ACTION_TYPE_TO_FIELD_TYPE[action],
      value: value ? kwhToWatt(value).toString() : '',
    };
  }
  return {
    name: SCHEDULER_ACTION_TYPE_TO_FIELD_TYPE[action],
    value: value ? value.toString() : '',
  };
}
