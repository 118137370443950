import {
  ApiErrorResponse,
  isApiErrorResponse,
} from '@api/bems-cloud/login-portal/login-portal-api.types';

import { Nil } from '@model';
import { Eula, Eulas } from '@model/bems-cloud/login-portal/internals';
import { first, isNil } from 'lodash-es';

export function getEula(eulas: Eulas | ApiErrorResponse | Nil): Eula | Nil {
  if (isNil(eulas)) {
    return undefined;
  }

  if (isApiErrorResponse(eulas) || eulas.eulas.length === 0) {
    return undefined;
  }

  const eula = first(eulas.eulas);

  if (isNil(eula)) {
    return undefined;
  }

  return eula;
}
