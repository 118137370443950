import { WithId } from './with-id';

export const COLLECTION_ADDRESSES_RELATION = 'collection:addresses';

export enum AddressType {
  Billing = 'Billing',
  Shipping = 'Shipping',
  Default = 'Default',
}

export interface Address extends WithId {
  city?: string;
  country?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  formattedAddress?: string;
  latitude?: number;
  longitude?: number;
  type: AddressType;
}
