import { Router } from '@angular/router';
import { ACCEPT_URL_SEGMENT, USERS_URL_SEGMENT } from '../const';

export function addNavigationToLink(
  router: Router,
  containerId: string,
  link: string[],
): void {
  document
    .getElementById(containerId)
    ?.getElementsByTagName('a')
    .item(0)
    ?.addEventListener('click', (event) => {
      event.preventDefault();
      router.navigate(link);
    });
}

export function isUserInvitationAcceptPage(path: string): boolean {
  const parts = path.split('/');

  // if we are in a local env the url has 5 parts
  // if we are in a dev/qa/prod env the url has 6 parts

  if (parts.length !== 5 && parts.length !== 6) {
    return false;
  }

  const userUrlSegmentIndex = parts.length === 6 ? 3 : 2;

  if (parts[userUrlSegmentIndex] != USERS_URL_SEGMENT) {
    return false;
  }

  const acceptUrlSegmentIndex = parts.length === 6 ? 5 : 4;

  if (parts[acceptUrlSegmentIndex] != ACCEPT_URL_SEGMENT) {
    return false;
  }

  return true;
}
