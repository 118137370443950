import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IpInfo } from '@model/ip-info';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpInfoApiService {
  public constructor(private httpClient: HttpClient) {}

  public getIpInfo(): Observable<IpInfo> {
    return this.httpClient.get<IpInfo>(
      'https://ipinfo.io?token=a1f9dcb70b8e83',
    );
  }
}
