import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IpStack } from '@model/ip-stack';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpStackApiService {
  public constructor(private httpClient: HttpClient) {}

  public getIpStack(ip: string): Observable<IpStack> {
    return this.httpClient.get<IpStack>(
      `http://api.ipstack.com/${ip}?access_key=8ce2f6634aea2afb1f4f784def01103c`,
    );
  }
}
