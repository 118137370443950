import { LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { TrackingModule } from '@core/tracking';
import { environment } from '@env/bems-cloud/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthEffects } from '@store/bems-cloud/auth';
import { CurrentDeviceEffects } from '@store/bems-cloud/current-device';
import { CurrentUserEffects } from '@store/bems-cloud/current-user';
import { NotificationsEffects } from '@store/bems-cloud/notifications';
import { StoreRouterEffects } from '@store/bems-cloud/router';
import { CookieConsentService } from '@ui/cookie-consent';
import { DialogModule } from '@ui/dialog';
import { FORM_FIELD_APPEARANCE, HIDE_REQUIRED_MARKER } from '@ui/form';
import {
  LanguageModule,
  LanguageService,
  MultiLanguageFallback,
} from '@ui/language';
import { provideLottieAnimation } from '@ui/lottie-animation';
import { NotificationComponent, NotificationService } from '@ui/notification';
import { provideSaveTrap } from '@ui/save-trap';
import { provideTimeLineKey } from '@ui/time-line';
import { ToastrModule } from 'ngx-toastr';
import { MESSAGES } from '../i18n';
import { apiInterceptor } from './api.interceptor';
import { appRoutes } from './app.routes';
import { GlobalErrorHandler } from './global-error.handler';
import { PathPreserveQueryLocationStrategy } from './login-portal/core/path-location-strategy';
import { metaReducers, reducers } from './store';
import { LayoutEffects } from './store/layout';

const multiLanguageFallback: MultiLanguageFallback = () => {
  return '';
};

export const appConfig: ApplicationConfig = {
  providers: [
    LanguageService,
    NotificationService,
    CookieConsentService,
    {
      provide: HIDE_REQUIRED_MARKER,
      useValue: true,
    },
    {
      provide: FORM_FIELD_APPEARANCE,
      useValue: 'outline',
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    provideAnimations(),
    provideLottieAnimation(),
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideHttpClient(withInterceptors([apiInterceptor])),
    provideSaveTrap(MESSAGES.app.saveTrap.confirm),
    importProvidersFrom(
      DialogModule,
      StoreModule.forRoot(reducers, { metaReducers }),
      StoreRouterConnectingModule.forRoot(),
      EffectsModule.forRoot([
        AuthEffects,
        CurrentDeviceEffects,
        CurrentUserEffects,
        LayoutEffects,
        NotificationsEffects,
        StoreRouterEffects,
      ]),
      ToastrModule.forRoot({
        toastComponent: NotificationComponent,
      }),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
      }),
      LanguageModule.forRoot(multiLanguageFallback),
      TrackingModule.forRoot(environment.googleTagManagerId),
    ),
    provideTimeLineKey(environment.schedulerLicenseKey),
  ],
};
