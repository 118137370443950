import { MESSAGES } from '@i18n/bems-cloud';
import { TemperatureUnit } from '@model';
import { DateFormat } from '@model/bems-cloud/bems/users';

export const DATE_FORMATS_TRANSLATIONS: Record<DateFormat, string> = {
  [DateFormat.Europe]: MESSAGES.general.dateFormat.europe,
  [DateFormat.US]: MESSAGES.general.dateFormat.us,
};

export const TEMPERATURES_TRANSLATIONS: Record<TemperatureUnit, string> = {
  [TemperatureUnit.Celsius]: MESSAGES.general.unit.temperature.celsius,
  [TemperatureUnit.Fahrenheit]: MESSAGES.general.unit.temperature.fahrenheit,
};
