export enum EnergyPointType {
  EvChargerNode = 'evChargerNode',
  GridNode = 'gridNode',
  Loads = 'loads',
  PvNode = 'pvNode',
  StorageNode = 'storageNode',
  BuildingPower = 'buildingPower',
  BuildingPowerConsumption = 'buildingPowerConsumption',
  BuildingPowerProduction = 'buildingPowerProduction',
}
