import { Id } from '@model';

import { NotificationSeverityLevel } from './enums/notification-severity-level';

export interface Notification {
  id: Id;
  severityLevel: NotificationSeverityLevel;
  message: string;
  createdAt: Date;
  isRead: boolean;
}
