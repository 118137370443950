import { z } from 'zod';

import { ValueTrendJsonSchema } from './value-trend.io';

export const ValueTrendsJsonSchema = z.object({
  gridNode: ValueTrendJsonSchema.optional(),
  pvNode: ValueTrendJsonSchema.optional(),
  selfSufficiencyNode: ValueTrendJsonSchema.optional(),
  evChargerNode: ValueTrendJsonSchema.optional(),
  energyCostsNode: ValueTrendJsonSchema.optional(),
});

export type ValueTrendsJson = z.infer<typeof ValueTrendsJsonSchema>;
