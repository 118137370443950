import { TrackingEvent } from './event';

export class TrackingServiceMock {
  public initialize(_: string): void {}

  public track(_: TrackingEvent): void {}

  public trackView(
    _view: string,
    _properties: Record<string, string> = {},
  ): void {}

  public trackAction(
    _view: string,
    _action: string,
    _properties: Record<string, string> = {},
  ): void {}
}
