import { IdJsonSchema } from '@api';
import { NotificationSeverityLevel } from '@model/bems-cloud/bems/notifications';
import { z } from 'zod';

export const NotificationJsonSchema = z.object({
  id: IdJsonSchema,
  severityLevel: z.nativeEnum(NotificationSeverityLevel),
  message: z.string(),
  createdAt: z.string(),
  isRead: z.boolean(),
});

export type NotificationJson = z.infer<typeof NotificationJsonSchema>;
