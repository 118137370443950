import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';

import { SaveTrapService } from './save-trap.service';

@Component({
  selector: 'etn-save-trap',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SaveTrapComponent {
  public constructor(private service: SaveTrapService) {}

  @HostListener('window:beforeunload')
  public canDeactivate(): boolean {
    return this.service.isPristine();
  }
}
