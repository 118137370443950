import { AssetMetric } from '../asset-metric';
import { TotalAndPhases } from '../total-and-phases';

export interface SolarPanelDetails {
  dcInputPower: AssetMetric;
  dcInputCurrent1: AssetMetric;
  dcInputVoltage1: AssetMetric;
  dcInputPower1: AssetMetric;
  dcInputCurrent2: AssetMetric;
  dcInputVoltage2: AssetMetric;
  dcInputPower2: AssetMetric;
  acOutputActivePower: TotalAndPhases;
  acOutputReactivePower: TotalAndPhases;
  acOutputApparentPower: TotalAndPhases;
  acOutputMaxPower: AssetMetric;
  acOutputMaxPowerSetpoint: AssetMetric;
  acOutputVoltage: TotalAndPhases;
  acOutputCurrent: TotalAndPhases;
  inverterTemperature: AssetMetric;
  installedCapacity: AssetMetric;
  numberOfPhases: 0;
}
