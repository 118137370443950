import { Component, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractFormModel, FormModel, Nil } from '@model';
import { isEqual, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, map } from 'rxjs/operators';

@Component({ template: '' })
export abstract class AbstractFormComponent<
  M,
  F,
  FM extends AbstractFormModel<F> = FormModel<F>,
> {
  public constructor(private formBuilder: FormBuilder) {}

  private _model: Partial<M> | Nil;

  @Input() public set model(model: Partial<M> | Nil) {
    this._model = model;
    if (model) {
      this.formGroup.setValue(this.serializeModel(model) as any);
      this.onModelChange(model, this.formGroup as any);
    }
  }
  @Input() public set readonly(readonly: boolean | Nil) {
    if (readonly) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  public formGroup = this.formBuilder.group(this.getControls(this.formBuilder));

  @Output() public valueChange: Observable<Partial<M>> =
    this.formGroup.valueChanges.pipe(
      map((formModel) => {
        return this.deserializeModel(formModel as F);
      }),
    );

  @Output()
  public valid: Observable<boolean> = this.formGroup.statusChanges.pipe(
    map(() => {
      return this.formGroup.valid;
    }),
    distinctUntilChanged(),
    delay(0),
  );

  @Output()
  public dirty: Observable<boolean> = this.formGroup.valueChanges.pipe(
    map(() => {
      if (this.formGroup.pristine) {
        return false;
      }
      return this.isChanged(this._model, this.formGroup.value as F);
    }),
    distinctUntilChanged(),
    delay(0),
  );

  public validateForm(): void {
    this.formGroup.setValue(this.formGroup.value as any);
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get model(): Partial<M> | Nil {
    return this._model;
  }

  public abstract getControls(fb: FormBuilder): FM;
  public abstract serializeModel(model: Partial<M>): F;
  public abstract deserializeModel(formModel: F): Partial<M>;

  protected onModelChange(
    _model: Partial<M> | Nil,
    _formGroup: FormGroup<FM>,
  ): void {}

  // Pass the model and the form model through serialize/deserialize to ensure that they are the same
  private isChanged(model: Partial<M> | Nil, formModel: F): boolean {
    if (isNil(model)) {
      return false;
    }
    const processedModel = this.deserializeModel(this.serializeModel(model));
    const processedFormModel = this.deserializeModel(formModel);
    return !isEqual(processedModel, processedFormModel);
  }
}
