import {
  EnvironmentProviders,
  InjectionToken,
  Provider,
  makeEnvironmentProviders,
} from '@angular/core';

import { SaveTrapService } from './save-trap.service';

export const SAVE_TRAP_MESSAGE = new InjectionToken<string>('');

export function provideSaveTrap(message: string): EnvironmentProviders {
  const providers: Provider[] = [
    {
      provide: SAVE_TRAP_MESSAGE,
      useValue: message,
    },
    SaveTrapService,
  ];
  return makeEnvironmentProviders(providers);
}
