import { DialogPosition } from './dialog.types';

export const ANIMATION_IN: Record<DialogPosition, string> = {
  [DialogPosition.Center]: 'animate__zoomIn',
  [DialogPosition.RightSide]: 'animate__slideInRight',
};

export const ANIMATION_OUT: Record<DialogPosition, string> = {
  [DialogPosition.Center]: 'animate__zoomOut',
  [DialogPosition.RightSide]: 'animate__slideOutRight',
};
