import { AssetMetric } from '../asset-metric';
import { TotalAndPhases } from '../total-and-phases';

export interface StorageUnitDetails {
  chargingStatus: number;
  activePower: AssetMetric;
  maxTemperature: AssetMetric;
  stateOfCharge: number;
  stringsOnline: number;
  stringsTotal: number;
  maxChargePower: AssetMetric;
  maxDischargePower: AssetMetric;
  energyChargeable: AssetMetric;
  energyDischargeable: AssetMetric;
  x1InputActivePower: TotalAndPhases;
  x1InputReactivePower: TotalAndPhases;
  x1InputApparentPower: TotalAndPhases;
  x1InputPowerFactor: TotalAndPhases;
  x1InputCurrent: TotalAndPhases;
  x1InputVoltage: TotalAndPhases;
  x1InputFrequency: AssetMetric;
  x3OutputActivePower: TotalAndPhases;
  x3OutputReactivePower: TotalAndPhases;
  x3OutputApparentPower: TotalAndPhases;
  x3OutputPowerFactor: TotalAndPhases;
  x3OutputCurrent: TotalAndPhases;
  x3OutputVoltage: TotalAndPhases;
  x3OutputFrequency: AssetMetric;
  firmwareVersion: string;
  numberOfPhases: number;
}
