export enum ErrorCode {
  DeviceNotFound = 4001,
  EULANotAccepted = 2022,
  AccessTokenExpired = 2030,
  EventOverlapForAssetID = 6721,
  AssetNotFound = 8019,
  InvalidInvitationID = 9002,
  PermissionNotFound = 9003,
  InvalidEmailAddress = 9008,
  CurrentUserIsSoleAdmin = 9015,
}
