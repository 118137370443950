import { WithId, WithName } from '@model';

import { ChartDataValue } from './chart-data-value';
import { ChartDataFieldStep } from './enums/chart-data-field-step';
import { ChartDataFieldUnit } from './enums/chart-data-field-unit';
import { DataType } from './enums/data-type';

export interface ChartDataField extends WithId, WithName {
  dataType: DataType;
  dataUnit: ChartDataFieldUnit;
  measured: ChartDataValue[];
  forecast?: ChartDataValue[];
  step: ChartDataFieldStep;
}
