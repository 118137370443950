<mat-card>
  @if (title) {
    <mat-card-header>
      @if (title) {
        <mat-card-title>{{ title }}</mat-card-title>
      }
      <div class="header-actions">
        @for (action of headerActions; track action.id) {
          <etn-action [action]="action"></etn-action>
        }
      </div>
      <ng-content select="[header-content]"></ng-content>
    </mat-card-header>
  }

  @if (image || imageSrc) {
    <div mat-card-image>
      <etn-image [name]="image" [src]="imageSrc" [height]="height"></etn-image>
    </div>
  }

  <mat-card-content>
    @if (title && showTitleDivider) {
      <mat-divider></mat-divider>
    }
    <ng-content></ng-content>
    @if (showActionsDivider) {
      <mat-divider></mat-divider>
    }
  </mat-card-content>

  @if (altActions.length > 0 || mainActions.length > 0) {
    <mat-card-actions [class.no-alt-actions]="altActions.length === 0">
      <div class="actions-container">
        @if (altActions.length > 0) {
          <div class="alt-actions">
            @for (action of altActions; track action.id) {
              <etn-action [action]="action"></etn-action>
            }
          </div>
        }

        <div class="main-actions">
          @for (action of mainActions; track action.id) {
            <etn-action [action]="action"></etn-action>
          }
        </div>
      </div>
    </mat-card-actions>
  }

  @if (loading) {
    <etn-progress-spinner></etn-progress-spinner>
  }
</mat-card>
