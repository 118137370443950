import { Nil } from '@model';
import { isNil } from 'lodash-es';

export function roundToTwoDecimals(value: number | Nil): string {
  if (isNil(value)) {
    return '';
  }

  const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
  return rounded.toFixed(2);
}

/**
 * Parse string, Nil or number into number
 * @param value any type value
 * @returns number
 */
export function parseNumber(value: number | string | Nil): number {
  if (isNil(value)) {
    return 0;
  }

  if (typeof value === 'string') {
    return parseFloat(value);
  }

  return value;
}
