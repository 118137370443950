export type Image =
  | 'welcome-and-setup-image'
  | 'rangext-44-66'
  | 'charging-station-fallback-image'
  | 'site-dashboard-image'
  | 'user-dashboard-image'
  | 'rangext-11-22'
  | 'user-invitation-error-image'
  | 'rangext-40-160'
  | 'car-charging-image'
  | 'home-two'
  | 'user-invitation-image'
  | 'private-one'
  | 'network-two';

export const IMAGE_MAPPING: Record<Image, string> = {
  'welcome-and-setup-image':
    'welcome-and-setup-image-5f738dfeb2bdf6689865fe092c52b48d.svg',
  'rangext-44-66': 'rangext-44-66-cba599964a80e6bf742cf0deb99f9402.svg',
  'charging-station-fallback-image':
    'charging-station-fallback-image-56f3daf014863e1fe639a6437a0aaa95.svg',
  'site-dashboard-image':
    'site-dashboard-image-7aeb194875b124febe09c65dcabd9960.svg',
  'user-dashboard-image':
    'user-dashboard-image-6d45ba63fa2624d280f69a15481faf92.svg',
  'rangext-11-22': 'rangext-11-22-3177fcd640dd921cb5d7a329ffb52dad.svg',
  'user-invitation-error-image':
    'user-invitation-error-image-8f9a004f39a322e8abe7f56836facc7d.svg',
  'rangext-40-160': 'rangext-40-160-7e2fcfaca635b0f8166cec8db62bbd68.svg',
  'car-charging-image':
    'car-charging-image-5213667207377a484a68ccb2a78c4dcf.svg',
  'home-two': 'home-two-ec1d2a7af670c2d3d27814de106c1739.svg',
  'user-invitation-image':
    'user-invitation-image-1a906e29992000f8d273a27e6629a3d9.svg',
  'private-one': 'private-one-ec1c35cc84808ee6872e38fa3327a52f.svg',
  'network-two': 'network-two-3d97b2d675d3b989ad88b4bd0322390e.svg',
};
