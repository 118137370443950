import { IdJsonSchema } from '@api';
import { z } from 'zod';

export const DeviceJsonSchema = z.object({
  asset: z.string().optional(),
  family: z.string().optional(),
  hardware: z.string().optional(),
  id: IdJsonSchema,
  macAddress: z.string().optional(),
  model: z.string().optional(),
  modelLongName: z.string().optional(),
  modelShortName: z.string().optional(),
  name: z.string(),
  profileId: z.string().optional(),
  role: z.string().optional(),
  serial: z.string().optional(),
  software: z.string().optional(),
  vendor: z.string().optional(),
});

export type DeviceJson = z.infer<typeof DeviceJsonSchema>;
