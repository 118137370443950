import {
  EnvironmentProviders,
  InjectionToken,
  Provider,
  makeEnvironmentProviders,
} from '@angular/core';

export const SCHEDULER_LICENSE_KEY = new InjectionToken<string>('');

export function provideTimeLineKey(licenseKey: string): EnvironmentProviders {
  const providers: Provider[] = [
    {
      provide: SCHEDULER_LICENSE_KEY,
      useValue: licenseKey,
    },
  ];
  return makeEnvironmentProviders(providers);
}
