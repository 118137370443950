import { createSelector } from '@ngrx/store';

import { State } from '../store.state';

const getCurrentDeviceState = (state: State) => {
  return state.currentDevice;
};

export const getCurrentDevice = createSelector(
  getCurrentDeviceState,
  ({ device }) => {
    return device;
  },
);

export const getCurrentDeviceWeather = createSelector(
  getCurrentDeviceState,
  ({ weather }) => {
    return weather;
  },
);

export const getDevices = createSelector(
  getCurrentDeviceState,
  ({ devices }) => {
    return devices;
  },
);

export const getCurrentDeviceName = createSelector(
  getCurrentDeviceState,
  ({ device }) => {
    return device?.name;
  },
);

export const getCurrentDeviceId = createSelector(
  getCurrentDeviceState,
  ({ device }) => {
    return device?.id;
  },
);

export const getCurrentDeviceSettings = createSelector(
  getCurrentDeviceState,
  ({ settings }) => {
    return settings;
  },
);

export const getCurrentDeviceSettingsCountry = createSelector(
  getCurrentDeviceState,
  ({ settings }) => {
    return settings?.country;
  },
);
