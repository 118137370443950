import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { LanguageDirective } from './language.directive';
import {
  LanguageService,
  MULTILANGUAGE_FALLBACK_MESSAGE,
} from './language.service';
import { MultiLanguageFallback } from './language.types';

@NgModule({
  declarations: [LanguageDirective],
  exports: [LanguageDirective],
  providers: [LanguageService],
  imports: [CommonModule],
})
export class LanguageModule {
  public static forRoot(
    fallback: MultiLanguageFallback,
  ): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [
        {
          provide: MULTILANGUAGE_FALLBACK_MESSAGE,
          useValue: fallback,
        },
      ],
    };
  }
}
