import { z } from 'zod';

export const LoginResponseDetailsJsonSchema = z.object({
  exp: z.string(),
  iat: z.string(),
});

export const LoginResponseJsonSchema = z.object({
  details: LoginResponseDetailsJsonSchema,
  response: z.string(),
});

export type LoginResponseJson = z.infer<typeof LoginResponseJsonSchema>;
