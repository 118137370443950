/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { environment } from '@env/bems-cloud/environment';
import { get, has } from 'lodash-es';
import {
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  take,
} from 'rxjs';

import { Nil } from '@model';
import { SaveTrapService } from '@ui/save-trap';
import { ErrorCode } from './bems/api/bems-api.utils';
import { NavigationService } from './bems/core/services';
import { checkUrlMatch } from './bems/core/utils';
import { ApiErrorMessage } from './login-portal/api';
import { AUTH_URL_SEGMENT, LOGIN_URL_SEGMENT } from './login-portal/core/const';
import { CurrentDeviceService } from './store/current-device';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(private injector: Injector) {
    const zone = this.injector.get(NgZone);
    this.errorSubject$
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((error) => {
        zone.run(() => {
          this.handleApiError(error);
        });
      });
  }

  private errorSubject$ = new Subject<any>();

  public handleError(error: any): void {
    this.errorSubject$.next(error);
  }

  private handleApiError(error: any): void {
    if (!environment.production) {
      console.error(error);
    }

    //we have to use injector here to get the service because of circular dependency
    const navigationService = this.injector.get(NavigationService);

    const httpErrorResponse = this.getHttpErrorResponse(error);

    if (isNotNil(httpErrorResponse) || this.isApiError(httpErrorResponse)) {
      if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
        this.handleUnauthorizedError(httpErrorResponse, navigationService);
      }
      if (httpErrorResponse.status === HttpStatusCode.Forbidden) {
        this.handleForbiddenError(httpErrorResponse, navigationService);
      }
      if (httpErrorResponse.status === HttpStatusCode.NotFound) {
        this.handleNotFoundError(httpErrorResponse, navigationService);
      }
      // 500 and 503 responses follow a different payload structure and for those
      // cases we only care about the status code.
      if (httpErrorResponse.status === HttpStatusCode.InternalServerError) {
        this.markFormAsPristine();
        navigationService.navigateToInternalServerError();
      }
      if (httpErrorResponse.status === HttpStatusCode.ServiceUnavailable) {
        this.markFormAsPristine();
        navigationService.navigateToServiceUnavailable();
      }
    }
  }

  private getHttpErrorResponse(error: any): HttpErrorResponse | Nil {
    if (error instanceof HttpErrorResponse) {
      return error;
    }
    if (has(error, 'rejection')) {
      const rejection = get(error, 'rejection');
      return rejection instanceof HttpErrorResponse ? rejection : undefined;
    }
    return undefined;
  }

  private isApiError(error: unknown): error is HttpErrorResponse {
    if (error instanceof HttpErrorResponse) {
      if (isNotNil(error.url)) {
        return error.url.includes(environment.urls.api);
      }
    }
    return false;
  }

  private handleNotFoundError(
    httpErrorResponse: HttpErrorResponse | Nil,
    navigationService: NavigationService,
  ): void {
    this.markFormAsPristine();

    const service = this.injector.get(CurrentDeviceService);
    const apiErrorMessage: ApiErrorMessage | Nil =
      httpErrorResponse?.error.errors?.[0]?.messages?.[0];

    // if we detect a "4001 Device Not Found" error we redirect the user to the
    // first available device or to the "no device" page if there are no devices
    if (apiErrorMessage?.code === ErrorCode.DeviceNotFound) {
      combineLatest([service.device$, service.devices$])
        .pipe(take(1))
        .subscribe(([device, devices]) => {
          const availableDevices = (devices ?? []).filter((d) => {
            return d.id !== device?.id;
          });
          if (availableDevices.length === 0) {
            navigationService.navigateToNoDevicePage();
          } else {
            navigationService.navigateToDashboard(availableDevices[0]);
          }
        });
    } else if (apiErrorMessage?.code === ErrorCode.AssetNotFound) {
      service.deviceRequired$.pipe(take(1)).subscribe((device) => {
        navigationService.navigateToAssets(device);
      });
    } else if (apiErrorMessage?.code === ErrorCode.PermissionNotFound) {
      service.deviceRequired$.pipe(take(1)).subscribe((device) => {
        navigationService.navigateToUsers(device);
      });
    } else {
      navigationService.navigateToNotFound();
    }
  }

  private handleUnauthorizedError(
    httpErrorResponse: HttpErrorResponse | Nil,
    navigationService: NavigationService,
  ): void {
    this.markFormAsPristine();

    if (checkUrlMatch([LOGIN_URL_SEGMENT, AUTH_URL_SEGMENT])) {
      return;
    }

    const apiErrorMessage: ApiErrorMessage | Nil =
      httpErrorResponse?.error.errors?.[0]?.messages?.[0];

    if (apiErrorMessage?.code === ErrorCode.AccessTokenExpired) {
      navigationService.navigateToSessionExpired();
    } else {
      navigationService.navigateToLogin();
    }
  }

  private handleForbiddenError(
    httpErrorResponse: HttpErrorResponse | Nil,
    navigationService: NavigationService,
  ): void {
    this.markFormAsPristine();

    const apiErrorMessage: ApiErrorMessage | Nil =
      httpErrorResponse?.error.errors?.[0]?.messages?.[0];
    if (apiErrorMessage?.code === ErrorCode.EULANotAccepted) {
      navigationService.navigateToEULAAccept();
    } else {
      navigationService.navigateToForbidden();
    }
  }

  private markFormAsPristine() {
    const trapService = this.injector.get(SaveTrapService);
    trapService.markAsPristine();
  }
}
