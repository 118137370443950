export enum FileUnit {
  BYTE = 'Byte',
  BYTES = 'Bytes',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
}

export interface FileSize {
  size: string;
  unit: string;
}
