import { Injectable } from '@angular/core';
import { AuthApiService } from '@api/bems-cloud/bems/auth';
import { isApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { AuthApiService as LoginPortalAuthApiService } from '@api/bems-cloud/login-portal/auth';
import { isApiErrorResponse as isLoginPortalApiErrorResponse } from '@api/bems-cloud/login-portal/login-portal-api.types';
import { LOGIN_URL_SEGMENT } from '@core/bems-cloud/bems/const';
import { isNotNil } from '@core/is-not-nil';
import { RefreshTokenResponse } from '@model/bems-cloud/bems/auth';
import { LoginResponse } from '@model/bems-cloud/login-portal/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { isError, isNil } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import {
  Observable,
  delay,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { NavigationService } from '@core/bems-cloud/bems/services';
import {
  login,
  loginError,
  loginSuccess,
  logout,
  logoutError,
  logoutSuccess,
  refreshToken,
  refreshTokenError,
  refreshTokenSuccess,
} from './auth.actions';
import {
  ACCESS_TOKEN_COOKIE,
  CSRF_ACCESS_TOKEN,
  FIVE_MINUTES,
  ONE_MINUTE,
} from './auth.const';
import { AuthService } from './auth.service';
import { AuthState } from './auth.state';

@Injectable()
export class AuthEffects {
  public constructor(
    private actions$: Actions,
    private authApiService: AuthApiService,
    private authService: AuthService,
    private cookieService: CookieService,
    private loginPortalAuthApiService: LoginPortalAuthApiService,
    private navigationService: NavigationService,
  ) {}

  public login$ = createEffect(() => {
    return this.loginEffect();
  });

  public loginSuccess$ = createEffect(
    () => {
      return this.loginSuccessEffect();
    },
    { dispatch: false },
  );

  public refreshToken$ = createEffect(() => {
    return this.refreshTokenEffect();
  });

  public logout$ = createEffect(() => {
    return this.logoutEffect();
  });

  public logoutSuccess$ = createEffect(
    () => {
      return this.logoutSuccessEffect();
    },
    { dispatch: false },
  );

  private loginEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(login),
      switchMap((data) => {
        return this.loginPortalAuthApiService.signIn(data).pipe(
          map((response) => {
            if (isLoginPortalApiErrorResponse(response)) {
              return loginError(response);
            }
            return loginSuccess({
              authState: this.getAuthState(response),
              redirectUrl: data.redirectUrl,
            });
          }),
        );
      }),
    );
  }

  private loginSuccessEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(loginSuccess),
      tap((data) => {
        this.navigationService.redirect(data.redirectUrl ?? '/');
      }),
    );
  }

  private refreshTokenEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(refreshToken),
      delay(ONE_MINUTE),
      withLatestFrom(this.authService.tokenExpirationTime$),
      switchMap(([_, timestamp]) => {
        if (isNil(timestamp)) {
          return of(new Error());
        }

        if (this.mustRefreshToken(timestamp)) {
          return this.authApiService.refreshToken();
        }

        return of(null);
      }),
      switchMap((response) => {
        if (isError(response) || isApiErrorResponse(response)) {
          return [refreshTokenError()];
        } else if (isNotNil(response)) {
          return [
            refreshTokenSuccess(this.getAuthState(response)),
            refreshToken(),
          ];
        }

        return [refreshToken()];
      }),
    );
  }

  private logoutEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(logout),
      switchMap(() => {
        return this.authApiService.signOut();
      }),
      map((response) => {
        if (isError(response)) {
          return logoutError();
        }
        return logoutSuccess();
      }),
    );
  }

  private logoutSuccessEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(logoutSuccess),
      tap(() => {
        this.cookieService.delete(CSRF_ACCESS_TOKEN);
        this.cookieService.delete(ACCESS_TOKEN_COOKIE);
        this.navigationService.redirect(LOGIN_URL_SEGMENT);
      }),
    );
  }

  private getAuthState(
    response: LoginResponse | RefreshTokenResponse,
  ): AuthState {
    return {
      exp: parseInt(response.details.exp, 10) * 1000,
      csrfToken: this.cookieService.get('csrf_access_token'),
    };
  }

  private mustRefreshToken(timestamp: number): boolean {
    const now = new Date().getTime();
    const remainingTime = timestamp - now;

    console.log('refresh token expiration date', new Date(timestamp));
    console.log('time remaining before refresh token', remainingTime);

    return remainingTime < FIVE_MINUTES;
  }
}
