export const ACCEPT_URL_SEGMENT = 'accept';
export const ACCOUNT_URL_SEGMENT = 'account';
export const APPLICATIONS_URL_PARAMETER = ':name';
export const APPLICATIONS_URL_SEGMENT = 'applications';
export const AUTH_URL_SEGMENT = 'authorize';
export const CREATE_URL_SEGMENT = 'create';
export const ERROR_URL_SEGMENT = 'error';
export const EULA_URL_SEGMENT = 'eula';
export const FLOW_URL_PARAMETER = ':flow';
export const FORGOT_PASSWORD_URL_SEGMENT = 'forgot-password';
export const LOGIN_URL_SEGMENT = 'login';
export const RESET_URL_SEGMENT = 'reset';
export const SUCCESS_URL_SEGMENT = 'success';
export const VERIFY_EMAIL_URL_SEGMENT = 'verify-email';

/* EULA */
export const EULA_PAGE_ROUTE_URL = EULA_URL_SEGMENT;
export const EULA_ACCEPT_PAGE_URL = `${EULA_URL_SEGMENT}/${ACCEPT_URL_SEGMENT}`;
export const EULA_PAGE_URL_SEGMENTS = [LOGIN_URL_SEGMENT, EULA_URL_SEGMENT];
export const EULA_ACCEPT_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  EULA_URL_SEGMENT,
  ACCEPT_URL_SEGMENT,
];

/* VERIFY EMAIL */
export const VERIFY_EMAIL_PAGE_ROUTE_URL = `${FLOW_URL_PARAMETER}/${VERIFY_EMAIL_URL_SEGMENT}`;

/* RESET PASSWORD */
export const RESET_PASSWORD_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  FORGOT_PASSWORD_URL_SEGMENT,
  RESET_URL_SEGMENT,
];
export const RESET_PASSWORD_VERIFY_EMAIL_PAGE_ROUTER_LINK = [
  FORGOT_PASSWORD_URL_SEGMENT,
  VERIFY_EMAIL_URL_SEGMENT,
];
export const RESET_PASSWORD_PAGE_ROUTE_URL = `${FORGOT_PASSWORD_URL_SEGMENT}/${RESET_URL_SEGMENT}`;
export const RESET_PASSWORD_SUCCESS_PAGE_ROUTE_URL = `${FORGOT_PASSWORD_URL_SEGMENT}/${RESET_URL_SEGMENT}/${SUCCESS_URL_SEGMENT}`;
export const RESET_PASSWORD_SUCCESS_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  FORGOT_PASSWORD_URL_SEGMENT,
  RESET_URL_SEGMENT,
  SUCCESS_URL_SEGMENT,
];
export const RESET_PASSWORD_ERROR_PAGE_ROUTE_URL = `${FORGOT_PASSWORD_URL_SEGMENT}/${RESET_URL_SEGMENT}/${ERROR_URL_SEGMENT}`;
export const RESET_PASSWORD_ERROR_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  FORGOT_PASSWORD_URL_SEGMENT,
  RESET_URL_SEGMENT,
  ERROR_URL_SEGMENT,
];

/* CREATE ACCOUNT */
export const CREATE_ACCOUNT_VERIFY_EMAIL_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  ACCOUNT_URL_SEGMENT,
  VERIFY_EMAIL_URL_SEGMENT,
];
export const CREATE_ACCOUNT_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  ACCOUNT_URL_SEGMENT,
  CREATE_URL_SEGMENT,
];
export const CREATE_ACCOUNT_SUCCESS_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  ACCOUNT_URL_SEGMENT,
  CREATE_URL_SEGMENT,
  SUCCESS_URL_SEGMENT,
];
export const CREATE_ACCOUNT_ERROR_PAGE_URL_SEGMENTS = [
  LOGIN_URL_SEGMENT,
  ACCOUNT_URL_SEGMENT,
  CREATE_URL_SEGMENT,
  ERROR_URL_SEGMENT,
];

export const CREATE_ACCOUNT_PAGE_ROUTE_URL = `${ACCOUNT_URL_SEGMENT}/${CREATE_URL_SEGMENT}`;
export const CREATE_ACCOUNT_SUCCESS_PAGE_ROUTE_URL = `${ACCOUNT_URL_SEGMENT}/${CREATE_URL_SEGMENT}/${SUCCESS_URL_SEGMENT}`;
export const CREATE_ACCOUNT_ERROR_PAGE_ROUTE_URL = `${ACCOUNT_URL_SEGMENT}/${CREATE_URL_SEGMENT}/${ERROR_URL_SEGMENT}`;

/* AUTH */
export const AUTHORIZE_APPLICATION_PAGE_URL_SEGMENTS = [
  AUTH_URL_SEGMENT,
  APPLICATIONS_URL_SEGMENT,
  APPLICATIONS_URL_PARAMETER,
];

export const AUTHORIZE_APPLICATION_PAGE_ROUTE_URL = `${APPLICATIONS_URL_SEGMENT}/${APPLICATIONS_URL_PARAMETER}`;
export const AUTHORIZE_APPLICATION_ERROR_PAGE_ROUTE_URL = `${APPLICATIONS_URL_SEGMENT}/${APPLICATIONS_URL_PARAMETER}/${ERROR_URL_SEGMENT}`;
export const AUTHORIZE_APPLICATION_SUCCESS_PAGE_ROUTE_URL = `${APPLICATIONS_URL_SEGMENT}/${APPLICATIONS_URL_PARAMETER}/${SUCCESS_URL_SEGMENT}`;
