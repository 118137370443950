import { DialogModule as CdkDialogModule } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';

import { DialogService } from './dialog.service';

@NgModule({
  imports: [CdkDialogModule],
  providers: [DialogService],
})
export class DialogModule {}
