import { Nil } from '@model';

import { AssetMetric } from '../asset-metric';
import { EVChargerStatus } from '../enums/ev-status';

export interface EvChargerUnitDetails {
  totalConsumption: AssetMetric;
  plugs: Plug[] | Nil;
}

export interface Plug {
  status: EVChargerStatus;
  currentChargingPower: AssetMetric;
  externalConstraint: AssetMetric;
  maxPower: AssetMetric;
  chargingStart: number;
  errorCode: string;
}
