import { IconName, IconSize } from '@ui/icon';

export enum FabIconColor {
  Accent = 'accent',
  Primary = 'primary',
  PrimaryContrast = 'primary-contrast',
  Grey = 'gray',
  Disabled = 'disabled',
  Warn = 'warn',
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  LightBlue = 'light-blue',
  Orange = 'orange',
}

export interface FabIcon {
  name: IconName;
  size: IconSize;
  color?: FabIconColor;
  rimSize: IconSize;
}
