import { MESSAGES } from '@i18n/bems-cloud';
import { Id, Nil } from '@model';
import {
  AssetCategoryType,
  AssetCategoryTypeId,
  AssetStatus,
} from '@model/bems-cloud/bems/assets';
import { EnergyPointType } from '@model/bems-cloud/bems/dashboard';
import { TagColor } from '@ui/list-item';
import {
  EV_CHARGER_URL_SEGMENT,
  POWER_METER_URL_SEGMENT,
  SOLAR_PANEL_URL_SEGMENT,
  STORAGE_UNIT_URL_SEGMENT,
} from '../const';

export const ASSET_CATEGORY_TYPE_TRANSLATIONS: Record<
  AssetCategoryType,
  string
> = {
  [AssetCategoryType.EvCharger]: MESSAGES.general.assetCategory.evCharger,
  [AssetCategoryType.PowerMeter]: MESSAGES.general.assetCategory.powerMeter,
  [AssetCategoryType.StorageUnit]: MESSAGES.general.assetCategory.storageUnit,
  [AssetCategoryType.SolarPanel]: MESSAGES.general.assetCategory.solarPanel,
  [AssetCategoryType.BinaryLoad]: MESSAGES.general.assetCategory.binaryLoad,
};

export const ASSET_STATUS_TRANSLATIONS: Record<AssetStatus, string> = {
  [AssetStatus.Available]: MESSAGES.general.assetStatus.available,
  [AssetStatus.Charging]: MESSAGES.general.assetStatus.charging,
  [AssetStatus.Consuming]: MESSAGES.general.assetStatus.consuming,
  [AssetStatus.Disabled]: MESSAGES.general.assetStatus.disabled,
  [AssetStatus.Discharging]: MESSAGES.general.assetStatus.discharging,
  [AssetStatus.Enabled]: MESSAGES.general.assetStatus.enabled,
  [AssetStatus.Error]: MESSAGES.general.assetStatus.error,
  [AssetStatus.Fault]: MESSAGES.general.assetStatus.faulted,
  [AssetStatus.Faulted]: MESSAGES.general.assetStatus.faulted,
  [AssetStatus.Finishing]: MESSAGES.general.assetStatus.finishing,
  [AssetStatus.Idle]: MESSAGES.general.assetStatus.idle,
  [AssetStatus.Initialising]: MESSAGES.general.assetStatus.initialising,
  [AssetStatus.Injecting]: MESSAGES.general.assetStatus.injecting,
  [AssetStatus.Offline]: MESSAGES.general.assetStatus.offline,
  [AssetStatus.Online]: MESSAGES.general.assetStatus.online,
  [AssetStatus.Preparing]: MESSAGES.general.assetStatus.preparing,
  [AssetStatus.Reserved]: MESSAGES.general.assetStatus.reserved,
  [AssetStatus.SuspendedEVSE]: MESSAGES.general.assetStatus.suspendedevse,
  [AssetStatus.Unavailable]: MESSAGES.general.assetStatus.unavailable,
  [AssetStatus.Unknown]: MESSAGES.general.assetStatus.unknown,
};

export const ASSET_STATUS_TAG_COLORS: Record<AssetStatus, TagColor> = {
  [AssetStatus.Available]: TagColor.LightGreen,
  [AssetStatus.Charging]: TagColor.Blue,
  [AssetStatus.Consuming]: TagColor.Blue,
  [AssetStatus.Disabled]: TagColor.Orange,
  [AssetStatus.Discharging]: TagColor.Blue,
  [AssetStatus.Enabled]: TagColor.LightGreen,
  [AssetStatus.Error]: TagColor.Red,
  [AssetStatus.Fault]: TagColor.Red,
  [AssetStatus.Faulted]: TagColor.Red,
  [AssetStatus.Finishing]: TagColor.Blue,
  [AssetStatus.Idle]: TagColor.Gray,
  [AssetStatus.Initialising]: TagColor.Blue,
  [AssetStatus.Injecting]: TagColor.Blue,
  [AssetStatus.Offline]: TagColor.Orange,
  [AssetStatus.Online]: TagColor.LightGreen,
  [AssetStatus.Preparing]: TagColor.Blue,
  [AssetStatus.Reserved]: TagColor.Gray,
  [AssetStatus.SuspendedEVSE]: TagColor.LightBlue,
  [AssetStatus.Unavailable]: TagColor.Orange,
  [AssetStatus.Unknown]: TagColor.Orange,
};

export const STORAGE_UNIT_CHARGING_STATUS: Record<number, AssetStatus> = {
  3: AssetStatus.Discharging,
  4: AssetStatus.Charging,
  6: AssetStatus.Idle,
};

export const ENERGY_POINT_TYPE_TO_ASSET_CATEGORY_TYPE_ID: Record<
  EnergyPointType,
  AssetCategoryTypeId | Nil
> = {
  [EnergyPointType.EvChargerNode]: AssetCategoryTypeId.EvCharger,
  [EnergyPointType.GridNode]: null,
  [EnergyPointType.Loads]: null,
  [EnergyPointType.PvNode]: AssetCategoryTypeId.SolarPanel,
  [EnergyPointType.StorageNode]: AssetCategoryTypeId.StorageUnit,
  [EnergyPointType.BuildingPower]: null,
  [EnergyPointType.BuildingPowerConsumption]: null,
  [EnergyPointType.BuildingPowerProduction]: null,
};

export const ASSET_CATEGORY_ID_URL_SEGMENT: Record<
  AssetCategoryTypeId | Id,
  string
> = {
  [AssetCategoryTypeId.EvCharger]: EV_CHARGER_URL_SEGMENT,
  [AssetCategoryTypeId.PowerMeter]: POWER_METER_URL_SEGMENT,
  [AssetCategoryTypeId.StorageUnit]: STORAGE_UNIT_URL_SEGMENT,
  [AssetCategoryTypeId.SolarPanel]: SOLAR_PANEL_URL_SEGMENT,
};
