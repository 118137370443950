@if (action) {
  <button
    type="button"
    mat-flat-button
    color
    [attr.data-test]="id"
    [color]="color"
    [disabled]="action.disabled"
    [ngClass]="{
      'mat-button': action.basic,
      'mat-flat-button': !action.raised && !action.basic,
      'mat-raised-button': action.raised && !action.basic,
      border: action.border,
      'icon-only': !action.name
    }"
    [ngStyle]="buttonStyle"
    [matTooltip]="action.tooltip || ''"
    (click)="onActionClick($event, action)"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    @if (action.icon) {
      <etn-icon
        [name]="action.icon"
        [size]="action.iconSize || defaultIconSize"
      ></etn-icon>
    }
    @if (action.name || action.caption) {
      <div class="action-label-container">
        @if (action.name && action.caption) {
          <div class="action-caption mat-caption etn-disabled-text">
            {{ action.caption }}
          </div>
        }
        <div
          class="action-label"
          [ngClass]="{
            'with-icon': action.icon,
            'with-caption': action.caption
          }"
          [ngStyle]="{ 'text-align': action.align || 'left' }"
        >
          {{ action.name }}
        </div>
      </div>
    }
    @if (action.altIcon) {
      <etn-icon
        [name]="action.altIcon"
        [size]="action.altIconSize || defaultIconSize"
        class="alt-action"
      ></etn-icon>
    }
    @if (action.loading) {
      <mat-progress-spinner
        [diameter]="24"
        mode="indeterminate"
      ></mat-progress-spinner>
    }
  </button>
}
@if (action?.counter) {
  <span class="counter">{{ action?.counter }}</span>
}
