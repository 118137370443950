import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyMenuModule as MatMenuModule,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { Nil } from '@model/nil';

import { isNotNil } from '@core/is-not-nil';
import { IconComponent } from '@ui/icon';
import { ActionComponent } from '../action.component';
import { Action, ActionMenuItem } from '../action.types';

@Component({
  selector: 'etn-action-menu',
  standalone: true,
  imports: [CommonModule, ActionComponent, MatMenuModule, IconComponent],
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent {
  @Input() public action: Action | Nil;
  @Input() public menu: ActionMenuItem[] = [];

  @Output() public menuItemClick = new EventEmitter<ActionMenuItem>();

  @ViewChild(MatMenuTrigger, { static: true }) public trigger:
    | MatMenuTrigger
    | Nil;

  public onClick(item: ActionMenuItem) {
    if (isNotNil(item.callback)) {
      item.callback();
    }
    this.menuItemClick.emit(item);
  }
}
