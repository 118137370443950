import { MultiLanguage } from './multi-language';

export enum LanguageCode {
  French = 'fr',
  English = 'en',
  German = 'de',
  Italian = 'it',
}

export function makeLanguageRecord(en: string): MultiLanguage {
  return { [LanguageCode.English]: en };
}
