import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Nil } from '@model';
import {
  Login,
  LoginResponse,
  ResetPassword,
  SignUp,
} from '@model/bems-cloud/login-portal/auth';
import { RecoverPassword } from '@model/bems-cloud/login-portal/auth/recovery';
import { Observable } from 'rxjs';

import { LoginPortalApiService } from '../login-portal-api.service';
import { ApiErrorResponse } from '../login-portal-api.types';
import { LoginJson, LoginResponseJson, LoginResponseJsonSchema } from './io';
import { RecoverPasswordJson } from './io/recover-password.io';
import { ResetPasswordJson } from './io/reset-password.io';
import { SignUpJson } from './io/signup.io';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends LoginPortalApiService {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public signIn(login: Login): Observable<LoginResponse | ApiErrorResponse> {
    return this.post<Login, LoginJson, LoginResponse, LoginResponseJson>({
      endpoint: 'auth/sign-in',
      payload: login,
      payloadDeserializer: (payload) => {
        return payload;
      },
      responseSchema: LoginResponseJsonSchema,
      responseSerializer: (response) => {
        return response;
      },
    });
  }

  public signUp(signup: SignUp): Observable<ApiErrorResponse | Nil> {
    return this.postWithoutResponse<SignUp, SignUpJson>({
      endpoint: 'account/registration/signup',
      payload: signup,
      payloadDeserializer: (payload) => {
        return payload;
      },
    });
  }

  public recoverPassword(
    recoverPassword: RecoverPassword,
  ): Observable<ApiErrorResponse | undefined> {
    return this.postWithoutResponse<RecoverPassword, RecoverPasswordJson>({
      endpoint: 'account/auth/recovery',
      payload: recoverPassword,
      payloadDeserializer: (payload) => {
        return payload;
      },
    });
  }

  public resetPassword(
    resetPassword: ResetPassword,
  ): Observable<ApiErrorResponse | undefined> {
    return this.postWithoutResponse<ResetPassword, ResetPasswordJson>({
      endpoint: 'account/auth/password',
      payload: resetPassword,
      payloadDeserializer: (payload) => {
        return payload;
      },
    });
  }
}
