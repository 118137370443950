import { Injectable } from '@angular/core';
import { Role, isAdministrator, isReader } from '@model/bems-cloud/bems/roles';
import { User } from '@model/bems-cloud/bems/users';
import { Nil } from '@model/nil';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { State } from '../store.state';
import {
  acceptEula,
  fetchCurrentUser,
  fetchCurrentUserSuccess,
} from './current-user.actions';
import { getCurrentUser, getCurrentUserRole } from './current-user.selectors';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public constructor(private store: Store<State>) {}

  public currentUser$: Observable<User | Nil> =
    this.store.select(getCurrentUser);

  public currentUserRole$: Observable<Role | Nil> =
    this.store.select(getCurrentUserRole);

  public isAdministrator$ = this.currentUserRole$.pipe(map(isAdministrator));

  public isReadOnly$ = this.currentUserRole$.pipe(map(isReader));

  public fetchCurrentUser(): void {
    this.store.dispatch(fetchCurrentUser());
  }

  public updateCurrentUser(user: User): void {
    this.store.dispatch(fetchCurrentUserSuccess({ user }));
  }

  public acceptEula(version: string) {
    this.store.dispatch(acceptEula({ version }));
  }
}
