import { Nil } from '@model';
import { createReducer, on } from '@ngrx/store';

import {
  fetchCurrentDeviceSettingsSuccess,
  fetchCurrentDeviceSuccess,
  fetchCurrentDeviceWeatherSuccess,
  noDeviceError,
} from './current-device.actions';
import { CurrentDeviceState } from './current-device.state';

export const CURRENT_DEVICE_STATE_NAME = 'currentDevice';

const INITIAL_STATE: CurrentDeviceState | Nil = {
  device: undefined,
  devices: undefined,
  weather: undefined,
  settings: undefined,
};

export const currentDeviceReducer = createReducer<CurrentDeviceState>(
  INITIAL_STATE,
  on(fetchCurrentDeviceSuccess, (state, { device, devices }) => {
    return { ...state, device, devices };
  }),
  on(fetchCurrentDeviceWeatherSuccess, (state, { weather }) => {
    return { ...state, weather };
  }),
  on(fetchCurrentDeviceSettingsSuccess, (state, { settings }) => {
    return { ...state, settings };
  }),
  on(noDeviceError, (state) => {
    return { ...state, device: undefined, devices: [] };
  }),
);
