import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ListItemTagModule } from '@brightlayer-ui/angular-components';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

import { TagColor } from '../list-item.types';

@Component({
  selector: 'etn-list-item-tag',
  templateUrl: './list-item-tag.component.html',
  styleUrls: ['./list-item-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ListItemTagModule],
})
export class ListItemTagComponent {
  @HostBinding('class')
  @Input()
  public color: TagColor | Nil;

  @Input() public set label(label: string | number | Nil) {
    this.text = isNil(label) ? ' ' : `${label}`;
  }

  public text = ' ';
}
