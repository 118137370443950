import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'etn-list-item-separator',
  templateUrl: './list-item-separator.component.html',
  styleUrls: ['./list-item-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ListItemSeparatorComponent {}
