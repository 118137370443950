import { createSelector } from '@ngrx/store';

import { State } from '../store.state';

const getAuthState = (state: State) => {
  return state.auth;
};

export const getAuth = createSelector(getAuthState, (auth) => {
  return auth;
});

export const getTokenExpirationDate = createSelector(getAuthState, (auth) => {
  return auth.exp;
});
