export interface AdvancedPeakShavingHistory {
  monthlyHistory: MonthlyHistory[];
  yearlyHistory: YearlyHistory[];
}

export interface MonthlyHistory {
  year: number;
  month: number;
  read: number;
  unit: string;
}

export interface YearlyHistory {
  year: number;
  read: number;
  unit: string;
}
