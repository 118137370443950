import { HttpHeaders } from '@angular/common/http';
import { has } from 'lodash-es';
import { z } from 'zod';

export interface ApiErrorResponse {
  code: number;
  errors: ApiError[] | null;
  message?: string;
}

export interface ApiError {
  field: string;
  messages: ApiErrorMessage[];
}

export interface ApiErrorMessage {
  code: number;
  message: string;
  description: string;
}

export interface PaginatedResults<T> {
  size: number;
  total: number;
  items: T[];
}

export interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
}

export const PaginatedResultsJsonSchemaFactory = <Schema extends z.Schema>(
  schema: Schema,
) => {
  return z.object({
    size: z.number(),
    total: z.number(),
    next: z.string(),
    previous: z.string(),
    items: z.array(schema).or(z.null()),
  });
};

export const PaginatedSelectableResultsJsonSchemaFactory = <
  Schema extends z.Schema,
>(
  schema: Schema,
) => {
  return z.object({
    size: z.number(),
    total: z.number(),
    next: z.string(),
    prev: z.string(),
    selectedPos: z.number(),
    items: z.array(schema).or(z.null()),
  });
};

export const AssetJsonSchemaFactory = <Schema extends z.Schema>(
  schema: Schema,
) => {
  return z.object({
    assetName: z.string(),
    timestamp: z.string(),
    isOnline: z.boolean(),
    noData: z.boolean(),
    assetCategory: z.number(),
    assetDetails: schema,
  });
};

export function isApiErrorResponse(
  response: unknown,
): response is ApiErrorResponse {
  return has(response, 'errors');
}
