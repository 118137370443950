import { IdJsonSchema } from '@api';
import { TemperatureUnit } from '@model';
import { DateFormat, LanguageCode, User } from '@model/bems-cloud/bems/users';
import { z } from 'zod';

export const UserJsonSchema = z.object({
  id: IdJsonSchema,
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  acceptedEula: z.string().optional(),
  language: z.nativeEnum(LanguageCode),
  temperatureUnit: z.nativeEnum(TemperatureUnit),
  dateFormat: z.nativeEnum(DateFormat),
});

export type UserJson = z.infer<typeof UserJsonSchema>;

export function deserializeUser(user: UserJson): User {
  return {
    ...user,
  };
}
