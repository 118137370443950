import { isNil } from 'lodash-es';

export interface ChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export function makeChangePassword(
  partial: Partial<ChangePassword>,
): ChangePassword {
  if (isNil(partial.confirmPassword)) {
    throw new Error('Missing organization property: confirmPassword');
  }

  if (isNil(partial.newPassword)) {
    throw new Error('Missing organization property: confirmPassword');
  }

  if (isNil(partial.oldPassword)) {
    throw new Error('Missing organization property: confirmPassword');
  }

  return {
    confirmPassword: partial.confirmPassword,
    newPassword: partial.newPassword,
    oldPassword: partial.oldPassword,
  };
}
