@if (!isBluiIcon && !isGmIcon) {
  <mat-icon
    [ngStyle]="styles"
    [etnTooltip]="tooltip"
    [etnTooltipPosition]="tooltipPosition"
  >
    {{ iconName }}
  </mat-icon>
}
@if (isBluiIcon) {
  <i
    [attr.class]="iconName"
    [etnTooltip]="tooltip"
    [etnTooltipPosition]="tooltipPosition"
    [ngStyle]="styles"
  ></i>
}
@if (isGmIcon) {
  <img [src]="'assets/' + iconName + '.svg'" [ngStyle]="styles" />
}
@if (counter) {
  <span class="counter">{{ counter }}</span>
}
