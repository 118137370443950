import { TemperatureUnit } from '@model';
import { WeatherCode } from '@model/bems-cloud/bems/devices';
import { z } from 'zod';

export const WeatherJsonSchema = z.object({
  temperature: z.number(),
  unit: z.nativeEnum(TemperatureUnit),
  weatherCode: z.nativeEnum(WeatherCode),
});

export type WeatherJson = z.infer<typeof WeatherJsonSchema>;
