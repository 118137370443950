import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { environment } from '@env/bems-cloud/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { ZodError, z } from 'zod';

import {
  ApiErrorResponse,
  PaginatedResults,
  PaginatedResultsJsonSchema,
} from './login-portal-api.types';

export abstract class LoginPortalApiService {
  public constructor(private httpClient: HttpClient) {}

  protected post<Payload, PayloadJson, Response, ResponseJson>({
    endpoint,
    payload,
    responseSchema,
    payloadDeserializer: deserializer,
    responseSerializer: serializer,
  }: {
    endpoint: string;
    payload: Payload;
    responseSchema: z.Schema<ResponseJson>;
    payloadDeserializer: (payload: Payload) => PayloadJson;
    responseSerializer: (response: ResponseJson) => Response;
  }): Observable<Response | ApiErrorResponse> {
    return this.httpClient
      .post<Response>(
        `${environment.urls.api}/${endpoint}`,
        deserializer(payload),
      )
      .pipe(
        map((response) => {
          return serializer(responseSchema.parse(response));
        }),
        catchError((response: HttpErrorResponse) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected get<Response, Model>({
    endpoint,
    schema,
    serializer,
  }: {
    endpoint: string;
    schema: z.Schema<Response>;
    serializer: (response: Response) => Model;
  }): Observable<Model | ApiErrorResponse> {
    return this.httpClient
      .get<Response>(`${environment.urls.api}/${endpoint}`)
      .pipe(
        map((response) => {
          return serializer(schema.parse(response));
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected postWithoutResponse<Payload, PayloadJson>({
    endpoint,
    payload,
    payloadDeserializer: deserializer,
  }: {
    endpoint: string;
    payload: Payload;
    payloadDeserializer: (payload: Payload) => PayloadJson;
  }): Observable<undefined | ApiErrorResponse> {
    return this.httpClient
      .post<undefined>(
        `${environment.urls.api}/${endpoint}`,
        deserializer(payload),
      )
      .pipe(
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  protected list<Response, Model>({
    endpoint,
    schema,
    serializer,
  }: {
    endpoint: string;
    schema: z.Schema<Response>;
    serializer: (response: Response) => Model;
  }): Observable<PaginatedResults<Model> | ApiErrorResponse> {
    return this.httpClient
      .get<unknown>(`${environment.urls.api}/${endpoint}`)
      .pipe(
        map((response) => {
          const results = PaginatedResultsJsonSchema(schema).parse(response);
          return {
            ...results,
            items: (results.items || []).map(serializer),
          };
        }),
        catchError((response: HttpErrorResponse | ZodError) => {
          return of(this.getApiErrorResponse(response));
        }),
      );
  }

  private getApiErrorResponse(
    response: HttpErrorResponse | ZodError,
  ): ApiErrorResponse {
    if (response instanceof ZodError) {
      console.error(response);
      throw new Error();
    }

    // If the response is a 500 or 503 we want to throw it so that the global
    // error handler can handle it.
    if (
      response.status === HttpStatusCode.InternalServerError ||
      response.status === HttpStatusCode.ServiceUnavailable
    ) {
      throw response;
    }

    return {
      code: response.status,
      errors: response.error.errors,
      message: response.error.messages,
    };
  }
}
