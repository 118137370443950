import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

export function deserializeDate(value: string): Date {
  return new Date(value);
}

export function deserializeOptionalDate(value: string | Nil): Date | undefined {
  if (isNil(value) || value.length === 0) {
    return undefined;
  }
  const date = new Date(value);
  if (date.getTime() === 0) {
    return undefined;
  }
  return date;
}

export function serializeOptionalDate(date: Date | Nil): string | undefined {
  if (isNil(date)) {
    return undefined;
  }
  return date.toISOString();
}

export function serializeDate(date: Date): string {
  return date.toISOString();
}

export function serializeNumber(value: number | Nil): string | null {
  if (isNotNil(value)) {
    return value.toString();
  }
  return null;
}

export function deserializeNumber(value: string | Nil): number | undefined {
  if (isNotNil(value) && value.length > 0) {
    return parseFloat(value);
  }
  return undefined;
}
