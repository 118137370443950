import { Brand } from './brand';

export enum LogoType {
  Primay = 'primary',
  Contrast = 'contrast',
}

export const LOGO_FOLDER = 'logos';

export const LOGO_MAPPING: Record<Brand, Record<LogoType, string>> = {
  neutral: {
    primary: 'primary-e7f6176b3493540e18121eb93271f4b6.svg',
    contrast: 'contrast-e7f6176b3493540e18121eb93271f4b6.svg',
  },
  valt: {
    primary: 'primary-e45ee3d26bde5ea39037d98740566e01.svg',
    contrast: 'contrast-822a3f4d79c99ff1c4260bd05caa6947.svg',
  },
  eaton: {
    primary: 'primary-aa6232be4d2ca2f6beb2d0129e73c209.svg',
    contrast: 'contrast-78bb4d8d82b6047201a2d8457eaa6ae0.svg',
  },
  residenza: {
    contrast: 'contrast-54df5d6bdec18a440d516561b0e25c7f.svg',
    primary: 'primary-269eb18dc57ce25737a9817aee89517e.svg',
  },
  vmotion: {
    contrast: 'contrast-e1f74c7b07eda1d1401ce6af60c65886.svg',
    primary: 'primary-35c585668903d4dd5fbfb5bc2bafc742.svg',
  },
  evpass: {
    contrast: 'contrast-92a7f933c4263712438af4fe9123007b.svg',
    primary: 'primary-155fe768a3a32b186adaaa43d15e3505.svg',
  },
  nisko: {
    primary: 'primary-af3ce8200ca5de67b5b1bc8304019c07.svg',
    contrast: 'contrast-5302d89aad696e8711950cc3836d646a.svg',
  },
  smotion: {
    contrast: 'contrast-39bde29f6dfc1b142f8ee6f49804b670.svg',
    primary: 'primary-39bde29f6dfc1b142f8ee6f49804b670.svg',
  },
  emoti: {
    primary: 'primary-8244c457a60901908775448af3c26424.svg',
    contrast: 'contrast-4b6a46ab1960e05ef3a243f1f1016d5e.svg',
  },
  greenmotion: {
    contrast: 'contrast-9b8123b787b5f997a409b8715d8b9ecd.svg',
    primary: 'primary-c8e5747701b41ec5465652523f1f97f2.svg',
  },
};

export const FAVICON_MAPPING: Record<Brand, string> = {
  neutral: 'favicon-d43ab7ac3f12fe21376ef1b9363257c4.png',
  vmotion: 'favicon-a37d29c12b469570bff7b151b73aaa7a.png',
  evpass: 'favicon-5ebcfe5efe43ab40fa98d5f7b7875a77.png',
  nisko: 'favicon-b2acbe968ebf6f8b2f1c7695450bc3f9.png',
  emoti: 'favicon-28bba661a30132db3289d14261e2f1a4.png',
  smotion: 'favicon-722dc7d5046b4ec88a3300803429d7ff.png',
  valt: 'favicon-603bd20aab6c92d52a76790a0becad35.png',
  eaton: 'favicon-051966b0054af6199cda04710d812301.png',
  greenmotion: 'favicon-467cd279236cdc1b8607229c33a6a995.png',
  residenza: 'favicon-fb9e34aac2778b22cc432edf08cb7f89.png',
};
