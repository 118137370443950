import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CdkPortalOutletAttachedRef, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  HostBinding,
  Inject,
  Optional,
} from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FabIconComponent } from '@ui/fab-icon';
import { IconSize } from '@ui/icon';
import { ListItemSeparatorComponent } from '@ui/list-item';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { Action, ActionAlign, ActionColor, ActionComponent } from '../action';
import {
  AbstractDialogComponent,
  DialogConfig,
  DialogPosition,
  DialogTitleColor,
} from './dialog.types';
import { ANIMATION_IN, ANIMATION_OUT } from './dialog.utils';

@UntilDestroy()
@Component({
  selector: 'etn-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionComponent,
    CommonModule,
    FabIconComponent,
    MatCardModule,
    PortalModule,
    ListItemSeparatorComponent,
  ],
})
export class DialogComponent {
  public constructor(
    @Optional() @Inject(DIALOG_DATA) public config: DialogConfig,
    private ref: DialogRef,
  ) {
    if (config.position === DialogPosition.RightSide) {
      ref.backdropClick.subscribe(() => {
        this.closeDialog(ref, config);
        if (isNotNil(config.onClose)) {
          config.onClose(this.componentRefSubject$?.value?.result);
        }
      });
    }
  }

  private componentRefSubject$ = new BehaviorSubject<
    AbstractDialogComponent | Nil
  >(undefined);

  private valid$ = this.componentRefSubject$.pipe(
    switchMap((ref) => {
      if (isNil(ref)) {
        return of(true);
      }
      return ref.valid$;
    }),
    distinctUntilChanged(),
  );

  private loading$ = this.componentRefSubject$.pipe(
    switchMap((ref) => {
      if (isNil(ref)) {
        return of(false);
      }
      return ref.loading$;
    }),
    distinctUntilChanged(),
  );

  // ALIGN
  @HostBinding('class')
  public align = this.config.align;

  // TITLE COLOR
  @HostBinding('class')
  public titleColor =
    this.config.confirmAction?.color ||
    this.config.titleColor ||
    DialogTitleColor.Primary;

  @HostBinding('class.no-cancel')
  public noCancel = isNil(this.config.cancelAction);

  // CONTENT
  public content: string | Nil = this.config.content?.replace(/\\n/g, '<br>');

  // ACTIONS
  public cancelAction$ = this.loading$.pipe(
    map((loading) => {
      return this.getCancelAction(this.config, loading);
    }),
  );

  public confirmAction$: Observable<Action | Nil> = combineLatest([
    this.valid$,
    this.loading$,
  ]).pipe(
    map(([valid, loading]) => {
      return this.getConfirmAction(this.config, valid, loading);
    }),
  );

  public closeAction$ = this.loading$.pipe(
    map((loading) => {
      return this.getCloseAction(this.config, loading);
    }),
  );

  public onAttached(ref: CdkPortalOutletAttachedRef): void {
    if (ref) {
      this.componentRefSubject$.next(
        (ref as ComponentRef<AbstractDialogComponent>).instance,
      );
    }
  }

  private getCancelAction(
    config: DialogConfig,
    loading: boolean,
  ): Action | Nil {
    if (config.cancelAction) {
      return {
        ...config.cancelAction,
        align: ActionAlign.Center,
        border: true,
        callback: () => {
          if (config.cancelAction && config.cancelAction.callback) {
            config.cancelAction.callback();
          }
          this.closeDialog(this.ref, config);
        },
        disabled: loading,
      };
    }

    return undefined;
  }

  private getConfirmAction(
    config: DialogConfig,
    valid: boolean,
    loading: boolean,
  ): Action | Nil {
    if (isNil(config.confirmAction)) {
      return undefined;
    }

    return {
      raised: true,
      color: ActionColor.Primary,
      align: ActionAlign.Center,
      ...config.confirmAction,
      callback: () => {
        if (isNotNil(config.confirmAction) && config.confirmAction.callback) {
          config.confirmAction.callback(
            this.componentRefSubject$.value?.result,
            (value: boolean) => {
              this.componentRefSubject$.value?.loading$.next(value);
            },
            () => {
              this.closeDialog(this.ref, config);
            },
          );
        }
        if (config.closeOnConfirm !== false) {
          this.closeDialog(this.ref, config);
        }
      },
      disabled: !valid && !loading,
      loading,
    };
  }

  public getCloseAction(config: DialogConfig, loading: boolean): Action | Nil {
    return {
      id: 'close',
      icon: 'close',
      iconSize: IconSize.Default,
      color: this.config.confirmAction?.color || ActionColor.Primary,
      callback: () => {
        if (isNil(config.confirmAction)) {
          this.closeDialog(this.ref, config);
          if (isNotNil(config.onClose)) {
            config.onClose(this.componentRefSubject$.value?.result);
          }
        } else {
          this.closeDialog(this.ref, config);
          if (isNotNil(config.onClose)) {
            config.onClose(undefined);
          }
        }
      },
      disabled: loading,
    };
  }

  private closeDialog(ref: DialogRef, config: DialogConfig): void {
    const position = config.position || DialogPosition.Center;

    ref.overlayRef.removePanelClass(ANIMATION_IN[position]);
    ref.overlayRef.addPanelClass(ANIMATION_OUT[position]);

    ref.overlayRef.overlayElement.addEventListener('animationend', () => {
      ref.close();
    });
  }
}
