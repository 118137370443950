import { has, isObject } from 'lodash-es';

export type Id = string | number;

export const NEW = 'new';
export const NONE = 'none';

export interface WithId {
  id: Id | typeof NEW;
}

export function isWithId(obj: any): obj is WithId {
  return isObject(obj) && has(obj, 'id');
}

export function isNew({ id }: WithId): boolean {
  return id === NEW;
}

export function isNone({ id }: WithId): boolean {
  return id === NONE;
}
