import { Id, Nil, WithId, WithName } from '@model';

import { Currency } from '../internals';

export interface DeviceSettings extends WithId, WithName {
  deviceId: Id;
  country: string | Nil;
  countryId: Id | Nil;
  postalCode: string | Nil;
  postalCodeId: Id | Nil;
  postalCodeInfo: SelectedPostalCode | Nil;
  currency: Currency;
  currencyId: Id | Nil;
  systemGoal: number;
  lobbyView: LobbyViewInfo;
}

export interface SelectedPostalCode extends WithId, WithName {
  extendedName: string;
  timezone: SelectedTimezone;
}

export interface SelectedTimezone extends WithId, WithName {
  timezone: string;
  dstOffset: number;
  rawOffset: number;
}

export interface LobbyViewInfo {
  isActive: boolean;
  url?: string | Nil;
}
