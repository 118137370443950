import { Injectable } from '@angular/core';
import { isApiErrorResponse } from '@api/bems-cloud/bems/bems-api.types';
import { NotificationsApiService } from '@api/bems-cloud/bems/notifications';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, delay, map, switchMap } from 'rxjs';

import { ONE_MINUTE } from '../auth/auth.const';
import {
  fetchNotificationCount,
  fetchNotificationCountError,
  fetchNotificationCountSuccess,
} from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  public constructor(
    private actions$: Actions,
    private api: NotificationsApiService,
  ) {}

  public fetchNotificationCount$ = createEffect(() => {
    return this.fetchNotificationCountEffect();
  });

  public fetchNotificationCountError$ = createEffect(() => {
    return this.fetchNotificationCountError();
  });

  public fetchNotificationCountSuccess$ = createEffect(() => {
    return this.fetchNotificationCountSuccess();
  });

  private fetchNotificationCountEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchNotificationCount),
      switchMap(() => {
        return this.api.getNotificationCount();
      }),
      map((response) => {
        if (isApiErrorResponse(response)) {
          return fetchNotificationCountError(response);
        }

        return fetchNotificationCountSuccess(response);
      }),
    );
  }

  private fetchNotificationCountSuccess(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchNotificationCountSuccess),
      delay(ONE_MINUTE),
      map(() => {
        return fetchNotificationCount();
      }),
    );
  }

  private fetchNotificationCountError(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchNotificationCountError),
      delay(ONE_MINUTE),
      map(() => {
        return fetchNotificationCount();
      }),
    );
  }
}
