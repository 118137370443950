import { AssetMetric } from '../asset-metric';
import { Phases } from './phases';

export interface PowerMeterDetails {
  activePower: Phases;
  apparentPower: Phases;
  current: Phases;
  powerFactor: Phases;
  reactivePower: Phases;
  voltage: Phases;
  frequency: AssetMetric;
  numberOfPhases: number;
}
