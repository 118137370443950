<full-calendar [options]="calendarOptions">
  <ng-template #resourceAreaHeaderContent>{{ headerTitle }}</ng-template>
  <ng-template #resourceLabelContent let-arg>
    <div
      class="resource-label-container line-content"
      [ngClass]="arg.resource.eventClassNames"
    >
      <div class="resource-label">
        {{ arg.fieldValue }}
      </div>
    </div>
  </ng-template>
  <ng-template #resourceLaneContent let-arg>
    <div
      class="slot-row line-content"
      [ngClass]="arg.resource.eventClassNames"
    ></div>
  </ng-template>
  <ng-template #eventContent let-arg>
    <div class="event-content" [ngClass]="arg.event.classNames">
      <div class="event-description">
        {{ arg.event.extendedProps.description }}
      </div>
      <div class="event-title">
        {{ arg.event.title }}
      </div>
    </div>
  </ng-template>
</full-calendar>
