import { WithId } from '@model';
import { TemperatureUnit } from '@model/temperature';

import { DateFormat } from './enums/date-format';
import { LanguageCode } from './enums/language';

export interface User extends WithId {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  acceptedEula?: string;
  language: LanguageCode;
  temperatureUnit: TemperatureUnit;
  dateFormat: DateFormat;
}

export function getUserDisplayName({ firstName, lastName }: User): string {
  return `${firstName} ${lastName}`;
}
