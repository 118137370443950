import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { InfoListItemModule } from '@brightlayer-ui/angular-components';
import { Nil } from '@model';
import { Action, ActionComponent } from '@ui/action';
import { Icon, IconComponent, IconSize } from '@ui/icon';

import { ListItemDivider, ListItemStatusColor } from './list-item.types';

@Component({
  selector: 'etn-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ActionComponent, CommonModule, IconComponent, InfoListItemModule],
})
export class ListItemComponent {
  @Input() public icon: Icon | Nil;
  @Input() public action: Action | Nil;
  @Input() public chevronIcon: Icon | Nil = {
    name: 'chevron_right',
    size: IconSize.Default,
  };
  @Input() public dense = false;
  @Input() public customIcon: boolean | Nil;
  @Input() public divider: ListItemDivider | Nil;
  @Input() public statusColor: ListItemStatusColor | Nil;

  @HostBinding('class.active')
  @Input()
  public active: boolean | Nil;

  @HostBinding('class.flexible-height')
  @Input()
  public flexibleHeight: boolean | Nil = false;

  @HostBinding('class.bottom-divider')
  @Input()
  public bottomDivider = true;

  @HostBinding('class.top-divider')
  @Input()
  public topDivider = false;

  @Input() public wrapSubtitle = false;

  public defaultIconSize: IconSize = IconSize.Default;

  public defaultDivider = ListItemDivider.Full;

  public get hasChevron(): boolean {
    return this.chevronIcon?.name === 'chevron_right';
  }
}
