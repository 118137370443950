import { IdJsonSchema } from '@api';
import { PaginatedResultsJsonSchemaFactory } from '@api/bems-cloud/bems/bems-api.types';
import { z } from 'zod';

export const TimezoneJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
  timezone: z.string(),
  gmtOffset: z.number(),
  dstOffset: z.number(),
  rawOffset: z.number(),
});

export const CoordinatesJsonSchema = z.object({
  lat: z.number(),
  lng: z.number(),
});

export const PostalCodeJsonSchema = z.object({
  id: IdJsonSchema,
  name: z.string(),
  code: z.string(),
  timezone: TimezoneJsonSchema,
  countryId: z.string(),
  coordinates: CoordinatesJsonSchema,
});

export type PostalCodeJson = z.infer<typeof PostalCodeJsonSchema>;

const PaginatedPostalCodeJsonSchema =
  PaginatedResultsJsonSchemaFactory(PostalCodeJsonSchema);

export type PaginatedPostalCodeJson = z.infer<
  typeof PaginatedPostalCodeJsonSchema
>;
